import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

// Company Jsons ////////////////////////////////////////////////
// CPP *** //
import * as CPP_WL from 'src/assets/json/CPP/CPP_WL.json';
import * as CPP_T10 from 'src/assets/json/CPP/CPP_T10.json';
import * as CPP_T20 from 'src/assets/json/CPP/CPP_T20.json';
import * as CPP_T25 from 'src/assets/json/CPP/CPP_T25.json';
import * as CPP_T100 from 'src/assets/json/CPP/CPP_T100.json';
// CPP Ends *** //

// ACL *** //
import * as ACL_WL from 'src/assets/json/ACL/ACL_WL.json';
import * as ACL_T15 from 'src/assets/json/ACL/ACL_T15.json';
import * as ACL_T20 from 'src/assets/json/ACL/ACL_T20.json';
import * as ACL_T25 from 'src/assets/json/ACL/ACL_T25.json';
// ACL Ends *** //

// SLI *** //
import * as SLI_WL from 'src/assets/json/SLI/SLI_WL.json'
// SLI ends //

// AIT *** //
// AIT c1
import * as AIT1_WL from 'src/assets/json/AIT/c1/AIT1_WL.json';
import * as AIT1_T10 from 'src/assets/json/AIT/c1/AIT1_T10.json';
import * as AIT1_T15 from 'src/assets/json/AIT/c1/AIT1_T15.json';
import * as AIT1_T20 from 'src/assets/json/AIT/c1/AIT1_T20.json';
import * as AIT1_T25 from 'src/assets/json/AIT/c1/AIT1_T25.json';
import * as AIT1_T30 from 'src/assets/json/AIT/c1/AIT1_T30.json';
import * as AIT1_T35 from 'src/assets/json/AIT/c1/AIT1_T35.json';
import * as AIT1_T40 from 'src/assets/json/AIT/c1/AIT1_T40.json'
// AIT c2
import * as AIT2_WL from 'src/assets/json/AIT/c2/AIT2_WL.json';
import * as AIT2_T10 from 'src/assets/json/AIT/c2/AIT2_T10.json';
import * as AIT2_T15 from 'src/assets/json/AIT/c2/AIT2_T15.json';
import * as AIT2_T20 from 'src/assets/json/AIT/c2/AIT2_T20.json';
import * as AIT2_T25 from 'src/assets/json/AIT/c2/AIT2_T25.json';
import * as AIT2_T30 from 'src/assets/json/AIT/c2/AIT2_T30.json';
import * as AIT2_T35 from 'src/assets/json/AIT/c2/AIT2_T35.json';
import * as AIT2_T40 from 'src/assets/json/AIT/c2/AIT2_T40.json'
// AIT c3
import * as AIT3_WL from 'src/assets/json/AIT/c3/AIT3_WL.json';
import * as AIT3_T10 from 'src/assets/json/AIT/c3/AIT3_T10.json'
import * as AIT3_T15 from 'src/assets/json/AIT/c3/AIT3_T15.json';
import * as AIT3_T20 from 'src/assets/json/AIT/c3/AIT3_T20.json';
import * as AIT3_T25 from 'src/assets/json/AIT/c3/AIT3_T25.json';
import * as AIT3_T30 from 'src/assets/json/AIT/c3/AIT3_T30.json';
import * as AIT3_T35 from 'src/assets/json/AIT/c3/AIT3_T35.json';
import * as AIT3_T40 from 'src/assets/json/AIT/c3/AIT3_T40.json'
// AIT c4
import * as AIT4_WL from 'src/assets/json/AIT/c4/AIT4_WL.json';
import * as AIT4_T10 from 'src/assets/json/AIT/c4/AIT4_T10.json';
import * as AIT4_T15 from 'src/assets/json/AIT/c4/AIT4_T15.json';
import * as AIT4_T20 from 'src/assets/json/AIT/c4/AIT4_T20.json';
import * as AIT4_T25 from 'src/assets/json/AIT/c4/AIT4_T25.json';
import * as AIT4_T30 from 'src/assets/json/AIT/c4/AIT4_T30.json';
import * as AIT4_T35 from 'src/assets/json/AIT/c4/AIT4_T35.json';
import * as AIT4_T40 from 'src/assets/json/AIT/c4/AIT4_T40.json'
// AIT c5
import * as AIT5_WL from 'src/assets/json/AIT/c5/AIT5_WL.json';
import * as AIT5_T10 from 'src/assets/json/AIT/c5/AIT5_T10.json';
import * as AIT5_T15 from 'src/assets/json/AIT/c5/AIT5_T15.json';
import * as AIT5_T20 from 'src/assets/json/AIT/c5/AIT5_T20.json';
import * as AIT5_T25 from 'src/assets/json/AIT/c5/AIT5_T25.json';
import * as AIT5_T30 from 'src/assets/json/AIT/c5/AIT5_T30.json';
import * as AIT5_T35 from 'src/assets/json/AIT/c5/AIT5_T35.json';
import * as AIT5_T40 from 'src/assets/json/AIT/c5/AIT5_T40.json'
// AIT ends //

// Company Jsons Ends ////////////////////////////////////////////////

// 
import { QqFormService } from "src/app/services/qq-form.service";
import { QqRouterFormService } from "src/app/services/qq-router-form.service";


@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  // %%
  ///////////////////////
  // AIT ********* ////////////////////////////
  // *** AIT 10k - 49k //////////
  AIT1_T10Data:any;
  AIT1_T15Data:any;
  AIT1_T20Data:any;
  AIT1_T25Data:any;
  AIT1_T30Data:any;
  AIT1_T35Data:any;
  AIT1_T40Data:any;
  AIT1_WLData:any;
  // *** AIT 50k - 99k //////////
  AIT2_T10Data:any;
  AIT2_T15Data:any;
  AIT2_T20Data:any;
  AIT2_T25Data:any;
  AIT2_T30Data:any;
  AIT2_T35Data:any;
  AIT2_T40Data:any;
  AIT2_WLData:any;
  // *** AIT 100k - 199k //////////
  AIT3_T10Data:any;
  AIT3_T15Data:any;
  AIT3_T20Data:any;
  AIT3_T25Data:any;
  AIT3_T30Data:any;
  AIT3_T35Data:any;
  AIT3_T40Data:any;
  AIT3_WLData:any;
  // *** AIT 200k - 499k //////////
  AIT4_T10Data:any;
  AIT4_T15Data:any;
  AIT4_T20Data:any;
  AIT4_T25Data:any;
  AIT4_T30Data:any;
  AIT4_T35Data:any;
  AIT4_T40Data:any;
  AIT4_WLData:any;
  // *** AIT 500k+ //////////
  AIT5_T10Data:any;
  AIT5_T15Data:any;
  AIT5_T20Data:any;
  AIT5_T25Data:any;
  AIT5_T30Data:any;
  AIT5_T35Data:any;
  AIT5_T40Data:any;
  AIT5_WLData:any;
  // 
  // 
  // AIT json Array...
  jsonAIT1Arr= [];
  jsonAIT2Arr= [];
  jsonAIT3Arr= [];
  jsonAIT4Arr= [];
  jsonAIT5Arr= [];
  // AIT Rates Array...
  AITRates = [];
  AIT_WL = [];
  // AIT ends ********* ////////////////////////////
  ///////////////////////
  // %%


  // %%
  ///////////////////////
  // SLI ********* ////////////////////////////
  SLI_WLData:any;
  // SLI json Array...
  jsonSLIArr = [];
  // SLI Rates Array...
  SLI_WL = [];
  // SLI ends ********* ////////////////////////////
  ///////////////////////
  // %%

  // %%
  ////////////////// 
  // CPP ******* ////////////////////////////
  CPP_WLData:any;
  CPP_T10Data:any;
  CPP_T20Data:any;
  CPP_T25Data:any;
  CPP_T100Data:any;
  // CPP json Array...
  jsonCPPArr = [];
  // CPP Rates Array...
  CPPRates = [];
  CPP_WL = [];

  // CPP Ends ******* ////////////////////////////
  /////////////////////
  // %%
  
  // %%
  ////////////////// 
  // ACL ******* ////////////////////////////
  ACL_WLData:any;
  ACL_T15Data:any;
  ACL_T20Data:any;
  ACL_T25Data:any;
  // ACL json Array...
  jsonACLArr = [];
  // ACL Rates Array...
  ACLRates = [];
  ACL_WL = [];
  // ACL Ends ******* ////////////////////////////
  /////////////////////
  // %%
  
  // %%
  // ## DISPLAY //////////////////////////////////////////////////////////////
  // Display Info...
  displayInfoArr = [{
    "AIT_WL": {
      "companyNameAng": "IAT",
      "companyNameFull": "Industrial Alliance Insurance",
      "policyNameReg": "Whole Life",
      "policyNameCode": "WL",
      "url": "#",
      "img": "../../../assets/img/iaf-logo.png"
    },
    "AIT_T10": {
      "companyNameAng": "IAT",
      "companyNameFull": "Industrial Alliance Insurance",
      "policyNameReg": "10 Years",
      "policyNameCode": "T10",
      "url": "#",
      "img": "../../../assets/img/iaf-logo.png"
    },
    "AIT_T15": {
      "companyNameAng": "IAT",
      "companyNameFull": "Industrial Alliance Insurance",
      "policyNameReg": "15 Years",
      "policyNameCode": "T15",
      "url": "#",
      "img": "../../../assets/img/iaf-logo.png"
    },
    "AIT_T20": {
      "companyNameAng": "IAT",
      "companyNameFull": "Industrial Alliance Insurance",
      "policyNameReg": "20 Years",
      "policyNameCode": "T20",
      "url": "#",
      "img": "../../../assets/img/iaf-logo.png"
    },
    "AIT_T25": {
      "companyNameAng": "IAT",
      "companyNameFull": "Industrial Alliance Insurance",
      "policyNameReg": "25 Years",
      "policyNameCode": "T25",
      "url": "#",
      "img": "../../../assets/img/iaf-logo.png"
    },
    "AIT_T30": {
      "companyNameAng": "IAT",
      "companyNameFull": "Industrial Alliance Insurance",
      "policyNameReg": "30 Years",
      "policyNameCode": "T30",
      "url": "#",
      "img": "../../../assets/img/iaf-logo.png"
    },
    "AIT_T35": {
      "companyNameAng": "IAT",
      "companyNameFull": "Industrial Alliance Insurance",
      "policyNameReg": "35 Years",
      "policyNameCode": "T35",
      "url": "#",
      "img": "../../../assets/img/iaf-logo.png"
    },
    "AIT_T40": {
      "companyNameAng": "IAT",
      "companyNameFull": "Industrial Alliance Insurance",
      "policyNameReg": "40 Years",
      "policyNameCode": "T40",
      "url": "#",
      "img": "../../../assets/img/iaf-logo.png"
    },
    "SLI_WL": {
      "companyNameAng": "SLI",
      "companyNameFull": "Specialty Life Insurance",
      "policyNameReg": "Whole Life",
      "policyNameCode": "WL",
      "url": "#",
      "img": "../../../assets/img/sli-thumb-output.jpg"
    },    
    "CPP_T10": {
      "companyNameAng": "CPP",
      "companyNameFull": "Canada Protection Plan",
      "policyNameReg": "10 Years",
      "policyNameCode": "T10",
      "url": "#",
      "img": "../../../assets/img/cpp-logo.jpg"
    },
    "CPP_T20": {
      "companyNameAng": "CPP",
      "companyNameFull": "Canada Protection Plan",
      "policyNameReg": "20 Years",
      "policyNameCode": "T20",
      "url": "#",
      "img": "../../../assets/img/cpp-logo.jpg"
    },
    "CPP_T25": {
      "companyNameAng": "CPP",
      "companyNameFull": "Canada Protection Plan",
      "policyNameReg": "25 Years",
      "policyNameCode": "T25",
      "url": "#",
      "img": "../../../assets/img/cpp-logo.jpg"
    },
    "CPP_T100": {
      "companyNameAng": "CPP",
      "companyNameFull": "Canada Protection Plan",
      "policyNameReg": "100 Years",
      "policyNameCode": "T100",
      "url": "#",
      "img": "../../../assets/img/cpp-logo.jpg"
    },
    "CPP_WL": {
      "companyNameAng": "CPP",
      "companyNameFull": "Canada Protection Plan",
      "policyNameReg": "Whole Life",
      "policyNameCode": "WL",
      "url": "#",
      "img": "../../../assets/img/cpp-logo.jpg"
    },
    "ACL_T15": {
      "companyNameAng": "ACL",
      "companyNameFull": "Industry Alliance Excellence",
      "policyNameReg": "15 Years",
      "policyNameCode": "T15",
      "url": "#",
      "img": "../../../assets/img/iae-logo.png"
    },
    "ACL_T20": {
      "companyNameAng": "ACL",
      "companyNameFull": "Industry Alliance Excellence",
      "policyNameReg": "20 Years",
      "policyNameCode": "T20",
      "url": "#",
      "img": "../../../assets/img/iae-logo.png"
    },
    "ACL_T25": {
      "companyNameAng": "ACL",
      "companyNameFull": "Industry Alliance Excellence",
      "policyNameReg": "25 Years",
      "policyNameCode": "T25",
      "url": "#",
      "img": "../../../assets/img/iae-logo.png"
    },
    "ACL_WL": {
      "companyNameAng": "ACL",
      "companyNameFull": "Industry Alliance Excellence",
      "policyNameReg": "Whole Life",
      "policyNameCode": "WL",
      "url": "#",
      "img": "../../../assets/img/iae-logo.png"
    }
  }];
  // ## DISPLAY ENDS //////////////////////////////////////////////////////////////
  // %%
  
  // %%
  //////////////////////////////
  // All Companies ******* //////////////////////////
  companyRates = [];
  companyRates_WL = [];
  // All Companies Ends ******* //////////////////////////
  /////////////////////////////////
  // %%

  // Stepper //
  step2Complete: boolean = false;
  // 

  // Date //
  minDate = new Date();
  maxDate = new Date();
  todaysDate = new Date();
  // 
  quoteForm:FormGroup;

  // 
  // coverageText = 500000;

  // Validator
  typeValidator:boolean = false;
  genderValidator:boolean = false;
  smokerValidator:boolean = false;
  birthDateValidator:boolean = false;
  coverageValidator:boolean = false;
  contactValidator:boolean = false;

  // Coverage By Type
  covCheck:boolean = false;
  covMin:number;
  covMax:number;
  covStep:number;
  covCenter:number;
  formatText:number;
  // Coverage name array
  coverageNameArr = {
    "burI":"Burial Insurance",
    "creI":"Cremation Insurance",
    "diaI":"Diabetes Insurance",
    "canI":"Cancer Insurance",
    "lifI":"Life Insurance",
    "nonI":"Non-Medical Life Insurance",
    "proI":"Income Protection Insurance"
  }
  coverageTextArr = {
    "burI":"The average cost of <b>burial</b> is between <b>$5,000</b>  to <b>$20,000</b>.  Depending upon the wishes of the individual & family.",
    "creI":"The average cost of <b>cremation</b> is between <b>$4,000</b> to <b>$10,000</b>. Depending upon the wishes of the individual & family.",
    "diaI":"Life insurance for those with type 1 & type 2 diabetes.",
    "canI":"Life insurance for those presently with cancer or had cancer in the past.",
    "lifI":"Traditional life insurance",
    "nonI":"Specially designed for those who are hard to insure. No medical guaranteed approval.",
    "proI":"Income protection from critical illness. This is a living benefit."
  }

  // Needs Analysis
  needsForm:FormGroup;
  cashTotalCheck:boolean = false;
  assetTotalCheck:boolean = false;
  incomeTotalCheck:boolean = false;
  needsTotalCheck:boolean = false;
  needsToolTipArr = {
    "title":"Information Unavailable",
    "final":"Final Expenses:\nA funeral can cost anywhere from $5,000 to $20,000 or more, depending on whether you plan for cremation or burial and how expensive your choices are (coffin, location, etc.). In addition, you should consider expenses such as probate fees, final income taxes, legal and accounting fees and executor fees, if any.",
    "mortgage":"Mortgage:\nWhat is the outstanding balance of your mortgage? If in doubt, you might use the original principal amount as a conservative estimate or use an amount that you would like to pay off.",
    "loans":"Other Loans:\nConsider any bank loans, car loans, loans from credit unions or other financial institutions, credit card outstanding balances, loans from friends, etc.",
    "child":"Children’s Education Fund:\nThe amount you like to set aside for your children’s post-secondary education.",
    "emergency":"Emergency funds:\nMost experts recommend at least 3 to 6 months of salary as a reasonable amount for an emergency fund to cover unexpected expenses and tide your family over.",
    // 
    "monthInc":"Monthly Income:\nThis is the amount of money that you bring in each month to pay for the groceries, hydro, water, gas, car, clothing, vacations and other expenses. If you have a budget, this number may be readily available to you. If not, you might consider using your monthly income less what you pay in income tax. When you are considering your monthly expenses, consider the amount set aside for housing. Is this a mortgage for your house or rent for your apartment? If you live in a house, would your family remain in the house or would they sell and move to rental housing? Answers to these questions will help you to estimate what your survivor’s monthly expenses might be.",
    "yearInc":"For how many years:\nMost people want to replace their income at least until their youngest child is out of the home.",
    // 
    "savings":"Savings:\nBe sure to consider all available cash and any investments that can be easily turned into cash. You should include bank accounts, mutual funds, certificates of deposit, stocks, bonds and cash. You should NOT include RRSPs, as these funds will be required to provide a retirement income for the surviving spouse.",
    "assets":"Other assets:\nIf your survivors might sell your house and/or car, then you should include the value of these assets here. If they will need these assets (for example, they plan to live in the house, as opposed to selling it and moving in with other family or to a less expensive residence), then you should not include the asset value here.",
    "existing":"Existing life insurance:\n Be sure to include all existing life insurance plans, including individual plans, group plans, bank mortgage insurance, etc. Do not include plans that only pay in case of accidental death, as these may not be payable. "
  }

  // Progress Bar
  pStepAmount = 7;
  pPercent:number = 0;
  stepNum:number=1;
  // pStep2:boolean = false;
  // pStep3:boolean = false;
  // pStep4:boolean = false;

  // Send to application

  // FOOTER COLOR
  footerColor(){
    return "#3F51B5"
  }
  constructor(
    private qFormService:QqFormService,
    private qqRouterFormService:QqRouterFormService,
    private router:Router
  ) { 

  

    // 
    // Database quick quote


    // 
    // Set json data... //
    // CPP JSON...
    this.CPP_WLData = (CPP_WL as any).default;
    this.CPP_T10Data = (CPP_T10 as any).default;
    this.CPP_T20Data = (CPP_T20 as any).default;
    this.CPP_T25Data = (CPP_T25 as any).default;
    this.CPP_T100Data = (CPP_T100 as any).default;
    // 
    this.jsonCPPArr['CPP_T10'] = this.CPP_T10Data;
    this.jsonCPPArr['CPP_T20'] = this.CPP_T20Data;
    this.jsonCPPArr['CPP_T25'] = this.CPP_T25Data;
    this.jsonCPPArr['CPP_T100'] = this.CPP_T100Data;
    this.jsonCPPArr['CPP_WL'] = this.CPP_WLData;
    // 

    // ACL JSON..
    this.ACL_WLData = (ACL_WL as any).default;
    this.ACL_T15Data = (ACL_T15 as any).default;
    this.ACL_T20Data = (ACL_T20 as any).default;
    this.ACL_T25Data = (ACL_T25 as any).default;
    // 
    this.jsonACLArr['ACL_T15'] = this.ACL_T15Data;
    this.jsonACLArr['ACL_T20'] = this.ACL_T20Data;
    this.jsonACLArr['ACL_T25'] = this.ACL_T25Data;
    this.jsonACLArr['ACL_WL'] = this.ACL_WLData;
    // 

    // AIT JSON..
    // * AIT c1 
    this.AIT1_WLData = (AIT1_WL as any).default;
    this.AIT1_T10Data = (AIT1_T10 as any).default;
    this.AIT1_T15Data = (AIT1_T15 as any).default;
    this.AIT1_T20Data = (AIT1_T20 as any).default;
    this.AIT1_T25Data = (AIT1_T25 as any).default;
    this.AIT1_T30Data = (AIT1_T30 as any).default;
    this.AIT1_T35Data = (AIT1_T35 as any).default;
    this.AIT1_T40Data = (AIT1_T40 as any).default;
    //
    this.jsonAIT1Arr['AIT_WL'] = this.AIT1_WLData;
    this.jsonAIT1Arr['AIT_T10'] = this.AIT1_T10Data;
    this.jsonAIT1Arr['AIT_T15'] = this.AIT1_T15Data;
    this.jsonAIT1Arr['AIT_T20'] = this.AIT1_T20Data;
    this.jsonAIT1Arr['AIT_T25'] = this.AIT1_T25Data;
    this.jsonAIT1Arr['AIT_T30'] = this.AIT1_T30Data;
    this.jsonAIT1Arr['AIT_T35'] = this.AIT1_T35Data;
    this.jsonAIT1Arr['AIT_T40'] = this.AIT1_T40Data;
    //  
    // * AIT c2
    this.AIT2_WLData = (AIT2_WL as any).default;
    this.AIT2_T10Data = (AIT2_T10 as any).default;
    this.AIT2_T15Data = (AIT2_T15 as any).default;
    this.AIT2_T20Data = (AIT2_T20 as any).default;
    this.AIT2_T25Data = (AIT2_T25 as any).default;
    this.AIT2_T30Data = (AIT2_T30 as any).default;
    this.AIT2_T35Data = (AIT2_T35 as any).default;
    this.AIT2_T40Data = (AIT2_T40 as any).default;
    // 
    this.jsonAIT2Arr['AIT_WL'] = this.AIT2_WLData;
    this.jsonAIT2Arr['AIT_T10'] = this.AIT2_T10Data;
    this.jsonAIT2Arr['AIT_T15'] = this.AIT2_T15Data;
    this.jsonAIT2Arr['AIT_T20'] = this.AIT2_T20Data;
    this.jsonAIT2Arr['AIT_T25'] = this.AIT2_T25Data;
    this.jsonAIT2Arr['AIT_T30'] = this.AIT2_T30Data;
    this.jsonAIT2Arr['AIT_T35'] = this.AIT2_T35Data;
    this.jsonAIT2Arr['AIT_T40'] = this.AIT2_T40Data;
    // 
    // * AIT c3
    this.AIT3_WLData = (AIT3_WL as any).default;
    this.AIT3_T10Data = (AIT3_T10 as any).default;
    this.AIT3_T15Data = (AIT3_T15 as any).default;
    this.AIT3_T20Data = (AIT3_T20 as any).default;
    this.AIT3_T25Data = (AIT3_T25 as any).default;
    this.AIT3_T30Data = (AIT3_T30 as any).default;
    this.AIT3_T35Data = (AIT3_T35 as any).default;
    this.AIT3_T40Data = (AIT3_T40 as any).default;
    // 
    this.jsonAIT3Arr['AIT_WL'] = this.AIT3_WLData;
    this.jsonAIT3Arr['AIT_T10'] = this.AIT3_T10Data; 
    this.jsonAIT3Arr['AIT_T15'] = this.AIT3_T15Data;
    this.jsonAIT3Arr['AIT_T20'] = this.AIT3_T20Data;
    this.jsonAIT3Arr['AIT_T25'] = this.AIT3_T25Data;
    this.jsonAIT3Arr['AIT_T30'] = this.AIT3_T30Data;
    this.jsonAIT3Arr['AIT_T35'] = this.AIT3_T35Data;
    this.jsonAIT3Arr['AIT_T40'] = this.AIT3_T40Data;
    // 
    // * AIT c4
    this.AIT4_WLData = (AIT4_WL as any).default;
    this.AIT4_T10Data = (AIT4_T10 as any).default;
    this.AIT4_T15Data = (AIT4_T15 as any).default;
    this.AIT4_T20Data = (AIT4_T20 as any).default;
    this.AIT4_T25Data = (AIT4_T25 as any).default;
    this.AIT4_T30Data = (AIT4_T30 as any).default;
    this.AIT4_T35Data = (AIT4_T35 as any).default;
    this.AIT4_T40Data = (AIT4_T40 as any).default;
    // 
    this.jsonAIT4Arr['AIT_WL'] = this.AIT4_WLData;
    this.jsonAIT4Arr['AIT_T10'] = this.AIT4_T10Data; 
    this.jsonAIT4Arr['AIT_T15'] = this.AIT4_T15Data;
    this.jsonAIT4Arr['AIT_T20'] = this.AIT4_T20Data;
    this.jsonAIT4Arr['AIT_T25'] = this.AIT4_T25Data;
    this.jsonAIT4Arr['AIT_T30'] = this.AIT4_T30Data;
    this.jsonAIT4Arr['AIT_T35'] = this.AIT4_T35Data;
    this.jsonAIT4Arr['AIT_T40'] = this.AIT4_T40Data; 
    // 
    // * AIT c5
    this.AIT5_WLData = (AIT5_WL as any).default;
    this.AIT5_T10Data = (AIT5_T10 as any).default;
    this.AIT5_T15Data = (AIT5_T15 as any).default;
    this.AIT5_T20Data = (AIT5_T20 as any).default;
    this.AIT5_T25Data = (AIT5_T25 as any).default;
    this.AIT5_T30Data = (AIT5_T30 as any).default;
    this.AIT5_T35Data = (AIT5_T35 as any).default;
    this.AIT5_T40Data = (AIT5_T40 as any).default;
    // 
    this.jsonAIT5Arr['AIT_WL'] = this.AIT5_WLData;
    this.jsonAIT5Arr['AIT_T10'] = this.AIT5_T10Data;
    this.jsonAIT5Arr['AIT_T15'] = this.AIT5_T15Data;
    this.jsonAIT5Arr['AIT_T20'] = this.AIT5_T20Data;
    this.jsonAIT5Arr['AIT_T25'] = this.AIT5_T25Data;
    this.jsonAIT5Arr['AIT_T30'] = this.AIT5_T30Data;
    this.jsonAIT5Arr['AIT_T35'] = this.AIT5_T35Data;
    this.jsonAIT5Arr['AIT_T40'] = this.AIT5_T40Data; 


    // SLI JSON
    this.SLI_WLData = (SLI_WL as any).default;
    // 
    this.jsonSLIArr['SLI_WL'] = this.SLI_WLData;
    // 


  }
  ngOnInit(): void {
    // console.log(this.jsonAIT2Arr);
    // this.dataLoop_SLI();
    // 
    // MAIN QUICK FORM ////////////////////////////////////////////// 
    // Set Birth Date age max... 
    this.minDate = this.removeYears(this.minDate, 70);
    //
    this.quoteForm = new FormGroup({
      "type": new FormControl(null, Validators.required),
      "gender": new FormControl(null, Validators.required),
      "smoker": new FormControl(null, Validators.required),
      "birthDate": new FormControl(null, Validators.required),
      "coverage": new FormControl(500000, Validators.required),
      "name": new FormControl(null, Validators.required),
      "email": new FormControl(null, [Validators.required, Validators.email] ),
      "phone": new FormControl(null, [
        Validators.required, 
        Validators.pattern("^[0-9()-]*$")
      ]),
      "age": new FormControl(null),
      "completed": new FormControl(this.todaysDate),
      // 
      "term": new FormControl(null),
      "companyCode": new FormControl(null)
    });
    // 
    // NEEDS FORM FORM ////////////////////////////////////////////// 
    this.needsForm = new FormGroup({
      "final": new FormControl(null),
      "mortgage": new FormControl(null),
      "loans": new FormControl(null),
      "emergency": new FormControl(null),
      "child": new FormControl(null),
      // 
      "monthlyIncome": new FormControl(null),
      "incomeYears": new FormControl(null, Validators.pattern("^[0-9]*$")),
      // 
      "savings": new FormControl(null),
      "assets": new FormControl(null),
      "existingLife":new FormControl(null),
      // 
      "cashTotals": new FormControl(0),
      "incomeTotals": new FormControl(0),
      "assetsTotals": new FormControl(0),
      "needsTotals": new FormControl(0)
    })

    // 
    //////////////////////////
    // Form Listeners *************************************** /
    // Individual Input Status
    this.quoteForm.get('type').statusChanges.subscribe(
      (status) =>{
        // console.log('type validator');
        // console.log(status);
        if (status === 'VALID') {
          this.typeValidator = true;
        }
      }
    );
    this.quoteForm.get('gender').statusChanges.subscribe(
      (status) =>{
        // console.log('gender validator');
        // console.log(status);
        if (status === 'VALID') {
          this.genderValidator = true;
        }
      }
    );
    this.quoteForm.get('smoker').statusChanges.subscribe(
      (status) =>{
        // console.log('smoker validator');
        // console.log(status);
        if (status === 'VALID') {
          this.smokerValidator = true;          
        }
      }
    );
    this.quoteForm.get('birthDate').statusChanges.subscribe(
      (status) =>{
        // console.log('smoker validator');
        // console.log(status);
        if (status === 'VALID') {
          this.birthDateValidator = true; 
          this.coverageValidator = true;
        }else{

        };
      }
    );
    // Individual Input Values
    this.quoteForm.get('coverage').valueChanges.subscribe(
      (value) =>{
        if (!this.coverageValidator) {
          // this.coverageValidator = true;
        }
      }
    );
    this.quoteForm.get('type').valueChanges.subscribe(
      (value) => {
        this.setCoverageMinMax(value);
      }
    );
    // Full Form subs
    this.quoteForm.valueChanges.subscribe(
      (value) =>{
        // console.log(value);
        // Birth date set to Age
        if (this.quoteForm.get('birthDate').valid) {
          this.setAgeValue(value);           
        }
        // 

        // Validator for contact info page
        if (
          this.quoteForm.get('name').valid &&
          this.quoteForm.get('email').valid &&
          this.quoteForm.get('phone').valid
        ) {
          // console.log('Contact Info is valid');
          this.contactValidator = true;
        }
        // 

        // Trigger quote on status changes
        // console.log('Is form valid');
        // console.log(this.quoteForm.valid);
        if (this.quoteForm.valid) {
          // console.log('THE WHOLE QUOTE FORM IS VALID');
          this.startQuote();
          this.create();
        }
        // 
      }
    );
    this.quoteForm.statusChanges.subscribe(
      (status) =>{
        // console.log('Whole form status');
        // console.log(status);
      }
    );
    ///////////////////
    // NeedsForm Listeners
    this.needsForm.valueChanges.subscribe(
      (value) =>{
        // console.log(value);
        // console.log(this.needsForm.get('final').valid);
      }
    )
  }
  // 
  

  // 
  // /////////////////////////////////////
  // Database for Quick Quote Form
  isFormIn_db:boolean = false;
  qqForm_id;
  // Test database connect
  create(){
    if (!this.isFormIn_db) {
      // this.qFormService.createPerson(this.quoteForm)
      // .then((value) =>{
      //   console.log(value);
      //   console.log('Success!');
      //   this.qqForm_id = value.id;
      //   this.isFormIn_db = true;
      // })
      // .catch((err) => {
      //   console.log('The form did not transfer!');
      //   this.isFormIn_db = false;
      // })
      
    } else if (this.isFormIn_db) {
      this.qFormService.updatePerson(this.quoteForm, this.qqForm_id)
      .then((value) =>{
        console.log('Update Success');
      })
      .catch((err) =>{
        console.log('The form did not update!');
      })
    }
  }
  // /////////////////////////////////////
  // 

  // %%
  // Stepper Component ////////////////////////////////
  stepperMain(ref){
    this.stepNum = ref.selectedIndex;
    // console.log(`This is the selected index:  ${ref.selectedIndex}`);
    const percentage = Math.round((ref.selectedIndex / this.pStepAmount)*100);
    this.pPercent = percentage;
  }
  // Stepper Component ends ////////////////////////////////
  // %%

  // %%
  // Needs Component ////////////////////////////////
  incomeTabBool:boolean = false;
  assetsTabBool:boolean = false;
  totalsTabBool:boolean = false;
  tabNum:number = 0;
  isNeeds:boolean = false;
  // 
  // Show the Needs Analysis Component
  needsShow(){
    this.isNeeds = !this.isNeeds;
  }
  // Tab control
  //
  needsCashTotal(){
    console.log('needs cash total trig');
    const total = 
      this.needsForm.get('final').value +
      this.needsForm.get('mortgage').value +
      this.needsForm.get('loans').value +
      this.needsForm.get('emergency').value +
      this.needsForm.get('child').value;
    // 
    // console.log(`This is the total: ${total}`);
    this.needsForm.get('cashTotals').patchValue(total);
    this.incomeTabBool = true;
    this.tabNum = 1;
    
  } 
  // 
  needsIncomeTotal(){
    console.log('needs income total trig');
    const total = 
      this.needsForm.get('monthlyIncome').value *
      this.needsForm.get('incomeYears').value;
    // 
    // console.log(`This is the total: ${total}`);
    this.needsForm.get('incomeTotals').patchValue(total);
    this.assetsTabBool = true;
    this.tabNum = 2;
    
  }
  // 
  needsAssetTotal(){
    console.log('needs income total trig');
    const total = 
      this.needsForm.get('savings').value +
      this.needsForm.get('assets').value+
      this.needsForm.get('existingLife').value;
    // 
    // console.log(`This is the assets total: ${total}`);
    this.needsForm.get('assetsTotals').patchValue(total);
    this.totalsTabBool = true;
    this.tabNum = 3;
    this.needsAllTotal();
    
  }
  // 
  needsAllTotal(){
    console.log('needs income total trig');
    const total = 
      this.needsForm.get('cashTotals').value +
      this.needsForm.get('incomeTotals').value-
      this.needsForm.get('assetsTotals').value;
    // 
    
    // console.log(`This is the total: ${total}`);
    this.needsForm.get('needsTotals').patchValue(total);
    this.tabNum = 3;
    this.assetsTabBool = true;
    
  }
  // Needs Component ends ////////////////////////////////
  // %%

  // %%
  // Toggles ////////////////////////////////
  // List dropdown button
  buttonList:boolean = false;
  buttonResultsName:string = "SHOW MORE RESULTS";
  buttonListToggle(){
    // console.log('Toggle');
    this.buttonList = !this.buttonList;
    if (this.buttonList) {
      this.buttonResultsName = "HIDE MORE RESULTS";
    } else {
      this.buttonResultsName = "SHOW MORE RESULTS";
      
    }
  }
  // 
  // Is active
  // Toggles ends ////////////////////////////////
  // %%

  isActive:boolean = false;
  isActiveToggle(is){
    this.isActive = is;
  }


  // %%
  //////////////////////////////////////////
  // Format label for coverage slider ////////////////////////////////////////
  formatLabel(value: number) {    
    // 
    if (value >= 1000 && value < 1000000) {
      return Math.round(value / 1000) + 'k';
    }
    if (value >= 1000000) {
      return Math.round(value / 1000)/1000 + 'm';      
    }

    return value;
  }
  // Format label ////////////////////////////////////////
  //////////////////////////////////////////
  // %%


  // %%
  //////////////////////////////////////////
  // Set Age From Datepicker ////////////////////////////////////////
  setAgeValue(value){
    const daysFromBirth = (this.maxDate.getTime() - value.birthDate.getTime())/(24*60*60*1000)
    // console.log('This should be days totaled ' + daysFromBirth);
    const age = Math.floor(daysFromBirth/365);
    // console.log('Your age is ' + age);
    // 
    if (this.quoteForm.get('age').value !== age) {
      this.quoteForm.get('age').patchValue(age);
    }
  }
  //////////////////////////////////////////
  // %%

  // %%
  ////////////////////////////////
  // CPP Quote System /////////////////////////////////////////////////
  dataLoop_CPP(){
    this.CPPRates = [];
    for (const json in this.jsonCPPArr) {
      if (this.jsonCPPArr.hasOwnProperty(json)) {
        const element = this.jsonCPPArr[json];
        this.searchRatesLoop_CPP(element, json);
      }
    }
  }
  searchRatesLoop_CPP(json, company){
    const companyName = company;
    // console.log(`The company is ${company}`);
    //  
    const jsonData = json;
    // console.log(jsonData);
    const personAge = this.quoteForm.value.age;
    const smoker = this.quoteForm.value.smoker;
    const gender = this.quoteForm.value.gender;
    const coverage = this.quoteForm.value.coverage;
    // console.log(personAge);
    // 
    const ratesArr = [];
    let ratesMonthlyArr = [];
    let ratesYearlyArr = [];
    for (const data in jsonData) {
      if (jsonData.hasOwnProperty(data)) {
        const ageGroup = jsonData[data];
        const age = ageGroup['AGE'];
        // console.log(ageGroup);
        if (age === personAge) {
          // console.log(`You are ${age} years old.`);
          if (smoker) {
            // console.log('You are a Smoker');
            // gender
            if (gender === "male") {
              // console.log('You are a male');
              // 
              for (const rates in ageGroup) {
                if (ageGroup.hasOwnProperty(rates)) {
                  const rateAmount = ageGroup[rates];
                  // console.log(rates);
                  if (
                    rates === "MSM0" ||
                    rates === "MSM1" ||
                    rates === "MSM2" ||
                    rates === "MSM3" 
                  ) {
                    // console.log(`Rate: ${rateAmount}`);
                    ratesArr.push(rateAmount);
                  }

                }
              }
              // 
            }else{
              // console.log('You are not a male');
              // 
              for (const rates in ageGroup) {
                if (ageGroup.hasOwnProperty(rates)) {
                  const rateAmount = ageGroup[rates];
                  // console.log(rates);
                  if (
                    rates === "FSM0" ||
                    rates === "FSM1" ||
                    rates === "FSM2" ||
                    rates === "FSM3" 
                  ) {
                    // console.log(`Rate: ${rateAmount}`);
                    ratesArr.push(rateAmount);
                  }

                }
              }
              // 
            }
            // 
          }else if(!smoker){
            // console.log('You are a Not Smoker');
            // gender
            if (gender === "male") {
              // console.log('You are a male');
              // 
              for (const rates in ageGroup) {
                if (ageGroup.hasOwnProperty(rates)) {
                  const rateAmount = ageGroup[rates];
                  // console.log(rates);
                  if (
                    rates === "MNS0" ||
                    rates === "MNS1" ||
                    rates === "MNS2" ||
                    rates === "MNS3" 
                  ) {
                    // console.log(`Rate: ${rateAmount}`);
                    ratesArr.push(rateAmount);
                  }

                }
              }
              // 
            }else{
              // console.log('You are not a male');
              // 
              for (const rates in ageGroup) {
                if (ageGroup.hasOwnProperty(rates)) {
                  const rateAmount = ageGroup[rates];
                  // console.log(rates);
                  if (
                    rates === "FNS0" ||
                    rates === "FNS1" ||
                    rates === "FNS2" ||
                    rates === "FNS3" 
                  ) {
                    // console.log(`Rate: ${rateAmount}`);
                    ratesArr.push(rateAmount);
                  }

                }
              }
              // 
            }
            //
          }
        }
        
        
      }
    }
    // 
    // console.log('this is the cpp rates');
    // console.log(ratesArr);
    // console.log('this is the first rate');
    // console.log(ratesArr[0]);
    if (ratesArr[0] === undefined) {
      // console.log('This rate is undefined');
    }else{
      // Array check //
      ratesYearlyArr = this.calculateYearlyRates(ratesArr, coverage);
      ratesMonthlyArr = this.calculateMonthlyRates(ratesYearlyArr);
      if (company === 'CPP_WL') {
        this.CPP_WL = ratesMonthlyArr;
      }else{
        this.CPPRates[company] = ratesMonthlyArr;
      }
      //
      // console.log('this rate is' + ratesArr[0]);
    }
    // Array check //
    // ratesYearlyArr = this.calculateYearlyRates(ratesArr, coverage);
    // ratesMonthlyArr = this.calculateMonthlyRates(ratesYearlyArr);
    // if (company === 'CPP_WL') {
    //   this.CPP_WL = ratesMonthlyArr;
    // }else{
    //   this.CPPRates[company] = ratesMonthlyArr;
    // }
    // 
  }
  // CPP Quote System ends/////////////////////////////////////////////////
  ////////////////////////////////
  // %%

  // %%
  ////////////////////////////////
  // ACL Quote System /////////////////////////////////////////////////
  dataLoop_ACL(){
    for (const json in this.jsonACLArr) {
      if (this.jsonACLArr.hasOwnProperty(json)) {
        const element = this.jsonACLArr[json];
        // console.log('json');
        this.searchRatesLoop_ACL(element, json);
      }
    }
  }
  searchRatesLoop_ACL(json, company){
    const companyName = company;
    // console.log(`The company is ${company}`);
    //  
    const jsonData = json;
    // console.log(jsonData);
    const personAge = this.quoteForm.value.age;
    const smoker = this.quoteForm.value.smoker;
    const gender = this.quoteForm.value.gender;
    const coverage = this.quoteForm.value.coverage;
    // console.log(personAge);
    // 
    const ratesArr = [];
    let ratesMonthlyArr = [];
    let ratesYearlyArr = [];
    for (const data in jsonData) {
      if (jsonData.hasOwnProperty(data)) {
        const ageGroup = jsonData[data];
        const age = ageGroup['AGE'];
        // console.log(ageGroup);
        if (age === personAge) {
          // console.log(`You are ${age} years old.`);
          if (smoker) {
            // console.log('You are a Smoker');
            // gender
            if (gender === "male") {
              // console.log('You are a male');
              // 
              for (const rates in ageGroup) {
                if (ageGroup.hasOwnProperty(rates)) {
                  const rateAmount = ageGroup[rates];
                  // console.log(rates);
                  if (
                    rates === "MSM0" ||
                    rates === "MSM1" ||
                    rates === "MSM2" ||
                    rates === "MSM3" 
                  ) {
                    // console.log(`Rate: ${rateAmount}`);
                    ratesArr.push(rateAmount);
                  }

                }
              }
              // 
            }else{
              // console.log('You are not a male');
              // 
              for (const rates in ageGroup) {
                if (ageGroup.hasOwnProperty(rates)) {
                  const rateAmount = ageGroup[rates];
                  // console.log(rates);
                  if (
                    rates === "FSM0" ||
                    rates === "FSM1" ||
                    rates === "FSM2" ||
                    rates === "FSM3" 
                  ) {
                    // console.log(`Rate: ${rateAmount}`);
                    ratesArr.push(rateAmount);
                  }

                }
              }
              // 
            }
            // 
          }else if(!smoker){
            // console.log('You are a Not Smoker');
            // gender
            if (gender === "male") {
              // console.log('You are a male');
              // 
              for (const rates in ageGroup) {
                if (ageGroup.hasOwnProperty(rates)) {
                  const rateAmount = ageGroup[rates];
                  // console.log(rates);
                  if (
                    rates === "MNS0" ||
                    rates === "MNS1" ||
                    rates === "MNS2" ||
                    rates === "MNS3" 
                  ) {
                    // console.log(`Rate: ${rateAmount}`);
                    ratesArr.push(rateAmount);
                  }

                }
              }
              // 
            }else{
              // console.log('You are not a male');
              // 
              for (const rates in ageGroup) {
                if (ageGroup.hasOwnProperty(rates)) {
                  const rateAmount = ageGroup[rates];
                  // console.log(rates);
                  if (
                    rates === "FNS0" ||
                    rates === "FNS1" ||
                    rates === "FNS2" ||
                    rates === "FNS3" 
                  ) {
                    // console.log(`Rate: ${rateAmount}`);
                    ratesArr.push(rateAmount);
                  }

                }
              }
              // 
            }
            //
          }
        }
        
        
      }
    }
    // 
    if (ratesArr[0] === undefined) {
      console.log('This rate is undefined');
    }else{
      // Array check //
      ratesYearlyArr = this.calculateYearlyRates(ratesArr, coverage);
      ratesMonthlyArr = this.calculateMonthlyRates(ratesYearlyArr);
      if (company === 'ACL_WL') {
        this.ACL_WL = ratesMonthlyArr;
      }else{
        this.ACLRates[company] = ratesMonthlyArr;
      }
      // 
      // console.log('this rate is' + ratesArr[0]);
    }
    // Array check //
    // ratesYearlyArr = this.calculateYearlyRates(ratesArr, coverage);
    // ratesMonthlyArr = this.calculateMonthlyRates(ratesYearlyArr);
    // if (company === 'ACL_WL') {
    //   this.ACL_WL = ratesMonthlyArr;
    // }else{
    //   this.ACLRates[company] = ratesMonthlyArr;
    // }
    // 
  }
  // ACL Quote System ends/////////////////////////////////////////////////
  ////////////////////////////////
  // %%
  // %%
  //////////////////////////////////
  // Calculate Monthly & Yearly Rates /////////////////////////////////////
  // CPP ACL
  // All Return Functions...
  calculateYearlyRates(rates, coverage){
    // console.log(rates);
    const yearlyRates = [];
    const coverageAmount = coverage;
    for (const rate in rates) {
      if (rates.hasOwnProperty(rate)) {
        const element = rates[rate];
        // console.log(element);
        const calcYearRate = (element * (coverageAmount / 1000))+60;
        // console.log(calcYearRate);
        yearlyRates.push(calcYearRate);
      }
    }
    // const 
    return yearlyRates;
  }
  calculateMonthlyRates(rates){
    // console.log(rates);
    const monthlyRates = [];
    for (const rate in rates) {
      if (rates.hasOwnProperty(rate)) {
        const element = rates[rate];
        // const calcMonthlyRate = Math.round(((element/12) + Number.EPSILON) * 100)/100;
        const calcMonthlyRate = element * 0.09;
        // console.log(calcMonthlyRate);
        monthlyRates.push(calcMonthlyRate);
      }
    }
    // const 
    return monthlyRates;
  }
  // Calculate Monthly & Yearly Rates Ends /////////////////////////////////////
  //////////////////////////////////
  // %%


  // %%
  ////////////////////////////////
  // ACL Quote System /////////////////////////////////////////////////
  dataLoop_SLI(){
    // console.log('sli json');
    // console.log(this.jsonSLIArr);
    for (const json in this.jsonSLIArr) {
      if (this.jsonSLIArr.hasOwnProperty(json)) {
        const element = this.jsonSLIArr[json];
        // console.log(json);
        // console.log(element);
        this.searchRatesLoop_SLI(element, json);
      }
    }
  }
  searchRatesLoop_SLI(json, company){
    const companyName = company;
    // console.log(`The company is ${company}`);
    //  
    const jsonData = json;
    // console.log(jsonData);
    const personAge = this.quoteForm.value.age;
    const smoker = this.quoteForm.value.smoker;
    const gender = this.quoteForm.value.gender;
    const coverage = this.quoteForm.value.coverage;
    // console.log(personAge);
    // 
    const ratesArr = [];
    let ratesMonthlyArr = [];
    let ratesYearlyArr = [];
    for (const data in jsonData) {
      if (jsonData.hasOwnProperty(data)) {
        const ageGroup = jsonData[data];
        const age = ageGroup['AGE'];
        // console.log(age);
        if (age === personAge) {
          // console.log(`You are ${age} years old.`);
          if (smoker) {
            // console.log('You are a Smoker');
            // gender
            if (gender === "male") {
              // console.log('You are a male');
              // 
              for (const rates in ageGroup) {
                if (ageGroup.hasOwnProperty(rates)) {
                  const rateAmount = ageGroup[rates];
                  // console.log(rates);
                  if (
                    rates === "MSM0" ||
                    rates === "MSM1" ||
                    rates === "MSM2" ||
                    rates === "MSM3" 
                  ) {
                    // console.log(`Rate: ${rateAmount}`);
                    ratesArr.push(rateAmount);
                  }

                }
              }
              // 
            }else{
              // console.log('You are not a male');
              // 
              for (const rates in ageGroup) {
                if (ageGroup.hasOwnProperty(rates)) {
                  const rateAmount = ageGroup[rates];
                  // console.log(rates);
                  if (
                    rates === "FSM0" ||
                    rates === "FSM1" ||
                    rates === "FSM2" ||
                    rates === "FSM3" 
                  ) {
                    // console.log(`Rate: ${rateAmount}`);
                    ratesArr.push(rateAmount);
                  }

                }
              }
              // 
            }
            // 
          }else if(!smoker){
            // console.log('You are a Not Smoker');
            // gender
            if (gender === "male") {
              // console.log('You are a male');
              // 
              for (const rates in ageGroup) {
                if (ageGroup.hasOwnProperty(rates)) {
                  const rateAmount = ageGroup[rates];
                  // console.log(rates);
                  if (
                    rates === "MNS0" ||
                    rates === "MNS1" ||
                    rates === "MNS2" ||
                    rates === "MNS3" 
                  ) {
                    // console.log(`Rate: ${rateAmount}`);
                    ratesArr.push(rateAmount);
                  }

                }
              }
              // 
            }else{
              // console.log('You are not a male');
              // 
              for (const rates in ageGroup) {
                if (ageGroup.hasOwnProperty(rates)) {
                  const rateAmount = ageGroup[rates];
                  // console.log(rates);
                  if (
                    rates === "FNS0" ||
                    rates === "FNS1" ||
                    rates === "FNS2" ||
                    rates === "FNS3" 
                  ) {
                    // console.log(`Rate: ${rateAmount}`);
                    ratesArr.push(rateAmount);
                  }

                }
              }
              // 
            }
            //
          }
        }
        
        
      }
    }
    // 
    // Array check //
    // console.log(ratesArr);

    ratesYearlyArr = this.calculateYearlyRatesSLI(ratesArr, coverage);
    // ratesMonthlyArr = this.calculateMonthlyRatesSLI(ratesYearlyArr);
    // Monthly rate!?!?!? don't know why but it works
    const mRate = []
    for (const rate in ratesYearlyArr) {
      if (ratesYearlyArr.hasOwnProperty(rate)) {
        const element = ratesYearlyArr[rate];
        const calcM = element /12;
        mRate.push(calcM)
      }
    }
    if (company === 'SLI_WL') {
      this.SLI_WL = mRate;
      console.log('sli wl trig');
      // console.log(ratesMonthlyArr);
    }else{
      // this.ACLRates[company] = ratesMonthlyArr;
    }
    // 
  }
  // ACL Quote System ends/////////////////////////////////////////////////
  ////////////////////////////////
  // %%
  
  // %%
  //////////////////////////////////
  // Calculate Monthly & Yearly Rates /////////////////////////////////////
  // SLI
  // All Return Functions...
  calculateYearlyRatesSLI(rates, coverage){ 
    // console.log(rates);
    const yearlyRates = [];
    const coverageAmount = coverage;
    for (const rate in rates) {
      if (rates.hasOwnProperty(rate)) {
        const element = rates[rate];
        // console.log(element);
        const calcYearRate = (element * (coverageAmount / 1000))+60;
        // console.log(calcYearRate);
        yearlyRates.push(calcYearRate);
      }
    }
    // const 
    return yearlyRates;
  }
  calculateMonthlyRatesSLI(rates){
    // console.log(rates);
    const monthlyRates = [];
    for (const rate in rates) {
      if (rates.hasOwnProperty(rate)) {
        const element = rates[rate];
        // const calcMonthlyRate = Math.round(((element/12) + Number.EPSILON) * 100)/100;
        const calcMonthlyRate = Math.round(element / 12);
        // console.log(calcMonthlyRate);
        monthlyRates.push(calcMonthlyRate);
      }
    }
    // const 
    return monthlyRates;
  }
  // Calculate Monthly & Yearly Rates Ends /////////////////////////////////////
  //////////////////////////////////
  // %%



  // %%
  ////////////////////////////////
  // AIT Quote System /////////////////////////////////////////////////
  dataLoop_AIT(){
    let jsonAITArr;
    
    if (this.quoteForm.get('coverage').value >=20000 && this.quoteForm.get('coverage').value <=49999 ) {
      console.log('coverage is c1');
      jsonAITArr = this.jsonAIT1Arr;
    }else if (this.quoteForm.get('coverage').value >=50000 && this.quoteForm.get('coverage').value <=99999 ) {
      console.log('coverage is c2'); 
      jsonAITArr = this.jsonAIT2Arr; 
    }else if (this.quoteForm.get('coverage').value >=100000 && this.quoteForm.get('coverage').value <=199999 ) {
      console.log('coverage is c3');
      jsonAITArr = this.jsonAIT3Arr; 
      console.log(jsonAITArr);
    }else if (this.quoteForm.get('coverage').value >=200000 && this.quoteForm.get('coverage').value <=499999 ) {
      console.log('coverage is c4'); 
      jsonAITArr = this.jsonAIT4Arr; 
    }else if (this.quoteForm.get('coverage').value >=500000) {
      console.log('coverage is c5'); 
      jsonAITArr = this.jsonAIT5Arr; 
    }
    // 
    for (const json in jsonAITArr) {
      if (jsonAITArr.hasOwnProperty(json)) {
        const element = jsonAITArr[json];
        // console.log(json);
        // console.log(element);
        this.searchRatesLoop_AIT(element, json);
      }
    }
  }
  searchRatesLoop_AIT(json, company){
    const companyName = company;
    // console.log(`The company is ${company}`);
    //  
    const jsonData = json;
    // console.log(jsonData);
    const personAge = this.quoteForm.value.age;
    const smoker = this.quoteForm.value.smoker;
    const gender = this.quoteForm.value.gender;
    const coverage = this.quoteForm.value.coverage;
    // console.log(personAge);
    // 
    const ratesArr = [];
    let ratesMonthlyArr = [];
    let ratesYearlyArr = [];
    for (const data in jsonData) {
      if (jsonData.hasOwnProperty(data)) {
        const ageGroup = jsonData[data];
        const age = ageGroup['AGE'];
        // console.log(age);
        if (age === personAge) {
          // console.log(`You are ${age} years old.`);
          if (smoker) {
            // console.log('You are a Smoker');
            // gender
            if (gender === "male") {
              // console.log('You are a male');
              // 
              for (const rates in ageGroup) {
                if (ageGroup.hasOwnProperty(rates)) {
                  const rateAmount = ageGroup[rates];
                  // console.log(rates);
                  if (
                    rates === "MSM0" ||
                    rates === "MSM1" ||
                    rates === "MSM2" ||
                    rates === "MSM3" 
                  ) {
                    // console.log(`Rate: ${rateAmount}`);
                    ratesArr.push(rateAmount);
                  }

                }
              }
              // 
            }else{
              // console.log('You are not a male');
              // 
              for (const rates in ageGroup) {
                if (ageGroup.hasOwnProperty(rates)) {
                  const rateAmount = ageGroup[rates];
                  // console.log(rates);
                  if (
                    rates === "FSM0" ||
                    rates === "FSM1" ||
                    rates === "FSM2" ||
                    rates === "FSM3" 
                  ) {
                    // console.log(`Rate: ${rateAmount}`);
                    ratesArr.push(rateAmount);
                  }

                }
              }
              // 
            }
            // 
          }else if(!smoker){
            // console.log('You are a Not Smoker');
            // gender
            if (gender === "male") {
              // console.log('You are a male');
              // 
              for (const rates in ageGroup) {
                if (ageGroup.hasOwnProperty(rates)) {
                  const rateAmount = ageGroup[rates];
                  // console.log(rates);
                  if (
                    rates === "MNS0" ||
                    rates === "MNS1" ||
                    rates === "MNS2" ||
                    rates === "MNS3" 
                  ) {
                    // console.log(`Rate: ${rateAmount}`);
                    ratesArr.push(rateAmount);
                  }

                }
              }
              // 
            }else{
              // console.log('You are not a male');
              // 
              for (const rates in ageGroup) {
                if (ageGroup.hasOwnProperty(rates)) {
                  const rateAmount = ageGroup[rates];
                  // console.log(rates);
                  if (
                    rates === "FNS0" ||
                    rates === "FNS1" ||
                    rates === "FNS2" ||
                    rates === "FNS3" 
                  ) {
                    // console.log(`Rate: ${rateAmount}`);
                    ratesArr.push(rateAmount);
                  }

                }
              }
              // 
            }
            //
          }
        }
        
        
      }
    }
    // 
    if (ratesArr[0] === undefined) {
      // console.log('This rate is undefined');
    }else{
      // Array check //
      // console.log(ratesArr);
  
      ratesYearlyArr = this.calculateYearlyRates_AIT(ratesArr, coverage);
      ratesMonthlyArr = this.calculateMonthlyRates_AIT(ratesYearlyArr);
      if (company === 'AIT_WL') {
        this.AIT_WL = ratesYearlyArr;
        // console.log('sli wl trig');
        // console.log(ratesMonthlyArr);
      }else{
        this.AITRates[company] = ratesYearlyArr;
      }
      // 
      // console.log('this rate is' + ratesArr[0]);
    }
  }
  // AIT Quote System ends/////////////////////////////////////////////////
  ////////////////////////////////
  // %%
  // %%
  //////////////////////////////////
  // Calculate Monthly & Yearly Rates /////////////////////////////////////
  // SLI
  // All Return Functions...
  calculateYearlyRates_AIT(rates, coverage){ 
    // console.log(rates);
    const yearlyRates = [];
    const coverageAmount = coverage;
    for (const rate in rates) {
      if (rates.hasOwnProperty(rate)) {
        const element = rates[rate];
        // console.log(element);
        const calcYearRate = (element * (coverageAmount / 1000)) + 5.4;
        // console.log(calcYearRate);
        yearlyRates.push(calcYearRate);
      }
    }
    // const 
    return yearlyRates;
  }
  calculateMonthlyRates_AIT(rates){
    // console.log(rates);
    const monthlyRates = [];
    for (const rate in rates) {
      if (rates.hasOwnProperty(rate)) {
        const element = rates[rate];
        // const calcMonthlyRate = Math.round(((element/12) + Number.EPSILON) * 100)/100;
        const calcMonthlyRate = element / 12;
        // console.log(calcMonthlyRate);
        monthlyRates.push(calcMonthlyRate);
      }
    }
    // const 
    return monthlyRates;
  }
  // Calculate Monthly & Yearly Rates Ends /////////////////////////////////////
  //////////////////////////////////
  // %%


  // %%
  ///////////////////////////////////////
  // Date Formatting Functions /////////////////////////////////////////////////
  addDays(date, days){
    const newDate = new Date(date.valueOf());
    newDate.setDate(newDate.getDate() + days);
    return newDate;
  }
  removeYears(date, years){
    const newDate = new Date(date.valueOf());
    newDate.setFullYear(newDate.getFullYear() - years);
    return newDate;
  }
  // Date Formatting Functions Ends //////////////////////////////////////////
  ///////////////////////////////////////
  // %%
  
  // %%
  ///////////////////////////////////////
  // Coverage  /////////////////////////////////////////////////
  findCovMid(a,b){
    return ((a+b)/2)-(a/2);
  }
  setCoverageMinMax(type:string){
    // 
    switch (type) {
      case 'burI':
        this.covMin = 5000;
        this.covMax = 50000;
        this.covCenter = this.findCovMid(this.covMin, this.covMax);
        this.quoteForm.controls.coverage.patchValue(this.covCenter);
        this.covStep = 5000;
        break;
      case 'creI':
        this.covMin = 5000;
        this.covMax = 50000;
        this.covCenter = this.findCovMid(this.covMin, this.covMax);
        this.quoteForm.controls.coverage.patchValue(this.covCenter);
        this.covStep = 5000;
        break;
      case 'diaI':
        this.covMin = 5000;
        this.covMax = 500000;
        this.covCenter = this.findCovMid(this.covMin, this.covMax);
        this.quoteForm.controls.coverage.patchValue(this.covCenter);
        this.covStep = 10000;
        break;
      case 'canI':
        this.covMin = 5000;
        this.covMax = 500000;
        this.covCenter = this.findCovMid(this.covMin, this.covMax);
        this.quoteForm.controls.coverage.patchValue(this.covCenter);
        this.covStep = 10000;
        break;
      case 'lifI':
        this.covMin = 10000;
        this.covMax = 2000000;
        this.covCenter = this.findCovMid(this.covMin, this.covMax);
        this.quoteForm.controls.coverage.patchValue(this.covCenter);
        this.covStep = 10000;
        break;
      case 'nonI':
        this.covMin = 5000;
        this.covMax = 500000;
        this.covCenter = this.findCovMid(this.covMin, this.covMax);
        this.quoteForm.controls.coverage.patchValue(this.covCenter);
        this.covStep = 10000;
        break;
      case 'proI':
        this.covMin = 5000;
        this.covMax = 500000;
        this.covCenter = this.findCovMid(this.covMin, this.covMax);
        this.quoteForm.controls.coverage.patchValue(this.covCenter);
        this.covStep = 10000;
        break;
    
      default:
        break;
    }
  }

  // Coverage  Ends //////////////////////////////////////////
  ///////////////////////////////////////
  // %%

  // %%
  ///////////////////////
  // Submit /////////////////////////////////////////////
  onSubmit(){
    console.log('Submit');
    
  }
  startQuote(){
    // AIT...
    this.dataLoop_AIT();
    if (this.AIT_WL.length === 0) {
      console.log('There is nothing here');      
    }else{
      this.companyRates_WL['AIT_WL'] = this.AIT_WL;
    }
    this.companyRates['AIT'] = this.AITRates; 
    // SLI...
    this.dataLoop_SLI();
    if (this.SLI_WL.length === 0) {
      console.log('There is nothing here');
    }else{
      this.companyRates_WL['SLI_WL'] = this.SLI_WL;
      // console.log(this.companyRates_WL);
    }
    // ACL...
    this.dataLoop_ACL();
    // console.log('ac');
    // console.log(this.ACL_WL);
    if (this.ACL_WL.length === 0) {
      console.log('There is nothing here');      
    } else {     
      this.companyRates_WL['ACL_WL'] = this.ACL_WL;
    } 
    this.companyRates['ACL'] = this.ACLRates;  
    // CPP...
    this.dataLoop_CPP();
    this.companyRates['CPP'] = this.CPPRates;
    this.companyRates_WL['CPP_WL'] = this.CPP_WL;
    // console.log(this.companyRates_WL);
    // 
    // console.log('All Company Rates');
    // console.log(this.companyRates);
    // console.log(this.companyRates_WL);
    
    // 
    this.getLowestQuote();

  }
  // Submit Ends /////////////////////////////////////////////
  ////////////////////////
  // %%

  // %%
  ///////////////////////
  // Quote Display /////////////////////////////////////////////
  lowestQuoteArr = [];
  topQuotesTermArr = [];
  topQuotesWLArr = []
  getLowestQuote(){
    this.topQuotesTermArr = [];
    this.topQuotesWLArr = [];
    const companyRates = this.companyRates;
    const companyRates_WL = this.companyRates_WL;

    // Term Life ...
    for (const company in companyRates) {
      if (companyRates.hasOwnProperty(company)) {
        const element = companyRates[company];
        for (const term in element) {
          if (element.hasOwnProperty(term)) {
            const policy = element[term];
            this.topQuotesTermArr.push(
              {
                "term": term,
                "value": policy[0]
              }
            )
          }
        }
        
      }
    }
    // 

    // Whole Life ...
    for (const company in companyRates_WL) {
      if (companyRates_WL.hasOwnProperty(company)) {
        const value = companyRates_WL[company];
        this.topQuotesWLArr.push(
          {
            "company": company,
            "value": value[0]
          }
        );
        // console.log(value);
        
      }
    }
    // 

    // 
    console.log('Sorted Term');
    this.topQuotesTermArr.sort((a, b) => a.value - b.value);
    // console.log(this.topQuotesTermArr);
    //
    console.log('Sorted Whole life'); 
    this.topQuotesWLArr.sort((a, b) => a.value - b.value);
    // console.log(this.topQuotesWLArr);
    // 

  }
  // 
  // Quote Display Ends /////////////////////////////////////////////
  ////////////////////////
  // %%

  // %%
  ////////////////////////
  // Router function //////////////////////////////////////////////
  applyRouter( term,appCode){
    console.log(`Term Code: ${term}`);
    console.log(`Display Code: ${appCode}`);
    // 
    this.quoteForm.get('term').patchValue(term);
    this.quoteForm.get('companyCode').patchValue(appCode);
    this.qqRouterFormService.qqFormSet(this.quoteForm);
    if (appCode === 'CPP') {
      console.log('CPP Route');     
    } else if (appCode === 'ACL') {
      console.log('ACL Route');      
    } else if (appCode === 'IAT') {
      console.log('IAT Route');      
    }else if (appCode === 'SLI') {
      console.log('SLI Route');     
    }
    this.router.navigate([`/${appCode}-App`]);

  }
  // Router function ends //////////////////////////////////////////////
  ////////////////////////
  // %%
}
