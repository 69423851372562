// 
// IMPORTS
import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { FormGroup, FormControl, Validators } from '@angular/forms';

// SERVICES
import { QqRouterFormService } from "src/app/services/qq-router-form.service";
import { QuoteApiService } from "src/app/services/quote-api.service";

// HTTP/ROUTER
import { HttpClient } from "@angular/common/http";
import { Router } from '@angular/router';

@Component({
  selector: 'app-acl-app',
  templateUrl: './acl-app.component.html',
  styleUrls: ['./acl-app.component.scss']
})
export class AclAppComponent implements OnInit {
  //

  /////////////////////////////
  // DATES //
  minDate = new Date();
  maxDate = new Date();
  todaysDate = new Date();
  //

  ///////////////////////////
  // DISABLE BOOLEANS
  disableQuoteOutput:boolean = true;
  quoting:boolean = false;
  isMonthly:boolean = false;
  changeQuote:boolean = false;

  // TERM INFO SET... 
  termArr = [
    {
      "text": "T15",
      "value": "T15"
    },
    {
      "text": "T20",
      "value": "T20"
    },
    {
      "text": "T25",
      "value": "T25"
    },
    {
      "text": "Whole Life",
      "value": "WL"
    },
  ]
  // 
  // APP TITLE
  appTitle = "ACL APPLICATION";

  footerColor(){
    return "#003DA5"
  }

  ////////////////////////////////////
  // QUOTE API RETURN DATA
  apiReturnData:any;
  // 
  
  //////////////////////////////////////
  // FORM HOLDER FOR QUICK QUOTE FORM
  // qQuoteForm:FormGroup;
  
  // Testing form | REAL SET ABOVE ^^^
  qQuoteForm = new FormGroup({
    "coverage": new FormControl(200000, Validators.required),
    "smoker": new FormControl(true, Validators.required),
    "gender": new FormControl("male", Validators.required),
    "birthDate": new FormControl(this.removeYears(this.todaysDate,42) ),
    "name": new FormControl("Bryan Dignard"),
    "email": new FormControl("bidggs@live.com"),
    "phone": new FormControl("555-555-5555"),
    "age": new FormControl(42, Validators.required),
    // 
    // 
    "term": new FormControl("T15", Validators.required)
  });
  // 

  ///////////////////////////////
  // Form for CPP quote API
  quoteForm = new FormGroup({
    "coverage": new FormControl(null, Validators.required),
    "smoker": new FormControl(null, Validators.required),
    "gender": new FormControl(null, Validators.required),
    "age": new FormControl(null, Validators.required),
    "term": new FormControl(null, Validators.required)
  });
  // 

  ////////////////////////
  // PERSONAL INFO FORM
  personalInfoForm = new FormGroup({
    "idInfoForm": new FormGroup({
      "firstName": new FormControl(null, Validators.required),
      "middleName": new FormControl(null),
      "lastName": new FormControl(null, Validators.required),
      "gender": new FormControl(null, Validators.required),
      "smoker": new FormControl(null, Validators.required),
      "dob": new FormControl(null, Validators.required),
      "citizen": new FormControl(null, Validators.required),
      "country": new FormControl(null, Validators.required),
      "sin": new FormControl(null, Validators.required),
      "idType": new FormControl(null, Validators.required),
      "idNum": new FormControl(null, Validators.required),
      "idProvince": new FormControl(null, Validators.required),
      "idExpiry": new FormControl(null, Validators.required),
      "age": new FormControl(null)
    }),
    "addressForm": new FormGroup({
      "addressType": new FormControl(null, Validators.required),
      "address": new FormControl(null, Validators.required),
      "unit": new FormControl(null, Validators.required),
      "city": new FormControl(null, Validators.required),
      "postal": new FormControl(null, Validators.required)
    }),
    "contact": new FormGroup({
      "phone": new FormControl(null, [
        Validators.required, 
        Validators.pattern("^[0-9()-]*$")
      ]),
      "altNum": new FormControl(null),
      "email": new FormControl(null, Validators.required)
    })
  }) 
  ///////////////////////
  // PLAN INFO FORM
  planInfoForm = new FormGroup({
    "term": new FormControl(null, Validators.required),
    "coverage": new FormControl(null, Validators.required)
  })

  //////////////////////
  // QUESTIONS A FORM
  questionForm_A = new FormGroup({
    "aq1": new FormControl(null, Validators.required),
    "aq2": new FormControl(null, Validators.required),
    "aq3": new FormControl(null, Validators.required),
    "aq4": new FormControl(null, Validators.required),
    "aq5": new FormControl(null, Validators.required),
    "aq6": new FormControl(null, Validators.required),
    "aq7": new FormControl(null, Validators.required),
    "aq8": new FormControl(null, Validators.required),
    "aq9": new FormControl(null, Validators.required),
    "aq10": new FormControl(null, Validators.required),
    "aq11": new FormControl(null, Validators.required),
    "aq12": new FormControl(null, Validators.required)
  })
  
  ///////////////////////////////
  // DRAWER BREAKPOINT
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );
  // // //

  constructor(
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private http: HttpClient,
    private RouterFormService: QqRouterFormService,
    private apiService: QuoteApiService
  ) {

  }

  // 
  ////////////////////////////////////
  // ngOnInit ////////////////////////////
  ngOnInit(): void {
    this.minDate = this.removeYears(this.todaysDate, 80)
    ////////////////////////////
    // FORM LISTENERS /////////////
    this.personalInfoForm.get('idInfoForm').valueChanges.subscribe(
      (value) =>{
        console.log('idInfoForm value');
        console.log(value);
        const personalInfoForm = this.personalInfoForm.get('idInfoForm');
        const planForm  = this.planInfoForm;
        const quoteForm = this.quoteForm; 
        // 
        // Quote Form transfer
        // Gender...
        if (personalInfoForm.get('gender').valid) {
          console.log('Gender app is valid');
          this.changeQuote = true;
        }
        // Smoker...
        if (personalInfoForm.get('smoker').valid) {
          console.log('smoker app is valid');
          this.changeQuote = true;
        }
        // Age...
        // if (personalInfoForm.get('age').valid) {
        //   console.log('age app is valid');
        //   this.changeQuote = true;
        // }
        // Age...
        if (personalInfoForm.get('dob').valid) {
          console.log('Birth date app is valid');
          console.log(personalInfoForm.get('dob'));
          // 
          // this.setAgeValue(personalInfoForm.get('dob').value);
          // 
          this.changeQuote = true;
        }
      }
    );
    this.planInfoForm.valueChanges.subscribe(
      (value) =>{
        const quoteForm = this.quoteForm; 
        const planForm = this.planInfoForm;
        // Coverage...
        if (planForm.get('coverage').valid) {
          console.log('smoker app is valid');
          this.changeQuote = true;
        }
        // term...
        if (planForm.get('term').valid) {
          console.log('smoker app is valid');
          this.changeQuote = true;
        }

      }
    )
    // quick quote form listeners...
    this.quoteForm.statusChanges.subscribe(
      (status) =>{
        console.log(`CPP Quote Status: ${status}`);
      }
    );
    this.quoteForm.valueChanges.subscribe(
      (value) => {
        if (this.quoteForm.valid) {
          console.log('in value is valid');
          // this.apiService.cppQuote = this.quoteForm.value;
          console.log('cpp form from service');
          console.log(value);
        }else{
          console.log('in value is invalid');
        }
      }
    )
    if (this.qQuoteForm) {
      this.transferQuote()
    } else {
      console.log('Form from quick quote is invalid');
    }
    // 

    // Testing ccp quote api..
    // this.getQuote();
  }
  // ngOnInit ENDS ////////////////////////////
  /////////////////////// 
  // 
  
  // %%
  ///////////////////////////////
  // Quote functions //////////
  
  // Quick Quote transfer...
  transferQuote(){
    console.log('Transfer Quick Quote');
    // Plan Form
    this.planInfoForm.get('term').patchValue(this.qQuoteForm.get('term').value);
    this.planInfoForm.get('coverage').patchValue(this.qQuoteForm.get('coverage').value);
    // ID Info Form
    this.personalInfoForm.get('idInfoForm').get('smoker').patchValue(this.qQuoteForm.get('smoker').value);
    this.personalInfoForm.get('idInfoForm').get('gender').patchValue(this.qQuoteForm.get('gender').value);
    this.personalInfoForm.get('idInfoForm').get('age').patchValue(this.qQuoteForm.get('age').value);
    this.personalInfoForm.get('idInfoForm').get('dob').patchValue(this.qQuoteForm.get('birthDate').value);
    // Contact form
    this.personalInfoForm.get('contact').get('email').patchValue(this.qQuoteForm.get('email').value);
    this.personalInfoForm.get('contact').get('phone').patchValue(this.qQuoteForm.get('phone').value);
    // 
    this.sendChangeToQuote();
  }
  sendChangeToQuote(){
    this.isMonthly = false;
    const personalInfoForm = this.personalInfoForm.get('idInfoForm');
    const planForm = this.planInfoForm;
    // 
    console.log('PATCH PROBLEM');
    console.log(personalInfoForm.get('dob').value);
    console.log(this.setAgeValue(personalInfoForm.get('dob').value));
    this.quoteForm.get('gender').patchValue(personalInfoForm.get('gender').value);
    this.quoteForm.get('smoker').patchValue(personalInfoForm.get('smoker').value);
    this.quoteForm.get('age').patchValue(this.setAgeValue(personalInfoForm.get('dob').value));
    this.quoteForm.get('coverage').patchValue(planForm.get('coverage').value);
    this.quoteForm.get('term').patchValue(planForm.get('term').value);
    // 
    this.getQuote();
    // 
    this.changeQuote = false;
  }
  // Quote functions ends//////////
  ///////////////////////////////
  // %%
  
  // %%
  ///////////////////////////////////////
  // Date Formatting Functions /////////////////////////////////////////////////
  addDays(date, days){
    const newDate = new Date(date.valueOf());
    newDate.setDate(newDate.getDate() + days);
    return newDate;
  }
  removeYears(date, years){
    const newDate = new Date(date.valueOf());
    newDate.setFullYear(newDate.getFullYear() - years);
    return newDate;
  }
  // Date Formatting Functions Ends //////////////////////////////////////////
  ///////////////////////////////////////
  // %% 
  
  // %%
  //////////////////////////////////////////
  // Set Age From Datepicker ////////////////////////////////////////
  setAgeValue(value){
    console.log("SETAGE");
    console.log(value);
    const daysFromBirth = (this.maxDate.getTime() - value.getTime())/(24*60*60*1000)
    console.log('This should be days totaled ' + daysFromBirth);
    const age = Math.floor(daysFromBirth/365);
    console.log('Your age is ' + age);
    return age;
  }
  //////////////////////////////////////////
  // %%


  // %% 
  ////////////////////////
  // Accordion Logic...
  step = 1;
  setStep(index: number) {
    this.step = index;
  }
  nextStep() {
    this.step++;
  }
  prevStep() {
    this.step--;
  } 
  // Accordion Logic ends...
  /////////////////////////////
  // %%

  // %%
  ////////////////////////////
  // *** API SYSTEM SERVICE //////////////////////////////
  apiLocation = 'https://us-central1-tsquotesapp.cloudfunctions.net/aclQuote';
  getQuote(){
    console.log(`quoting in:  ${this.disableQuoteOutput}`);
    this.apiService.getQuote(this.quoteForm.value, this.apiLocation).subscribe(resData => {
      console.log('SUCCESS!!!');
      console.log(resData);
      console.log(resData.body);
      this.apiReturnData = resData.body[0];
      // 
      this.isMonthly = true;

    }, error =>{
      console.log('ERROR');
      console.log(error);
      // 
      this.isMonthly = false;
    })
  };
  // *** API SYSTEM SERVICE //////////////////////////////
  ////////////////////////////
  // %%
  
  ///////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////
  // Google api handler /////////////////
  /////////////////////////////
  public handleAddressChange(address: any) {
    // Full formatted address 
    // this.visitorsPage2Form.controls.contactInfoGroup.value.contactAddress = address.formatted_address;
    console.log('This is triggered');
    console.log(address.formatted_address);
    // this.personalInfoForm.controls. = address.formatted_address;
    this.personalInfoForm.controls.addressForm.value.address = address.formatted_address;
    //
    // Object check for address components below...
    // console.log(address);
    // console.log(address.address_components[0].types[0]);
    // 
    // Parse through the address component
    // - for each element in the full address
    let city;
    let postal;
    address.address_components.forEach(element => {
      // console.log(element.types[0]);
      // console.log(element.long_name);
      const adType = element.types[0];
      const adName = element.long_name;
      if (adType === 'street_number') {
        // this.addressNum = adName;
      } else 
      if(adType === 'route'){
        // this.addressRoute = adName;
      } else 
      if(adType === 'locality'){
        this.personalInfoForm.get('addressForm').get('city').patchValue(adName);
      } else 
      if(adType === 'administrative_area_level_1'){
        // this.addressProvince = adName;
      } else 
      if(adType === 'postal_code'){
        this.personalInfoForm.get('addressForm').get('postal').patchValue(adName);
      }
    });
    //  
  } 
  // 
  // /////////////////////////////////
  /////////////////////////////////////////////////////////////

}
