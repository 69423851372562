// 
// IMPORTS
import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';

// SERVICES
import { QqRouterFormService } from "src/app/services/qq-router-form.service";
import { QuoteApiService } from "src/app/services/quote-api.service";

// HTTP/ROUTER
import { HttpClient } from "@angular/common/http";
import { Router } from '@angular/router';


@Component({
  selector: 'app-cpp-app',
  templateUrl: './cpp-app.component.html',
  styleUrls: ['./cpp-app.component.scss']
})
export class CppAppComponent implements OnInit {
  //

  /////////////////////////////
  // DATES //
  minDate = new Date();
  maxDate = new Date();
  todaysDate = new Date();
  //

  ///////////////////////////
  // DISABLE BOOLEANS
  disableQuoteOutput:boolean = true;
  quoting:boolean = false;
  isMonthly:boolean = false;
  changeQuote:boolean = false;

  // TERM INFO SET... 
  termArr = [
    {
      "text": "T10",
      "value": "T10"
    },
    {
      "text": "T20",
      "value": "T20"
    },
    {
      "text": "T25",
      "value": "T25"
    },
    {
      "text": "T100",
      "value": "T100"
    },
    {
      "text": "Whole Life",
      "value": "WL"
    },
  ]
  // 
  // APP TITLE
  appTitle = "CPP APPLICATION";

  footerColor(){
    return "#C41C25"
  }

  ////////////////////////////////////
  // QUOTE API RETURN DATA
  apiReturnData:any;
  // 
  
  //////////////////////////////////////
  // FORM HOLDER FOR QUICK QUOTE FORM
  // qQuoteForm:FormGroup;
  
  // Testing form | REAL SET ABOVE ^^^
  qQuoteForm = new FormGroup({
    "coverage": new FormControl(1000000, Validators.required),
    "smoker": new FormControl(true, Validators.required),
    "gender": new FormControl("male", Validators.required),
    "birthDate": new FormControl(this.removeYears(this.todaysDate,40) ),
    "name": new FormControl("Bryan Dignard"),
    "email": new FormControl("bidggs@live.com"),
    "phone": new FormControl("555-555-5555"),
    "age": new FormControl(20, Validators.required),
    // 
    // 
    "term": new FormControl("T10", Validators.required)
  });
  // 
  // %%
  ////////////////////////////
  // PLAN FUNCTIONS INIT/////////////////
  // Display month output...
  monthlyPlanArr = [];
  questionStage;
  // Term Drop down arrays
  termDropWLArr = [
    {
      "value": "WL",
      "text": "Whole Life"
    }
  ];
  termDropTermArr = [
    {
      "value": "T10",
      "text": "Term 10"
    },
    {
      "value": "T20",
      "text": "Term 20"
    },
    {
      "value": "T25",
      "text": "Term 25"
    },
    {
      "value": "T100",
      "text": "Term 100"
    },
  ];
  termDropAllArr = [
    {
      "value": "T10",
      "text": "Term 10"
    },
    {
      "value": "T20",
      "text": "Term 20"
    },
    {
      "value": "T25",
      "text": "Term 25"
    },
    {
      "value": "T100",
      "text": "Term 100"
    },
    {
      "value": "WL",
      "text": "Whole Life"
    }
  ];
  termDropArr = [];
  minCover;
  maxCover;
  // PLAN FUNCTIONS INIT ENDS //////////
  ////////////////////////////
  // %%

  ///////////////////////////////
  // Form for CPP quote API
  quoteForm = new FormGroup({
    "coverage": new FormControl(null, Validators.required),
    "smoker": new FormControl(null, Validators.required),
    "gender": new FormControl(null, Validators.required),
    "age": new FormControl(null, Validators.required),
    "term": new FormControl(null, Validators.required)
  });
  // 
  // PLAN INFO FORM
  planInfoForm = new FormGroup({
    "term": new FormControl(null, Validators.required),
    "coverage": new FormControl(null, [Validators.required])
  });
  //////////////////////// 
  ///////////////////////

  // %%
  /////////////////////////////////
  // ### ALL QUESTION FORMS ////////////////////////////////////////
  // PERSONAL INFO FORM
  personalInfoForm = new FormGroup({
    "idInfoForm": new FormGroup({
      "firstName": new FormControl(null, Validators.required),
      "middleName": new FormControl(null),
      "lastName": new FormControl(null, Validators.required),
      "gender": new FormControl(null, Validators.required),
      "smoker": new FormControl(null, Validators.required),
      "dob": new FormControl(null, Validators.required),
      "citizen": new FormControl(null, Validators.required),
      "country": new FormControl(null, Validators.required),
      "sin": new FormControl(null, Validators.required),
      "idType": new FormControl(null, Validators.required),
      "idNum": new FormControl(null, Validators.required),
      "idProvince": new FormControl(null, Validators.required),
      "idExpiry": new FormControl(null, Validators.required),
      "age": new FormControl(null)
    }),
    "addressForm": new FormGroup({
      "addressType": new FormControl(null, Validators.required),
      "address": new FormControl(null, Validators.required),
      "unit": new FormControl(null),
      "city": new FormControl(null, Validators.required),
      "postal": new FormControl(null, Validators.required)
    }),
    "contact": new FormGroup({
      "phone": new FormControl(null, [
        Validators.required, 
        Validators.pattern("^[0-9()-]*$")
      ]),
      "altNum": new FormControl(null),
      "email": new FormControl(null, Validators.required)
    }),
    "weed": new FormControl(null, Validators.required)
  });
  // QUESTIONS A FORM //
  questionSetA = new FormGroup({
    "aq1":new FormControl(null, Validators.required),
    "aq2":new FormControl(null, Validators.required),
    "aq3":new FormControl(null, Validators.required),
    "aq4":new FormControl(null, Validators.required),
    "aq5a":new FormControl(null, Validators.required),
    "aq5b":new FormControl(null, Validators.required),
    "aq5c":new FormControl(null, Validators.required),
    "aq6":new FormControl(null, Validators.required),
    "aq7":new FormControl(null, Validators.required),
    "aq8a":new FormControl(null, Validators.required),
    "aq8b":new FormControl(null, Validators.required),
    "aq8c":new FormControl(null, Validators.required),
    "aq8d":new FormControl(null, Validators.required),
    "aq9":new FormControl(null, Validators.required),
    "aq10":new FormControl(null, Validators.required),
    "aq11":new FormControl(null, Validators.required),
    "aq12":new FormControl(null, Validators.required),
    "height":new FormControl(null, Validators.required),
    "weight":new FormControl(null, [
      Validators.required,
      Validators.pattern("^[0-9]*$")
    ]),
  });
  // QUESTIONS B FORM //
  questionSetB = new FormGroup({
    "bq1a":new FormControl(null, Validators.required),
    "bq1b":new FormControl(null, Validators.required),
    "bq1c":new FormControl(null, Validators.required),
    "bq1d":new FormControl(null, Validators.required),
    "bq2":new FormControl(null, Validators.required)
  });
  // QUESTIONS C FORM //
  questionSetC = new FormGroup({
    "cq1": new FormControl(null, Validators.required),
    "cq2": new FormControl(null, Validators.required),
    "cq3": new FormControl(null, Validators.required),
    "cq4a": new FormControl(null, Validators.required),
    "cq4b": new FormControl(null, Validators.required),
    "cq5": new FormControl(null, Validators.required),
    "cq6": new FormControl(null, Validators.required),
    "cq7": new FormControl(null, Validators.required),
    "cq8a": new FormControl(null, Validators.required),
    "cq8b": new FormControl(null, Validators.required),
    "cq8c": new FormControl(null, Validators.required),
    "cq8d": new FormControl(null, Validators.required),
    "cq8e": new FormControl(null, Validators.required),
    "cq9": new FormControl(null, Validators.required),
    "cq10": new FormControl(null, Validators.required),
    "cq11": new FormControl(null, Validators.required),
    "height":new FormControl(null,  [
      Validators.required,
      Validators.pattern("^[0-9]*$")
    ]),
    "weight":new FormControl(null, Validators.required),
  });
  // QUESTIONS D FORM //
  questionSetD = new FormGroup({
    "dq1":new FormControl(null, Validators.required),
    "dq2":new FormControl(null, Validators.required),
    "dq3":new FormControl(null, Validators.required),
    "dq4":new FormControl(null, Validators.required),
    "dq5":new FormControl(null, Validators.required),
    "dq6":new FormControl(null, Validators.required),
  });
  // QUESTIONS E FORM //
  questionSetE = new FormGroup({
    "eq1": new FormControl("", Validators.required),
    "details": new FormControl(null),
    "physician":new FormControl(null),
    "dateConsulted":new FormControl(null),
    "physicianAddress":new FormControl(null),
    "reason":new FormControl(null)
  });
  // QUESTIONS F FORM //
  questionSetF = new FormGroup({
    "fq1":new FormControl(null, Validators.required),
    "details":new FormControl(null),
    "vaping":new FormControl(null, Validators.required)
  });
  // QUESTIONS BENEFICIARY FORM //
  questionSetBene = new FormGroup({
    "beneInputArr": new FormArray([
        this.addBeneGroup()
    ])
  });
  // questionSetBene = new FormArray([
  //   this.addBeneGroup()
  // ])
  // QUESTIONS POLICY FORM //
  questionSetPolicy = new FormGroup({
    "current": new FormControl(null, Validators.required),
    "firstName": new FormControl(null),
    "middleName": new FormControl(null),
    "lastName": new FormControl(null),
    "gender": new FormControl(null),
    "smoker": new FormControl(null),
    "dob": new FormControl(null),
    "relation": new FormControl(null),
    "country": new FormControl(null),
    "sin": new FormControl(null),
    "idType": new FormControl(null),
    "idNum": new FormControl(null),
    "idProvince": new FormControl(null),
    "idExpiry": new FormControl(null),
    "age": new FormControl(null),
    // 
    "addressType": new FormControl(null),
    "address": new FormControl(null),
    "unit": new FormControl(null),
    "city": new FormControl(null),
    "postal": new FormControl(null)
  });
  // QUESTIONS HISTORY FORM //
  questionSetHistory = new FormGroup({
    "historyQ1": new FormControl(null, Validators.required),
    "hCompany": new FormControl(null),
    "hAmount": new FormControl(null),
    "hPlan": new FormControl(null),
    // 
    "sameDate": new FormControl(null, Validators.required),
    "applicant": new FormControl(null)
  });
  // QUESTIONS PAYMENT FORM //
  questionSetPayment = new FormGroup({
    // Type of payor
    "payor": new FormControl(null, Validators.required),
    // Type of account
    "typeAcc": new FormControl(null, Validators.required),
    "firstNameAcc": new FormControl(null),
    "lastNameAcc": new FormControl(null)
  });
  questionSetBank = new FormGroup({
    "typeAcc": new FormControl(null, Validators.required),
    "transit": new FormControl(null, [
      Validators.required,
      Validators.pattern("^[0-9]*$"),
      Validators.maxLength(5),
      Validators.minLength(5)
    ]),
    "institution": new FormControl(null, [
      Validators.required,
      Validators.pattern("^[0-9]*$"),
      Validators.maxLength(3),
      Validators.minLength(3)
    ]),
    "numAcc": new FormControl(null,  [
      Validators.required, 
      Validators.pattern("^[0-9()-]*$"),
      Validators.maxLength(15),
      Validators.minLength(5)
    ]),
    // 
    "month": new FormControl(null, Validators.required),
    // 
    // 
    "tax": new FormControl(null, Validators.required),
    "countryList": new FormControl(null),
    "taxId": new FormControl(null)
  });
  // QUESTIONS DECLARATIONS FORM //
  questionSetDec = new FormGroup({
    "third": new FormControl(null, Validators.required),
    "paper": new FormControl(null, Validators.required),
    "generalAuth": new FormControl(null, Validators.required),
    "preAuth": new FormControl(null, Validators.required)
  });

  // ### ALL QUESTION FORMS ////////////////////////////////////////
  /////////////////////////////////
  // %%

  // %%
  ////////////////////////////////
  // Questions Functions /////////////////////////
  // Step Variables...
  step0:boolean = false;
  step1:boolean = false;
  step2:boolean = false;
  step3:boolean = false;
  step4:boolean = false;
  step5:boolean = false;
  step6:boolean = false;
  step7:boolean = false;
  step8:boolean = false;
  step9:boolean = false;
  step10:boolean = false;
  step11:boolean = false;
  // Questions Functions Ends /////////////////////////
  ////////////////////////////////
  // %%

  // %%
  /////////////////////////////
  // LEFT SIDEBAR INIT *** ///////////////////////////////////////
  // *** Question info array
  sidebarStepInfoArr = [
    // personal info...
    {
      "title": "PERSONAL INFORMATION",
      // 
      "yes": ``,
      "no": ``,
      // 
      "singleContent": `
      <p><strong>In this application, Insured means the person proposed to be the insured.</strong></p>
      <!--  -->
      <p><span class="sidebar-title-deco">1</span>Must be a Canadian Citizen, Permanent Resident or with a valid work permit.</p>
      <p>The maximum amount for an Insured on a work permit is $250,000.</p>
      <p><span class="sidebar-title-deco">2</span>SIN required only if applying for permanent life insurance(except for T100)</p>
      `
    },
    // Questions...
    // A)..
    {
      "title": "A: GUARANTEED ACCEPTANCE LIFE",
      // 
      "yes": `
      <span class="ex-p">If a question is answered YES in this section, apply for...<br></span>
      <span class="ex-title" > Guaranteed Acceptance Life<br></span>
      <span class="ex-sub-title">Maximum $50,000<br></span>
      `,
      "no": `      
      <span class="ex-p">If ALL NO answers are provided, Continue to section <strong>B</strong><br></span>
      `,
      // 
      "singleContent": ``
    },
    // B)..
    {
      "title": "B: DEFERRED LIFE",
      // 
      "yes": `
      <span class="ex-p">If a question is answered YES in this section, apply for...<br></span>
      <span class="ex-title" > Deferred Life<br></span>
      <span class="ex-sub-title">Maximum $75,000<br></span>
      `,
      "no": `      
      <span class="ex-p">If ALL NO answers are provided, Continue to section <strong>C</strong><br></span>
      `,
      // 
      "singleContent": ``
    },
    // C)..
    {
      "title": "C: DEFERRED ELITE LIFE",
      // 
      "yes": `
      <span class="ex-p">If a question is answered YES in this section, apply for...<br></span>
      <span class="ex-title" > Deferred Elite Life <br></span>
      <span class="ex-sub-title">Maximum $350,000<br></span>
      `,
      "no": `      
      <span class="ex-p">If ALL NO answers are provided, Continue to section <strong>D</strong><br></span>
      `,
      // 
      "singleContent": ``
    },
    // D)..
    {
      "title": "D: SIMPLIFIED ELITE LIFE",
      // 
      "yes": `
      <span class="ex-p">If a question is answered YES in this section, apply for...<br></span>
      <span class="ex-title" > Simplified Elite Life <br></span>
      <span class="ex-sub-title">Maximum $500,000<br></span>
      `,
      "no": `      
      <span class="ex-p">If ALL NO answers are provided, Continue to section <strong>E</strong><br></span>
      `,
      // 
      "singleContent": ``
    },
    // E)..
    {
      "title": "E: PREFERRED LIFE",
      // 
      "yes": `
      <span class="ex-p">MAY BE SUBJECT TO UNDERWRITING<br></span>
      <span class="ex-title" > Preferred Life<br></span>
      <span class="ex-sub-title">Minimum $50,000<br></span>
      <span class="ex-sub-title">Maximum $1,000,000<br></span>
      `,
      "no": `      
      <span class="ex-p">The plan you may be eligible for will be determined by the underwriting department.</span>
      `,
      // 
      "singleContent": ``
    },
    // F)..
    {
      "title": "F: PREFERRED ELITE LIFE",
      // 
      "yes": `
      <span class="ex-p">MAY BE SUBJECT TO UNDERWRITING<br></span>
      <span class="ex-title" > Preferred Elite Life<br></span>
      <span class="ex-sub-title">Minimum $500,000<br></span>
      <span class="ex-sub-title">Maximum $1,000,000<br></span>
      `,
      "no": `      
      <span class="ex-p">The plan you may be eligible for will be determined by the underwriting department.</span>
      `,
      // 
      "singleContent": ``
    },
    // BENEFICIARY)..
    {
      "title": "BENEFICIARY",
      // 
      "yes": ``,
      "no": ``,
      // 
      "singleContent": `
      <p><strong>Total % share must equal 100% for Primary and 100% for Contingent Beneficiaries.</strong></p>
      <!--  -->
      <p>
        <span class="sidebar-title-deco">!</span>Important: Each beneficiary is revocable unless indicated otherwise,.<br>
        However in Quebec, the designation of a legally married spouse of the Owner is irrevocable unless expressly indicated to be revocable.
      </p>
      `
    },
    // POLICY OWNER)..
    {
      "title": "POLICY OWNER",
      // 
      "yes": ``,
      "no": ``,
      // 
      "singleContent": `
      <p><strong>Complete Owner details only if different than Insured</strong></p>
      <!--  -->
      <p><span class="sidebar-title-deco">1</span>If the Owner is a corporation, the signature must be accompanied by either the company name or title of the signing officer OR a company seal.</p>
      <p><span class="sidebar-title-deco">2</span>SIN required only if applying for permanent life insurance(except for T100)</p>
      `
    },
    // INSURANCE HISTORY)..
    {
      "title": "INSURANCE HISTORY",
      // 
      "yes": ``,
      "no": ``,
      // 
      "singleContent": `
      <p><strong>Complete Owner details only if different than Insured</strong></p>
      <!--  -->
      <p>
      <span class="sidebar-title-deco">REPLACEMENT</span><br>
      Replacement declaration required if you are replacing an existing policy.
      </p>
      <p><span class="sidebar-title-deco">SIMULTANEOUS APPLICATIONS</span><br>
      Please indicate if you any of your family members are applying for insurance today along with you.
      </p>
      `
    },
    // PAYMENT)..
    {
      "title": "PAYMENT PLAN",
      // 
      "yes": ``,
      "no": ``,
      // 
      "singleContent": `
      <span class="ex-title" ><b>30 DAY MONEY BACK GUARANTEE</b>
      <br><br></span>
      <!--  -->
      <p><span class="sidebar-title-deco">MONTHLY</span></p>
      <p>For monthly (PAD) payment method, there is no premium debit for the first month</p>
      <p><span class="sidebar-title-deco">NOTE:</span>Each premium for coverage applied for in this Application, will be drawn from the account information provided, unless otherwise instructed.</p>
      `
    },
    // DECLARATIONS)..
    {
      "title": "DECLARATIONS",
      // 
      "yes": ``,
      "no": ``,
      // 
      "singleContent": `
      <p><b>Please click on the link provided to read the following declarations.</b></p>
      THIRD PARTY DETERMINATION<br>
      ELECTRONIC POLICY<br>
      <a href="../../../assets/pdf/pad.pdf"><span class="">PAD PLAN AGREEMENT</span></a><br>
      <a href="../../../assets/pdf/definitions.pdf"><span class="">DEFINITIONS</span></a><br>
      <a href="../../../assets/pdf/agreement.pdf"><span class="">AGREEMENTS</span></a><br>
      <a href="../../../assets/pdf/authorization.pdf"><span class="">AUTHORIZATION</span></a><br>
      `
    },
  ];
  // LEFT SIDEBAR INIT ENDS *** ///////////////////////////////////////
  /////////////////////////////
  // %%

  // %%
  /////////////////////////////
  // Beneficiary Init
  beneInputArr = this.questionSetBene.get('beneInputArr') as FormArray;
  /////////////////////////////
  // %%

  // %%
  /////////////////////////////
  // Weight init...
  heightArr = [
    {
      "value": "a",
      "text":"4\'8\" ; 1.42 m"
    },
    {
      "value": "a",
      "text":"4\'9\" ; 1.45 m"
    },
    {
      "value": "a",
      "text":"4\'10\" ; 1.47 m"
    },
    {
      "value": "b",
      "text":"4\'11\" ; 1.50 m"
    },
    {
      "value": "b",
      "text":"5\'0\" ; 1.52 m"
    },
    {
      "value": "b",
      "text":"5\'1\" ; 1.55 m"
    },
    {
      "value": "c",
      "text":"5\'2\" ; 1.57 m"
    },
    {
      "value": "c",
      "text":"5\'3\" ; 1.60 m"
    },
    {
      "value": "c",
      "text":"5\'4\" ; 1.63 m"
    },
    {
      "value": "d",
      "text":"5\'5\" ; 1.65 m"
    },
    {
      "value": "d",
      "text":"5\'6\" ; 1.68 m"
    },
    {
      "value": "d",
      "text":"5\'7\" ; 1.70 m"
    },
    {
      "value": "e",
      "text":"5\'8\" ; 1.73 m"
    },
    {
      "value": "e",
      "text":"5\'9\" ; 1.75 m"
    },
    {
      "value": "e",
      "text":"5\'10\" ; 1.78 m"
    },
    {
      "value": "f",
      "text":"5\'11\" ; 1.80 m"
    },
    {
      "value": "f",
      "text":"6\'0\" ; 1.83 m"
    },
    {
      "value": "f",
      "text":"6\'1\" ; 1.85 m"
    },
    {
      "value": "g",
      "text":"6\'2\" ; 1.88 m"
    },
    {
      "value": "g",
      "text":"6\'3\" ; 1.90 m"
    },
    {
      "value": "g",
      "text":"6\'4\" ; 1.93 m"
    },
    {
      "value": "g",
      "text":"6\'5\" ; 1.96 m"
    },
    {
      "value": "h",
      "text":"6\'6\" ; 1.98 m"
    },
    {
      "value": "h",
      "text":"6\'7\" ; 2.01 m"
    }
  ];
  // Weight init ends ...
  /////////////////////////////
  // %%


  
  ///////////////////////////////
  // DRAWER BREAKPOINT
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );
  // // //
  
  // 
  //////////////////////////////
  // Constructor //////////////////////////////
  constructor(
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private http: HttpClient,
    private RouterFormService: QqRouterFormService,
    private apiService: QuoteApiService
  ) { 
    // this.qQuoteForm = this.RouterFormService.qqForm;
  }
  // Constructor ENDS //////////////////////////////
  //////////////////////////////
  //  
  // %% 
  ////////////////////////
  // Accordion Logic...
  step = 11;
  setStep(index: number) {
    this.step = index;
    console.log(`This is the Step index: ${index}`);
  }
  nextStep() {
    this.step++;
  }
  prevStep() {
    this.step--;
  } 
  // Accordion Logic ends...
  /////////////////////////////
  // %%


  // %%
  /////////////////////////////
  // Weight logic....
  weightFn(set){
    console.log('weight trigger');
    const weight = this.questionSetA.get('weight').value;
    const height = this.questionSetA.get('height').value;
    if (set === "c"){
      if(height === "a"){
        if(weight >= 185){
          this.questionSetC.get('cq11').patchValue("yes");
        }
      }else{
        this.questionSetC.get('cq11').patchValue("no");
      }
      if(height === "b"){
        if(weight >= 199){
          this.questionSetC.get('cq11').patchValue("yes");
        }
      }  else {
        this.questionSetC.get('cq11').patchValue("no");
      }
      if(height === "c"){
        if(weight >= 215){
          this.questionSetC.get('cq11').patchValue("yes");
        }
      }  else {
        this.questionSetC.get('cq11').patchValue("no");
      }
      if(height === "d"){
        if(weight >= 235){
          this.questionSetC.get('cq11').patchValue("yes");
        }
      }  else {
        this.questionSetC.get('cq11').patchValue("no");
      }
      if(height === "e"){
        if(weight >= 260){
          this.questionSetC.get('cq11').patchValue("yes");
        }
      }  else {
        this.questionSetC.get('cq11').patchValue("no");
      }
      if(height === "f"){
        if(weight >= 282){
          this.questionSetC.get('cq11').patchValue("yes");
        }
      }  else {
        this.questionSetC.get('cq11').patchValue("no");
      }
      if(height === "g"){
        if(weight >= 305){
          this.questionSetC.get('cq11').patchValue("yes");
        }
      }  else {
        this.questionSetC.get('cq11').patchValue("no");
      }
      if(height === "h"){
        if(weight >= 333){
          this.questionSetC.get('cq11').patchValue("yes");
        }
      }  else {
        this.questionSetC.get('cq11').patchValue("no");
      }
    }
    // 
    if (set === "a"){
      if(height === "a"){
        if(weight >= 230){
          this.questionSetA.get('aq12').patchValue("yes");
        }
      }else{
        this.questionSetA.get('aq12').patchValue("no");
      }
      if(height === "b"){
        if(weight >= 247){
          this.questionSetA.get('aq12').patchValue("yes");
        }
      }  else {
        this.questionSetA.get('aq12').patchValue("no");
      }
      if(height === "c"){
        if(weight >= 273){
          this.questionSetA.get('aq12').patchValue("yes");
        }
      }  else {
        this.questionSetA.get('aq12').patchValue("no");
      }
      if(height === "d"){
        if(weight >= 300){
          this.questionSetA.get('aq12').patchValue("yes");
        }
      }  else {
        this.questionSetA.get('aq12').patchValue("no");
      }
      if(height === "e"){
        if(weight >= 328){
          this.questionSetA.get('aq12').patchValue("yes");
        }
      }  else {
        this.questionSetA.get('aq12').patchValue("no");
      }
      if(height === "f"){
        if(weight >= 358){
          this.questionSetA.get('aq12').patchValue("yes");
        }
      }  else {
        this.questionSetA.get('aq12').patchValue("no");
      }
      if(height === "g"){
        if(weight >= 389){
          this.questionSetA.get('aq12').patchValue("yes");
        }
      }  else {
        this.questionSetA.get('aq12').patchValue("no");
      }
      if(height === "h"){
        if(weight >= 420){
          this.questionSetA.get('aq12').patchValue("yes");
        }
      }  else {
        this.questionSetA.get('aq12').patchValue("no");
      }
    }
  }
  weightA:boolean = false;
  weightC:boolean = false;

  // Weight logic ends ....
  /////////////////////////////
  // %%

  // ***%%
  ////////////////////////////////////
  // ngOnInit ////////////////////////////
  ngOnInit(): void {

    // 
    this.minDate = this.removeYears(this.todaysDate, 80)
    // 
    this.termDropArr = this.termDropAllArr;
    ////////////////////////////
    // FORM LISTENERS /////////////
    // quick quote form listeners...
    this.quoteForm.statusChanges.subscribe(
      (status) =>{
        console.log(`CPP Quote Status: ${status}`);
      }
    );
    this.quoteForm.valueChanges.subscribe(
      (value) => {
        if (this.quoteForm.valid) {
          console.log('in value is valid');
          // this.apiService.cppQuote = this.quoteForm.value;
          console.log('cpp form from service');
          console.log(value);
        }else{
          console.log('in value is invalid');
        }
      }
    );
    // Personal Info listeners
    this.personalInfoForm.get('idInfoForm').valueChanges.subscribe(
      (value) =>{
        console.log('idInfoForm value');
        console.log(value);
        const personalInfoForm = this.personalInfoForm.get('idInfoForm');
        const planForm  = this.planInfoForm;
        const quoteForm = this.quoteForm; 
        if (personalInfoForm.valid) {
          this.step0 = true;
        }else{          
          this.step0 = false;
        }
        // 
        // Quote Form transfer
        // Gender...
        if (personalInfoForm.get('gender').valid) {
          console.log('Gender app is valid');
          this.changeQuote = true;
        }
        // Smoker...
        if (personalInfoForm.get('smoker').valid) {
          console.log('smoker app is valid');
          this.changeQuote = true;
        }
        if (personalInfoForm.get('dob').valid) {
          console.log('Birth date app is valid');
          console.log(personalInfoForm.get('dob'));
          // 
          // this.setAgeValue(personalInfoForm.get('dob').value);
          // 
          this.changeQuote = true;
        }
      }
    );
    this.personalInfoForm.statusChanges.subscribe(
      (status) =>{
        // Code...
        console.log(`Personal Fill Form is: ${status}`);
      }
    );
    this.planInfoForm.valueChanges.subscribe(
      (value) =>{
        const quoteForm = this.quoteForm; 
        const planForm = this.planInfoForm;
        // Coverage...
        // term...
        if (planForm.get('term').valid) {
          console.log('smoker app is valid');
          this.changeQuote = true;
        }
        if (planForm.get('coverage').valid) {
          console.log('smoker app is valid');
          this.changeQuote = true;
        }else{
          this.changeQuote = false;

        }

      }
    ); 

    //////////////////////////////
    /////////////////////////////
    // Questions Form Listeners
    // Question A
    this.questionSetA.valueChanges.subscribe(
      (value) =>{
        if (this.questionSetA.valid) {
          this.step1 = true;
        }else{          
          this.step1 = false;
        }
        console.log('Question A Values');
        console.log(value);
        if (this.questionSetA.valid) {
          this.questionAnswerFn(this.questionSetA, "A");          
        }
        // 
        if (this.questionSetA.get('weight').valid && this.questionSetA.get('height').valid) {
          if (!this.weightA){
            console.log('in trigger');
            this.weightA = true;
            this.weightFn("a");
          }
        }
        // 
        this.step = 1;
      }
    );
    // Question B
    this.questionSetB.valueChanges.subscribe(
      (value) =>{
        if (this.questionSetB.valid) {
          this.step2 = true;
        }else{          
          this.step2 = false;
        }
        console.log('Question B Values');
        console.log(value);
        if (this.questionSetB.valid) {
          this.questionAnswerFn(this.questionSetB, "B");          
        }
      }
    );
    // Question C
    this.questionSetC.valueChanges.subscribe(
      (value) =>{
        console.log('Question C Values');
        console.log(value);
        if (this.questionSetC.valid) {
          this.questionAnswerFn(this.questionSetC, "C");          
        }
        if (this.questionSetC.get('weight').valid && this.questionSetC.get('height').valid) {
          if (!this.weightC){
            this.weightC = true;
            this.weightFn("c");
          }
        }
      }
    );
    // Question D
    this.questionSetD.valueChanges.subscribe(
      (value) =>{
        console.log('Question D Values');
        console.log(value);
        if (this.questionSetD.valid) {
          this.questionAnswerFn(this.questionSetD, "D");          
        }
      }
    );
    // Question E
    this.questionSetE.valueChanges.subscribe(
      (value) =>{
        console.log('Question E Values');
        console.log(value);
        if (this.questionSetE.valid) {
          this.questionAnswerFn(this.questionSetE, "E");          
        }
      }
    );
    // Question F
    this.questionSetF.valueChanges.subscribe(
      (value) =>{
        console.log('Question F Values');
        console.log(value);
        if (this.questionSetF.valid) {
          this.questionAnswerFn(this.questionSetF, "F");          
        }
      }
    );
    // Questions Form Listeners ends
    ///////////////////////////////
    // Beneficiary
    this.questionSetBene.valueChanges.subscribe(
      (value) =>{
        console.log('Question Bene Values');
        console.log(value);
      }
    );
    // Policy
    this.questionSetPolicy.valueChanges.subscribe(
      (value) =>{
        console.log('Question Policy Values');
        console.log(value);
      }
    );
    // History
    this.questionSetHistory.valueChanges.subscribe(
      (value) =>{
        console.log('Question History Values');
        console.log(value);
      }
    );
    // Payment
    this.questionSetPayment.valueChanges.subscribe(
      (value) =>{
        console.log('Question Payment Values');
        console.log(value);
      }
    );
    // Bank
    this.questionSetBank.valueChanges.subscribe(
      (value) =>{
        console.log('Question Bank Values');
        console.log(value);
      }
    );
    // Dec
    this.questionSetDec.valueChanges.subscribe(
      (value) =>{
        console.log('Question Dec Values');
        console.log(value);
      }
    );

    // Testing ccp quote api..
    // this.getQuote();
    if (this.qQuoteForm) {
      this.transferQuote()
    } else {
      console.log('Form from quick quote is invalid');
    }
    //
  }
  // ngOnInit ENDS ////////////////////////////
  /////////////////////// 
  // ***%%


  // %%
  //////////////////////////////
  // *** PLAN FUNCTIONS ////////////////////
  checkQuestions(form){
    const formData = form.value
    let answerArr = [];
    let returnData;
    for (const data in formData) {
      if (formData.hasOwnProperty(data)) {
        const answer = formData[data];
        answerArr.push(answer);       
      }
    }
    console.log(answerArr);
    returnData = answerArr.includes("yes");
    console.log(returnData);
    return returnData;
  }
  questionAnswerFn(questionForm, questionLetter){
    const form = questionForm;
    const letter = questionLetter;
    const checkAnswers = this.checkQuestions(questionForm);
    // 
    if (!checkAnswers) {
      console.log('The form is valid go to next>>>');
      this.termDropArr = this.termDropAllArr;
      this.questionStage = '';
      this.sendChangeToQuote('');
    }else if(checkAnswers){
      this.questionStage = letter; // Need to be dynamic<<<
      this.questionSwitch(form,letter);
    }
  }
  questionSwitch(questionForm, questionLetter){
    const form = questionForm;
    const letter = questionLetter;
    switch(letter){
      case "A": {
        this.qAQuoteChangeFn();
        break;
      }
      case "B": {
        this.qBQuoteChangeFn();
      }
      case "C": {
        this.qCQuoteChangeFn();
      }
      case "D": {
        this.qDQuoteChangeFn();
      }
      case "E": {
        this.qEQuoteChangeFn();
      }
      case "F": {
        this.qFQuoteChangeFn();
      }
      default:{
        console.log('Not A Question');
        break;
      }
    }
  };
  // 
  // ** Question Functions ** // 
  // 
  // No letter function..
  planNamesWLArr = [
    "Simplified Elite",
    "Deferred Elite",
    "Deferred",
    "Guaranteed Acceptance"
  ];
  planNamesTermArr = [
    "Preferred Elite",
    "Preferred",
    "Simplified Elite",
    "Deferred Elite"
  ]
  noLetterFn(){
    this.monthlyPlanArr = [];
    const apiData = this.apiReturnData.monthly;
    const apiTerm = this.apiReturnData.term;
    // 
    if (apiTerm === "WL") {
      let i = 0;
      for (const rate in apiData) {
        if (apiData.hasOwnProperty(rate)) {
          const element = apiData[rate];
          console.log('WL no letter trig');
          // 
          this.monthlyPlanArr.push({"name":this.planNamesWLArr[i],"rate":element})
          
        }
        i++;
      }
      i=0;
      
    } else {
      let i = 0;
      for (const rate in apiData) {
        if (apiData.hasOwnProperty(rate)) {
          const element = apiData[rate];
          // 
          this.monthlyPlanArr.push({"name":this.planNamesTermArr[i],"rate":element})
        }
        i++;
      }
      i=0;
      
    }
    // 
    console.log('monthly array');
    console.log(this.monthlyPlanArr);
    
  }
  // 
  // Q:A) Functions ...
  qAQuoteChangeFn(){
    console.log('Question A');
    let apiData = this.apiReturnData;
    const age = this.quoteForm.get('age').value;
    const coverage = this.quoteForm.get('coverage').value;
    // LOGISTICS //
    // Term change...
    if(apiData.term === "WL"){
      // Do nothing yet...
      console.log('*WL change null');
    } else {
      console.log('!WL change to WL');
      this.planInfoForm.get('term').patchValue("WL");
    }
    this.termDropArr = this.termDropWLArr;
    //
    // Coverage // 
    // Age check...
    if(age >= 18 && age <= 60){
      console.log(`Age Check: ${age}`);
      this.minCover = 10000;
      this.maxCover = 50000;
    }else if(age >= 61 && age <= 75){
      console.log(`Age Check: ${age}`);
      this.minCover = 5000;
      this.maxCover = 50000;
    }
    // Coverage Validators
    this.planInfoForm.get('coverage').clearValidators();
    this.planInfoForm.get('coverage').setValidators([
        Validators.min(this.minCover),
        Validators.max(this.maxCover)
      ]);
    this.planInfoForm.get('coverage').updateValueAndValidity(); 
    // 
    // Update coverage
    if (this.planInfoForm.get('coverage').value < this.minCover){
      this.planInfoForm.get('coverage').patchValue(this.minCover);
    }else if (this.planInfoForm.get('coverage').value > this.maxCover){
      this.planInfoForm.get('coverage').patchValue(this.maxCover);
    }
    // 
    // Send to quote System
    this.questionStage = "A";
    this.sendChangeToQuote("A");
    // LOGISTICS ENDS //
    /////////////////
  };
  qADisplayFn(){
    console.log('Question A display function');
    this.monthlyPlanArr = [];
    const apiData = this.apiReturnData.monthly;
    const apiTerm = this.apiReturnData.term;
    // 
    let i = 0;
    for (const rate in apiData) {
      if (apiData.hasOwnProperty(rate)) {
        const element = apiData[rate];
        console.log('WL A: trig');
        // 
        const planName = this.planNamesWLArr[i];
        if (planName === "Guaranteed Acceptance"){
          this.monthlyPlanArr.push({"name":planName,"rate":element});
          // 
        }else{
          console.log('Skip push');
        }
        
      }
      i++;
    }
    i=0;

  }
  // Q:B) Functions ...
  qBQuoteChangeFn(){
    console.log('Question B');
    let apiData = this.apiReturnData;
    const age = this.quoteForm.get('age').value;
    const coverage = this.quoteForm.get('coverage').value;
    // LOGISTICS //
    // Term change...
    if(apiData.term === "WL"){
      // Do nothing yet...
      console.log('*WL change null');
    } else {
      console.log('!WL change to WL');
      this.planInfoForm.get('term').patchValue("WL");
    }
    this.termDropArr = this.termDropWLArr;
    //
    // Coverage // 
    // Age check...
    if(age >= 18 && age <= 60){
      console.log(`Age Check: ${age}`);
      this.minCover = 10000;
      this.maxCover = 75000;
    }else if(age >= 61 && age <= 80){
      console.log(`Age Check: ${age}`);
      this.minCover = 5000;
      this.maxCover = 50000;
    }
    // Coverage Validators
    this.planInfoForm.get('coverage').clearValidators();
    this.planInfoForm.get('coverage').setValidators([
        Validators.min(this.minCover),
        Validators.max(this.maxCover)
      ]);
    this.planInfoForm.get('coverage').updateValueAndValidity(); 
    // 
    // Update coverage
    if (this.planInfoForm.get('coverage').value < this.minCover){
      this.planInfoForm.get('coverage').patchValue(this.minCover);
    }else if (this.planInfoForm.get('coverage').value > this.maxCover){
      this.planInfoForm.get('coverage').patchValue(this.maxCover);
    }
    // 
    // Send to quote System
    this.questionStage = "B";
    this.sendChangeToQuote("B");
    // LOGISTICS ENDS //
    /////////////////
  };
  qBDisplayFn(){
    console.log('Question B display function');
    this.monthlyPlanArr = [];
    const apiData = this.apiReturnData.monthly;
    const apiTerm = this.apiReturnData.term;
    // 
    let i = 0;
    for (const rate in apiData) {
      if (apiData.hasOwnProperty(rate)) {
        const element = apiData[rate];
        console.log('WL B: trig');
        // 
        const planName = this.planNamesWLArr[i];
        if (
          planName === "Deferred"
          ){
          this.monthlyPlanArr.push({"name":planName,"rate":element});
          // 
        }else{
          console.log('Skip push');
        }
        
      }
      i++;
    }
    i=0;

  }
  // Q:C) Functions ...
  qCQuoteChangeFn(){
    console.log('Question C');
    let apiData = this.apiReturnData;
    const age = this.quoteForm.get('age').value;
    const coverage = this.quoteForm.get('coverage').value;
    // LOGISTICS //
    // Term change...
    if(apiData.term === "WL"){
      // Do nothing yet...
      console.log('*WL change null');
      // Age check...
      if(age >= 18 && age <= 60){
        console.log(`Age Check CCCCC: ${age}`);
        this.minCover = 10000;
        this.maxCover = 350000;
      }else if(age >= 61 && age <= 80){
        console.log(`Age Check CCCCCC: ${age}`);
        this.minCover = 5000;
        this.maxCover = 350000;
      }
    } else if(apiData.term === "T10"){
      // Age check...
      if(age >= 18 && age <= 70){
        console.log(`Age Check CCCCC: ${age}`);
        this.minCover = 25000;
        this.maxCover = 350000;
      }
    } else if(apiData.term === "T20"){
      // Age check...
      if(age >= 18 && age <= 60){
        console.log(`Age Check CCCCC: ${age}`);
        this.minCover = 25000;
        this.maxCover = 350000;
      }
    } else if(apiData.term === "T25"){
      // Age check...
      if(age >= 18 && age <= 55){
        console.log(`Age Check CCCCC: ${age}`);
        this.minCover = 25000;
        this.maxCover = 350000;
      }
    }else if(apiData.term === "T100"){
      // Age check...
      if(age >= 18 && age <= 80){
        console.log(`Age Check CCCCC: ${age}`);
        this.minCover = 25000;
        this.maxCover = 350000;
      }
    }
    //////////////
    // 
    this.termDropArr = this.termDropAllArr;
    //
    // Coverage // 
    // Coverage Validators
    this.planInfoForm.get('coverage').clearValidators();
    this.planInfoForm.get('coverage').setValidators([
        Validators.min(this.minCover),
        Validators.max(this.maxCover)
      ]);
    this.planInfoForm.get('coverage').updateValueAndValidity(); 
    // 
    // Update coverage
    if (this.planInfoForm.get('coverage').value < this.minCover){
      this.planInfoForm.get('coverage').patchValue(this.minCover);
    }else if (this.planInfoForm.get('coverage').value > this.maxCover){
      this.planInfoForm.get('coverage').patchValue(this.maxCover);
    }
    // 
    // Send to quote System
    this.questionStage = "C";
    this.sendChangeToQuote("C");
    // LOGISTICS ENDS //
    /////////////////
  };
  qCDisplayFn(){
    console.log('Question C display function');
    this.monthlyPlanArr = [];
    const apiData = this.apiReturnData.monthly;
    const apiTerm = this.apiReturnData.term;
    // 
    if (apiTerm ==="WL") {
      console.log('IN THE WHOLE LIFE');
      let i = 0;
      for (const rate in apiData) {
        if (apiData.hasOwnProperty(rate)) {
          const element = apiData[rate];
          console.log('WL C: trig');
          // 
          const planName = this.planNamesWLArr[i];
          if (
            planName === "Deferred Elite" 
            ){
            this.monthlyPlanArr.push({"name":planName,"rate":element});
            // 
          }else{
            console.log('Skip push');
          }
          
        }
        i++;
      }
      i=0;
      
    }else{
      console.log('IN THE TERMS');
      let i = 0;
      for (const rate in apiData) {
        if (apiData.hasOwnProperty(rate)) {
          const element = apiData[rate];
          console.log('TERM C: trig');
          // 
          const planName = this.planNamesTermArr[i];
          if (
            planName === "Deferred Elite" 
            ){
            this.monthlyPlanArr.push({"name":planName,"rate":element});
            // 
          }else{
            console.log('Skip push');
          }
          
        }
        i++;
      }
      i=0;
    }

  }
  // Q:D) Functions ...
  qDQuoteChangeFn(){
    console.log('Question D');
    let apiData = this.apiReturnData;
    const age = this.quoteForm.get('age').value;
    const coverage = this.quoteForm.get('coverage').value;
    // LOGISTICS //
    // Term change...
    if(apiData.term === "WL"){
      // Do nothing yet...
      console.log('*WL change null');
      // Age check...
      if(age >= 18 && age <= 60){
        console.log(`Age Check CCCCC: ${age}`);
        this.minCover = 10000;
        this.maxCover = 500000;
      }else if(age >= 61 && age <= 80){
        console.log(`Age Check CCCCCC: ${age}`);
        this.minCover = 5000;
        this.maxCover = 350000;
      }
      // 
    } else if(apiData.term === "T10"){
      // Age check...
      if(age >= 18 && age <= 70){
        console.log(`Age Check CCCCC: ${age}`);
        this.minCover = 25000;
        this.maxCover = 350000;
      }else if(age >= 61 && age <= 80){
        console.log(`Age Check CCCCCC: ${age}`);
        this.minCover = 5000;
        this.maxCover = 350000;
      }
      // 
    } else if(apiData.term === "T20"){
      // Age check...
      if(age >= 18 && age <= 60){
        console.log(`Age Check CCCCC: ${age}`);
        this.minCover = 25000;
        this.maxCover = 500000;
      }
    } else if(apiData.term === "T25"){
      // Age check...
      if(age >= 18 && age <= 55){
        console.log(`Age Check CCCCC: ${age}`);
        this.minCover = 25000;
        this.maxCover = 500000;
      }
    }else if(apiData.term === "T100"){
      // Age check...
      if(age >= 18 && age <= 80){
        console.log(`Age Check CCCCC: ${age}`);
        this.minCover = 25000;
        this.maxCover = 350000;
      }
    }
    //////////////
    // 
    this.termDropArr = this.termDropAllArr;
    //
    // Coverage // 
    // Coverage Validators
    this.planInfoForm.get('coverage').clearValidators();
    this.planInfoForm.get('coverage').setValidators([
        Validators.min(this.minCover),
        Validators.max(this.maxCover)
      ]);
    this.planInfoForm.get('coverage').updateValueAndValidity(); 
    // 
    // Update coverage
    if (this.planInfoForm.get('coverage').value < this.minCover){
      this.planInfoForm.get('coverage').patchValue(this.minCover);
    }else if (this.planInfoForm.get('coverage').value > this.maxCover){
      this.planInfoForm.get('coverage').patchValue(this.maxCover);
    }
    // 
    // Send to quote System
    this.questionStage = "D";
    this.sendChangeToQuote("D");
    // LOGISTICS ENDS //
    /////////////////
  };
  qDDisplayFn(){
    console.log('Question C display function');
    this.monthlyPlanArr = [];
    const apiData = this.apiReturnData.monthly;
    const apiTerm = this.apiReturnData.term;
    // 
    if (apiTerm ==="WL") {
      console.log('IN THE WHOLE LIFE');
      let i = 0;
      for (const rate in apiData) {
        if (apiData.hasOwnProperty(rate)) {
          const element = apiData[rate];
          console.log('WL C: trig');
          // 
          const planName = this.planNamesWLArr[i];
          if (
            planName === "Simplified Elite"
            ){
            this.monthlyPlanArr.push({"name":planName,"rate":element});
            // 
          }else{
            console.log('Skip push');
          }
          
        }
        i++;
      }
      i=0;
      
    }else{
      console.log('IN THE TERMS');
      let i = 0;
      for (const rate in apiData) {
        if (apiData.hasOwnProperty(rate)) {
          const element = apiData[rate];
          console.log('TERM C: trig');
          // 
          const planName = this.planNamesTermArr[i];
          if (
            planName === "Simplified Elite"
            ){
            this.monthlyPlanArr.push({"name":planName,"rate":element});
            // 
          }else{
            console.log('Skip push');
          }
          
        }
        i++;
      }
      i=0;
    }

  }
  // Q:E) Functions ...
  qEQuoteChangeFn(){
    console.log('Question E');
    let apiData = this.apiReturnData;
    const age = this.quoteForm.get('age').value;
    const coverage = this.quoteForm.get('coverage').value;
    // LOGISTICS //
    // Term change...
    if(apiData.term === "WL"){
      // Do nothing yet...
      console.log('*WL change null');
      this.planInfoForm.get('term').patchValue("T10");
    } else {
      console.log('!WL change to WL');
    }
    // 
    this.termDropArr = this.termDropTermArr;
    // 
    // LOGISTICS //
    // Term change...
    if(apiData.term === "WL"){
      // Do nothing yet...
      // 
    } else if(apiData.term === "T10"){
      // Age check...
      if(age >= 18 && age <= 70){
        console.log(`Age Check CCCCC: ${age}`);
        this.minCover = 50000;
        this.maxCover = 1000000;
      }
      // 
    } else if(apiData.term === "T20"){
      // Age check...
      if(age >= 18 && age <= 60){
        console.log(`Age Check CCCCC: ${age}`);
        this.minCover = 50000;
        this.maxCover = 1000000;
      }
    } else if(apiData.term === "T25"){
      // Age check...
      if(age >= 18 && age <= 55){
        console.log(`Age Check CCCCC: ${age}`);
        this.minCover = 50000;
        this.maxCover = 1000000;
      }
    }else if(apiData.term === "T100"){
      // Age check...
      if(age >= 18 && age <= 80){
        console.log(`Age Check CCCCC: ${age}`);
        this.minCover = 50000;
        this.maxCover = 1000000;
      }
    }
    //////////////
    //
    // Coverage Validators
    this.planInfoForm.get('coverage').clearValidators();
    this.planInfoForm.get('coverage').setValidators([
        Validators.min(this.minCover),
        Validators.max(this.maxCover)
      ]);
    this.planInfoForm.get('coverage').updateValueAndValidity(); 
    // 
    // Update coverage
    if (this.planInfoForm.get('coverage').value < this.minCover){
      this.planInfoForm.get('coverage').patchValue(this.minCover);
    }else if (this.planInfoForm.get('coverage').value > this.maxCover){
      this.planInfoForm.get('coverage').patchValue(this.maxCover);
    }
    // 
    // Send to quote System
    this.questionStage = "E";
    this.sendChangeToQuote("E");
    // LOGISTICS ENDS //
    /////////////////
  };
  qEDisplayFn(){
    console.log('Question E display function');
    this.monthlyPlanArr = [];
    const apiData = this.apiReturnData.monthly;
    const apiTerm = this.apiReturnData.term;
    // 
    let i = 0;
    for (const rate in apiData) {
      if (apiData.hasOwnProperty(rate)) {
        const element = apiData[rate];
        console.log('TERM E: trig');
        // 
        const planName = this.planNamesTermArr[i];
        if (
          planName === "Preferred"
          ){
          this.monthlyPlanArr.push({"name":planName,"rate":element});
          // 
        }else{
          console.log('Skip push');
        }
        
      }
      i++;
    }
    i=0;

  }
  // Q:F) Functions ...
  qFQuoteChangeFn(){
    console.log('Question E');
    let apiData = this.apiReturnData;
    const age = this.quoteForm.get('age').value;
    const coverage = this.quoteForm.get('coverage').value;
    // LOGISTICS //
    // Term change...
    if(apiData.term === "WL"){
      // Do nothing yet...
      console.log('*WL change null');
      this.planInfoForm.get('term').patchValue("T10");
    } else {
      console.log('!WL change to WL');
    }
    // 
    this.termDropArr = this.termDropTermArr;
    // 
    // LOGISTICS //
    // Term change...
    if(apiData.term === "WL"){
      // Do nothing yet...
      console.log('*WL change null');
      // Age check...
      // 
    } else if(apiData.term === "T10"){
      // Age check...
      if(age >= 18 && age <= 70){
        console.log(`Age Check CCCCC: ${age}`);
        this.minCover = 500000;
        this.maxCover = 1000000;
      }
      // 
    } else if(apiData.term === "T20"){
      // Age check...
      if(age >= 18 && age <= 60){
        console.log(`Age Check CCCCC: ${age}`);
        this.minCover = 500000;
        this.maxCover = 1000000;
      }
    } else if(apiData.term === "T25"){
      // Age check...
      if(age >= 18 && age <= 55){
        console.log(`Age Check CCCCC: ${age}`);
        this.minCover = 500000;
        this.maxCover = 1000000;
      }
    }else if(apiData.term === "T100"){
      // Age check...
      if(age >= 18 && age <= 80){
        console.log(`Age Check CCCCC: ${age}`);
        this.minCover = 500000;
        this.maxCover = 1000000;
      }
    }
    //////////////
    //
    // Coverage Validators
    this.planInfoForm.get('coverage').clearValidators();
    this.planInfoForm.get('coverage').setValidators([
        Validators.min(this.minCover),
        Validators.max(this.maxCover)
      ]);
    this.planInfoForm.get('coverage').updateValueAndValidity(); 
    // 
    // Update coverage
    if (this.planInfoForm.get('coverage').value < this.minCover){
      this.planInfoForm.get('coverage').patchValue(this.minCover);
    }else if (this.planInfoForm.get('coverage').value > this.maxCover){
      this.planInfoForm.get('coverage').patchValue(this.maxCover);
    }
    // 
    // Send to quote System
    this.questionStage = "F";
    this.sendChangeToQuote("F");
    // LOGISTICS ENDS //
    /////////////////
  };
  qFDisplayFn(){
    console.log('Question E display function');
    this.monthlyPlanArr = [];
    const apiData = this.apiReturnData.monthly;
    const apiTerm = this.apiReturnData.term;
    // 
    let i = 0;
    for (const rate in apiData) {
      if (apiData.hasOwnProperty(rate)) {
        const element = apiData[rate];
        console.log('TERM F: trig');
        // 
        const planName = this.planNamesTermArr[i];
        if (
          planName === "Preferred Elite"
          ){
          this.monthlyPlanArr.push({"name":planName,"rate":element});
          // 
        }else{
          console.log('Skip push');
        }
        
      }
      i++;
    }
    i=0;

  };
  // *** PLAN FUNCTIONS ENDS ////////////// 
  //////////////////////////////
  // %%

  // %%
  ////////////////////////////
  // ADD/REMOVE BENEFICIARY ////////////////
  // Bene Group Return...
  addBeneGroup(){
    return new FormGroup({
      "insured": new FormControl(null,Validators.required),
      "percent": new FormControl(null,Validators.required),
      "status": new FormControl(null,Validators.required),
      "firstNameBene": new FormControl(null,Validators.required),
      "lastNameBene": new FormControl(null,Validators.required),
      "birthBene": new FormControl(null,Validators.required)
    })
  };
  // Add Bene Clicked...
  addBeneClick(){
    const beneLength = this.questionSetBene.value.beneInputArr.length;
    // 
    if(beneLength >= 1){
      (<FormArray>this.questionSetBene.get('beneInputArr')).push(this.addBeneGroup());
    }
  }
  // Remove Bene Clicked...
  removeBeneClick(index:number){
    (<FormArray>this.questionSetBene.get('beneInputArr')).removeAt(index);
  }
  // 
  // ADD BENEFICIARY ENDS ////////////////
  /////////////////////////////
  // %%
  
  // %%
  ///////////////////////////////
  // Quote functions //////////  
  // Quick Quote transfer...
  transferQuote(){
    console.log('Transfer Quick Quote');
    // Plan Form
    this.planInfoForm.get('term').patchValue(this.qQuoteForm.get('term').value);
    this.planInfoForm.get('coverage').patchValue(this.qQuoteForm.get('coverage').value);
    // ID Info Form
    this.personalInfoForm.get('idInfoForm').get('smoker').patchValue(this.qQuoteForm.get('smoker').value);
    this.personalInfoForm.get('idInfoForm').get('gender').patchValue(this.qQuoteForm.get('gender').value);
    this.personalInfoForm.get('idInfoForm').get('age').patchValue(this.qQuoteForm.get('age').value);
    this.personalInfoForm.get('idInfoForm').get('dob').patchValue(this.qQuoteForm.get('birthDate').value);
    // Contact form
    this.personalInfoForm.get('contact').get('email').patchValue(this.qQuoteForm.get('email').value);
    this.personalInfoForm.get('contact').get('phone').patchValue(this.qQuoteForm.get('phone').value);
    // 
    this.sendChangeToQuote("");
  }
  sendChangeToQuote(questionLetter){
    this.isMonthly = false;
    const personalInfoForm = this.personalInfoForm.get('idInfoForm');
    const planForm = this.planInfoForm;
    const letter = questionLetter;
    // 
    console.log('PATCH PROBLEM');
    console.log(personalInfoForm.get('dob').value);
    console.log(this.setAgeValue(personalInfoForm.get('dob').value));
    this.quoteForm.get('gender').patchValue(personalInfoForm.get('gender').value);
    this.quoteForm.get('smoker').patchValue(personalInfoForm.get('smoker').value);
    this.quoteForm.get('age').patchValue(this.setAgeValue(personalInfoForm.get('dob').value));
    this.quoteForm.get('coverage').patchValue(planForm.get('coverage').value);
    this.quoteForm.get('term').patchValue(planForm.get('term').value);
    // 
    this.getQuote(letter);
    // 
    this.changeQuote = false;
  }
  // Quote functions ends//////////
  ///////////////////////////////
  // %%
  
  // %%
  ///////////////////////////////////////
  // Date Formatting Functions /////////////////////////////////////////////////
  addDays(date, days){
    const newDate = new Date(date.valueOf());
    newDate.setDate(newDate.getDate() + days);
    return newDate;
  }
  removeYears(date, years){
    const newDate = new Date(date.valueOf());
    newDate.setFullYear(newDate.getFullYear() - years);
    return newDate;
  }
  // Date Formatting Functions Ends //////////////////////////////////////////
  ///////////////////////////////////////
  // %% 
  
  // %%
  //////////////////////////////////////////
  // Set Age From Datepicker ////////////////////////////////////////
  setAgeValue(value){
    console.log(value);
    const daysFromBirth = (this.maxDate.getTime() - value.getTime())/(24*60*60*1000)
    console.log('This should be days totaled ' + daysFromBirth);
    const age = Math.floor(daysFromBirth/365);
    console.log('Your age is ' + age);
    return age;
  }
  //////////////////////////////////////////
  // %%


  // %%
  ////////////////////////////
  // *** API SYSTEM SERVICE //////////////////////////////
  apiLocation = 'https://us-central1-tsquotesapp.cloudfunctions.net/cppQuote';
  getQuote(questionLetter){
    const letter = questionLetter;
    // 
    this.apiService.getQuote(this.quoteForm.value, this.apiLocation).subscribe(resData => {
      console.log('SUCCESS!!!');
      console.log(resData);
      console.log(resData.body);
      this.apiReturnData = resData.body[0];
      // 

      // Question functions
      switch(letter){
        case "A": {
          this.qADisplayFn();
          break;
        }
        case "B": {
          this.qBDisplayFn();
          break;
        }
        case "C": {
          this.qCDisplayFn();
          break;
        }
        case "D": {
          this.qDDisplayFn();
          break;
        }
        case "E": {
          this.qEDisplayFn();
          break;
        }
        case "F": {
          this.qFDisplayFn();
          break;
        }
        default: {
          console.log('qetQuote: no letter chosen');
          this.noLetterFn();
        }
      }

      // 
      this.isMonthly = true;

    }, error =>{
      console.log('ERROR');
      console.log(error);
      // 
      this.isMonthly = false;
    })
  };
  // *** API SYSTEM SERVICE //////////////////////////////
  ////////////////////////////
  // %%
  
  ///////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////
  // Google api handler /////////////////
  /////////////////////////////
  public handleAddressChange(address: any) {
    // Full formatted address 
    // this.visitorsPage2Form.controls.contactInfoGroup.value.contactAddress = address.formatted_address;
    console.log('This is triggered');
    console.log(address.formatted_address);
    // this.personalInfoForm.controls. = address.formatted_address;
    this.personalInfoForm.controls.addressForm.value.address = address.formatted_address;
    //
    // Object check for address components below...
    // console.log(address);
    // console.log(address.address_components[0].types[0]);
    // 
    // Parse through the address component
    // - for each element in the full address
    let city;
    let postal;
    address.address_components.forEach(element => {
      // console.log(element.types[0]);
      // console.log(element.long_name);
      const adType = element.types[0];
      const adName = element.long_name;
      if (adType === 'street_number') {
        // this.addressNum = adName;
      } else 
      if(adType === 'route'){
        // this.addressRoute = adName;
      } else 
      if(adType === 'locality'){
        this.personalInfoForm.get('addressForm').get('city').patchValue(adName);
      } else 
      if(adType === 'administrative_area_level_1'){
        // this.addressProvince = adName;
      } else 
      if(adType === 'postal_code'){
        this.personalInfoForm.get('addressForm').get('postal').patchValue(adName);
      }
    });
    //  
  } 
  // 
  // /////////////////////////////////
  /////////////////////////////////////////////////////////////
}
