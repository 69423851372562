<div class="container cpp-app">
  <div class="row">
    <div class="col-12">
      <!-- Mat-sidenav-container -->
      <mat-sidenav-container class="sidenav-container">
        <!--  -->
        <!-- *** Sidebar Right -->
        <mat-sidenav 
        #drawer
        class="drawer"
        [mode]="(isHandset$ | async) ? 'over' : 'side'"
        [opened]="(isHandset$ | async) ? 'false' : 'true'" 
        position="end">
          <div class="col-12 cpp-sidenav">
            <!-- Close button -->
            <div class="row drawer-close-hld">
              <div class="col-12 drawer-close-icon">
                <button
                  type="button"
                  aria-label="Toggle sidenav"
                  mat-flat-button
                  (click)="drawer.toggle()">                  
                  <mat-icon>close</mat-icon>
                </button>
              </div>
            </div>
            <!-- AGENT IMG -->
            <div class="row">
              <div class="col-12 agent-hld">
                <div class="agent-img">
                  <img src="../../../assets/img/min-Jiang.jpg" class="img-fluid">
                </div>
              </div>
            </div>
            <!-- Drawer Agent Name-->
            <div class="row">
              <div class="col-12 agent-name-hld">
                Min Jiang
              </div>
            </div>
            <!-- Drawer Agent Title -->
            <div class="row">
              <div class="col-12 agent-title-hld">
                Your Insurance Advisor
              </div>
            </div>
            <!-- Agent icon button -->
            <div class="row">
              <div class="col-6 agent-icon-btn-hld">
                 <button
                 mat-icon-button>
                 <mat-icon>phone</mat-icon>
                </button>
              </div>
              <div class="col-6 agent-icon-btn-hld">
                <button
                mat-icon-button>
                <mat-icon>email</mat-icon>
               </button>
              </div>
            </div>
            <!-- Top Title -->
            <div class="row solid-output-hld">
              <div class="col-12 spacer-xs spacer-bottom-border"></div>
              <div class="col-12 solid-output-title-text">
              TOP PLANS
              </div>
            </div>
            <!-- Top Monthly -->
            <div class="loopDivPlan"
              *ngFor="let rate of monthlyPlanArr; let i = index;">
              <div class="ifDivPlan" *ngIf="i === 0">
                <div class="row solid-output-hld">              
                  <div class="col-7 solid-output-text">
                    {{rate.name}}
                  </div>
                  <div class="col-5 solid-output-output" >
                    <div *ngIf="isMonthly">
                      <!-- {{apiReturnData['monthly'][0] | currency}} -->
                      {{rate.rate|currency}}
                    </div>
                    <div *ngIf="!isMonthly">
                      <mat-progress-bar class="output-spinner" mode="indeterminate">
                      </mat-progress-bar>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            
            <!-- Form Output -->
            <!-- Coverage -->
            <div class="row output-hld">
              <div class="col-7 output-text">
              Coverage Amount
              </div>
              <div class="col-5 output-output select-output">
                <form [formGroup]="planInfoForm">
                  <mat-form-field 
                  class="id-input-fld">
                    <input matInput 
                    formControlName="coverage"
                    value="0.00"
                    currencyMask
                    [options]="{prefix:'$', thousands: ',', decimal: '.'}" >
                  </mat-form-field>
                </form>
              </div>
            </div>
            <!-- Smoker -->
            <div class="row output-hld">
              <div class="col-7 output-text">
              Smoking Status
              </div>
              <div class="col-5 output-output select-output">
                <form [formGroup]="personalInfoForm.get('idInfoForm')">
                  <mat-form-field class="id-input-fld">
                    <mat-select
                      formControlName="smoker">
                        <mat-option value="default" disabled>Select...</mat-option>
                        <mat-option [value]="true">Smoker</mat-option>
                        <mat-option [value]="false">Non-Smoker</mat-option>
                    </mat-select>
                  </mat-form-field>
                </form>
              </div>
            </div>
            <!-- Gender -->
            <div class="row output-hld">
              <div class="col-7 output-text">
              Gender
              </div>
              <div class="col-5 output-output select-output">
                <form [formGroup]="personalInfoForm.get('idInfoForm')">
                  <mat-form-field class="id-input-fld">
                    <mat-select
                      formControlName="gender">
                        <mat-option value="default" disabled>Select...</mat-option>
                        <mat-option value="male">Male</mat-option>
                        <mat-option value="female">Female</mat-option>
                    </mat-select>
                  </mat-form-field>
                </form>
              </div>
            </div>
            <!-- Birth Date -->
            <div class="row output-hld">
              <div class="col-7 output-text">
              Birth Date
              </div>
              <div class="col-5 output-output select-output">
                <form [formGroup]="personalInfoForm.get('idInfoForm')">
                  <mat-form-field class="id-input-fld">
                    <input  
                      [matDatepicker]="ageDatepicker"
                      [min]="minDate"
                      [max]="maxDate"
                      startView="multi-years"
                      formControlName="dob"
                      matInput>
                    <mat-datepicker-toggle 
                      [for]="ageDatepicker"
                      matSuffix>
                    </mat-datepicker-toggle>
                    <mat-datepicker 
                      #ageDatepicker 
                      >
                    </mat-datepicker>
                  </mat-form-field>
                </form>
              </div>
            </div>
            <!-- TErm -->
            <div class="row output-hld">
              <div class="col-7 output-text">
              Select Term
              </div>
              <div class="col-5 output-output select-output">
                <form [formGroup]="planInfoForm">
                  <mat-form-field class="id-input-fld">
                    <mat-select
                      formControlName="term">
                        <mat-option 
                          *ngFor="let term of termDropArr"
                          [value]="term.value">
                          {{term.text}}
                        </mat-option>
                    </mat-select>
                  </mat-form-field>
                </form>
              </div>
            </div>
            <!-- Send changes to quote -->
            <div class="row output-hld" style="border-bottom:0px solid transparent;">
              <div class="col-7 ">
              </div>
              <div class="col-5 output-output output-btn">
                <button 
                [ngStyle]="{'opacity':!changeQuote ? '0.2' : '1' }"
                [disabled]="!changeQuote"
                (click)="sendChangeToQuote(this.questionStage)"
                mat-flat-button>
                  Get Quote
                </button>
              </div>
            </div>
            <!--  -->

            <!--  -->
            <!-- ALTERNATE PLANS -->
            <!-- Alt Title -->
            <div class="row solid-output-hld">
              <div class="col-12 spacer-xs"></div>
              <div class="col-12 solid-output-title-text">
              ALTERNATE PLANS
              </div>
            </div>
            <!-- ALT Plan Loop -->
            <div class="loopDivPlan"
              *ngFor="let rate of monthlyPlanArr; let i = index;"> <!-- Loop Plans -->
              <div class="ifDivPlan" *ngIf="i >= 1"> <!-- Loop If -->
                <div class="row solid-output-hld">
                  <div class="col-7 solid-output-alt-text">
                    {{rate.name}}
                  </div>
                  <div class="col-5 solid-output-alt-output">
                    <div *ngIf="isMonthly">
                      <!-- {{apiReturnData['monthly'][1] | currency}} -->
                      {{rate.rate|currency}}
                    </div>
                    <div *ngIf="!isMonthly">
                      <mat-progress-bar class="output-spinner" mode="indeterminate">
                      </mat-progress-bar>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- ALT Plan Loop Ends -->

            <!-- ALTERNATE PLANS ENDS -->
            <div class="row spacer-md"></div>
            <!--  -->

            <!-- SET OUTPUTS -->
            <div class="row" *ngIf="false">
              <div class="col-12">
                <p>cpp-app works!</p>
                <p>
                  <b>FROM QUICK QUOTE...</b> <br>
                  <b>Coverage:</b>{{quoteForm.get('coverage').value}}. <br>
                  <b>Smoker:</b>{{quoteForm.get('smoker').value}}. <br>
                  <b>Gender:</b>{{quoteForm.get('gender').value}}. <br>
                  <b>Age:</b>{{quoteForm.get('age').value}}. <br>
                  <b>Term:</b>{{quoteForm.get('term').value}}. <br>
                </p>
                <div *ngIf="apiReturnData">
                  <b>FROM FIREBASE CLOUD FUNCTION</b> <br>
                  <b>Term:</b>{{apiReturnData['term']}}. <br> <br>
                  <div *ngFor="let rate of apiReturnData['monthly']; let i = index;">
                    <b>Monthly {{i+1}}: </b>{{rate |currency}}. <br>
                  </div>
                  <br>
                  <div *ngFor="let rate of apiReturnData['yearly']; let i = index;">
                    <b>Yearly {{i+1}}: </b>{{rate |currency}}. <br>
                  </div>
                </div>
              </div>
              <div class="col-12 spacer-md"></div>
            </div>
          </div>
        </mat-sidenav>
        <!-- *** Sidebar Right Ends -->
        <!--  -->

        <!--  -->
        <!-- *** CPP APP CONTENT -->
        <mat-sidenav-content>
          <div class="col-12">
            <div class="row">
              <!--  -->

              <!-- %% -->
              <!-- *** LEFT SIDEBAR -->
              <!-- Information section -->
              <div class="col-0 col-sm-3 left-sidebar mobile-hide border-table-right">

                <!-- COMPANY LOGO -->
                <div class="row">
                  <div class="col-12 lg-logo-hld">
                    <img src="../../../assets/img/cpp-logo.jpg"
                      class="img-fluid">
                  </div>
                </div>
                <!-- COMPANY LOGO ENDS -->

                <!-- *** PERSONAL SECTION -->
                <!-- TITLE -->
                <div class="row sidebar-row">
                  <div class="col-12 sidebar-letter">
                    <span>{{sidebarStepInfoArr[step].title}}</span>
                  </div>
                </div>
                <!-- *** -->

                <!-- SINGLE CONTENT PERSONAL | Step: 0 //-->
                <div class="personal-section" *ngIf="step <= 0 ">
                  <div class="row sidebar-row sidebar-bottom">
                    <div class="col-12 sidebar-personal-tex-hld"
                      [innerHtml]="sidebarStepInfoArr[step].singleContent" >
                    </div>
                  </div>
                </div>
                <!-- *** -->

                <!-- LETTERED SECTION | Step: 1 - 6//-->
                <div class="lettered-section" *ngIf="step >= 1 && step <= 6">
                  <!-- letter row-->
                  <!-- <div class="row sidebar-row">
                    <div class="col-12 sidebar-letter">
                      <span>A: Guaranteed Acceptance Life</span>
                    </div>
                  </div> -->
                  <!-- medical/no-medical -->
                  <div class="row sidebar-row" *ngIf="step <= 4">
                    <div class="col-2 sidebar-arrow"></div>
                    <div class="col-3 sidebar-no">
                      <span><strong>NO</strong></span>
                    </div>
                    <div class="col-7 sidebar-med-hld">
                      <div class="row sidebar-med-row">
                        <div class="col-12 med-text-2">MEDICAL</div>
                        <div class="col-12 med-text-2">REQUIRED</div>
                      </div>
                    </div>
                  </div>
                  <!-- Yes Explain -->
                  <div class="row sidebar-row sidebar-bottom">
                    <div class="col-4 explain-icon border-table-right">
                      <div class="circle-hld">
                        <div class="ex-circle-yes">
                          YES
                        </div>
                      </div>
                    </div>
                    <div class="col-8 explain-text">
                      <div class="ex-text-hld">
                        <div class="ex-text" [innerHtml]="sidebarStepInfoArr[step].yes">
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- No Explain -->
                  <div class="row sidebar-row sidebar-bottom">
                    <div class="col-4 explain-icon border-table-right">
                      <div class="circle-hld">
                        <div class="ex-circle-no">
                          NO
                        </div>
                      </div>
                    </div>
                    <div class="col-8 explain-text">
                      <div class="ex-text-hld">
                        <div class="ex-text" [innerHtml]="sidebarStepInfoArr[step].no">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- LETTERED SECTION ENDS-->

                <!-- SINGLE CONTENT TILL LAST -->
                <div class="personal-section" *ngIf="step >= 7 ">
                  <div class="row sidebar-row sidebar-bottom">
                    <div class="col-12 sidebar-personal-tex-hld"
                      [innerHtml]="sidebarStepInfoArr[step].singleContent" >
                   </div>
                  </div>
                </div>
                <!-- *** -->

              </div>
              <!-- Information section ENDS-->

              <!-- * LEFT SIDEBAR ENDS -->
              <!-- %% -->

              <!-- %% -->
              <!-- *** MAIN CONTENT -->
              <div class="col-12 col-sm-9 main">

                <!-- Title -->
                <div class="row title">
                  <div class="col-8">
                    <div class="mat-display-2">
                      {{appTitle}}
                    </div>
                  </div>
                  <div class="col-4 drawer-target-toggle-btn mobile-hide"
                  (click)="drawer.toggle()">
                    <div class="row drawer-target-toggle-hld">
                      <div class="col-10">
                        <div class="drawer-target-toggle-text">Hide Details</div>
                      </div>
                      <div class="col-2 drawer-target-toggle-icon">
                        <mat-icon>double_arrow</mat-icon>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- *** QUESTIONS -->
                <div class="row questions">
                  <div class="col-12 questions-hld">
                    <mat-accordion class="questions-acc">

                      <!-- ??? PERSONAL INFORMATION -->
                      <mat-expansion-panel 
                        [expanded]="step === 0" 
                        (opened)="setStep(0)" 
                        hideToggle>
                        <!-- Header -->
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                            Personal Information
                          </mat-panel-title>
                          <mat-panel-description>
                            <span>&nbsp;</span>
                            <mat-icon *ngIf="!step0">account_circle</mat-icon>
                            <mat-icon *ngIf="step0" class="icon-check">check_circle</mat-icon>
                          </mat-panel-description>
                        </mat-expansion-panel-header>
                        <!--  -->

                        <!-- PERSONAL INFO -->
                        <!-- IDENTIFICATION TITLE -->
                        <div class="col-12 question-sub-title">
                            <div class="col-12 question-title-text">Identification</div>
                        </div>
                        
                        <!-- IDENTIFICATION FORM -->                        
                        <form [formGroup]="personalInfoForm.get('idInfoForm')"
                          style="width: 100%;">
                          <!-- Name -->
                          <div class="col-12 question-sub-forms">
                            <div class="col-12 col-sm-4 question-id-text">
                              <mat-form-field class="id-input-fld">
                                <mat-label>First Name</mat-label>
                                <input matInput 
                                formControlName="firstName">
                              </mat-form-field>
                            </div>
                            <div class="col-12 col-sm-4 question-id-text ">
                              <mat-form-field class="id-input-fld">
                                <mat-label>Middle Name</mat-label>
                                <input matInput 
                                formControlName="middleName">
                              </mat-form-field>
                            </div>
                            <div class="col-12 col-sm-4 question-id-text">
                              <mat-form-field class="id-input-fld">
                                <mat-label>Last Name</mat-label>
                                <input matInput 
                                formControlName="lastName">
                              </mat-form-field>
                            </div>
                          </div>
                          <!-- ** Name ends-->

                          
                          <!-- ID/SIN -->
                          <div class="col-12 question-sub-forms">
                            <!-- citizen -->
                            <div class="col-12 col-sm-4 question-id-text">
                              <mat-form-field class="id-input-fld">
                                <mat-label>Citizenship</mat-label>
                                <mat-select formControlName="citizen">
                                  <mat-option value="default">Choose...</mat-option>
                                  <mat-option value="Citizenship">Citizenship</mat-option>
                                  <mat-option value="Permanent Resident">Permanent Resident</mat-option>
                                  <mat-option value="Work Permit">Work Permit</mat-option>
                                  <mat-option value="International Student">International Student</mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                            <!-- Country of Birth -->
                            <div class="col-12 col-sm-4 question-id-text">
                              <mat-form-field class="id-input-fld">
                                <mat-label>Country of birth</mat-label>
                                <mat-select formControlName="country">
                                    <mat-option value="Afghanistan" >Afghanistan</mat-option>
                                    <mat-option value="Albania" >Albania</mat-option>
                                    <mat-option value="Algeria" >Algeria</mat-option>
                                    <mat-option value="American Samoa" >American Samoa</mat-option>
                                    <mat-option value="Andorra" >Andorra</mat-option>
                                    <mat-option value="Angola" >Angola</mat-option>
                                    <mat-option value="Antigua and Barbuda" >Antigua and Barbuda</mat-option>
                                    <mat-option value="Argentina" >Argentina</mat-option>
                                    <mat-option value="Armenia" >Armenia</mat-option>
                                    <mat-option value="Aruba" >Aruba</mat-option>
                                    <mat-option value="Australia" >Australia</mat-option>
                                    <mat-option value="Austria" >Austria</mat-option>
                                    <mat-option value="Azerbaijan" >Azerbaijan</mat-option>
                                    <mat-option value="Bahamas" >Bahamas</mat-option>
                                    <mat-option value="Bahrain" >Bahrain</mat-option>
                                    <mat-option value="Bangladesh" >Bangladesh</mat-option>
                                    <mat-option value="Barbados" >Barbados</mat-option>
                                    <mat-option value="Belarus" >Belarus</mat-option>
                                    <mat-option value="Belgium" >Belgium</mat-option>
                                    <mat-option value="Belize" >Belize</mat-option>
                                    <mat-option value="Benin" >Benin</mat-option>
                                    <mat-option value="Bermuda" >Bermuda</mat-option>
                                    <mat-option value="Bhutan" >Bhutan</mat-option>
                                    <mat-option value="Bolivia" >Bolivia</mat-option>
                                    <mat-option value="Bonaire" >Bonaire</mat-option>
                                    <mat-option value="Bosnia and Herzegovina" >Bosnia and Herzegovina</mat-option>
                                    <mat-option value="Botswana" >Botswana</mat-option>
                                    <mat-option value="Brazil" >Brazil</mat-option>
                                    <mat-option value="British Virgin Islands" >British Virgin Islands</mat-option>
                                    <mat-option value="Brunei" >Brunei</mat-option>
                                    <mat-option value="Bulgaria" >Bulgaria</mat-option>
                                    <mat-option value="Burkina Faso" >Burkina Faso</mat-option>
                                    <mat-option value="Burundi" >Burundi</mat-option>
                                    <mat-option value="Cambodia" >Cambodia</mat-option>
                                    <mat-option value="Cameroon" >Cameroon</mat-option>
                                    <mat-option value="Canada" >Canada</mat-option>
                                    <mat-option value="Cape Verde" >Cape Verde</mat-option>
                                    <mat-option value="Cayman Islands" >Cayman Islands</mat-option>
                                    <mat-option value="Central African Republic" >Central African Republic</mat-option>
                                    <mat-option value="Chad" >Chad</mat-option>
                                    <mat-option value="Chile" >Chile</mat-option>
                                    <mat-option value="China" >China</mat-option>
                                    <mat-option value="China (Hong Kong)" >China (Hong Kong)</mat-option>
                                    <mat-option value="Colombia" >Colombia</mat-option>
                                    <mat-option value="Comoros" >Comoros</mat-option>
                                    <mat-option value="Costa Rica" >Costa Rica</mat-option>
                                    <mat-option value="Croatia" >Croatia</mat-option>
                                    <mat-option value="Cuba" >Cuba</mat-option>
                                    <mat-option value="Curacao" >Curacao</mat-option>
                                    <mat-option value="Cyprus" >Cyprus</mat-option>
                                    <mat-option value="Czech Republic" >Czech Republic</mat-option>
                                    <mat-option value="Democratic Republic of the Congo" >Democratic Republic of the Congo</mat-option>
                                    <mat-option value="Denmark" >Denmark</mat-option>
                                    <mat-option value="Djibouti" >Djibouti</mat-option>
                                    <mat-option value="Dominica" >Dominica</mat-option>
                                    <mat-option value="Dominican Republic" >Dominican Republic</mat-option>
                                    <mat-option value="East Timor" >East Timor</mat-option>
                                    <mat-option value="Ecuador" >Ecuador</mat-option>
                                    <mat-option value="Egypt" >Egypt</mat-option>
                                    <mat-option value="El Salvador" >El Salvador</mat-option>
                                    <mat-option value="Equatorial Guinea" >Equatorial Guinea</mat-option>
                                    <mat-option value="Eritrea" >Eritrea</mat-option>
                                    <mat-option value="Estonia" >Estonia</mat-option>
                                    <mat-option value="Ethiopia" >Ethiopia</mat-option>
                                    <mat-option value="Falkland Islands" >Falkland Islands</mat-option>
                                    <mat-option value="Fiji" >Fiji</mat-option>
                                    <mat-option value="Finland" >Finland</mat-option>
                                    <mat-option value="France" >France</mat-option>
                                    <mat-option value="Gabon" >Gabon</mat-option>
                                    <mat-option value="Gambia" >Gambia</mat-option>
                                    <mat-option value="Georgia" >Georgia</mat-option>
                                    <mat-option value="Germany" >Germany</mat-option>
                                    <mat-option value="Ghana" >Ghana</mat-option>
                                    <mat-option value="Greece" >Greece</mat-option>
                                    <mat-option value="Greenland" >Greenland</mat-option>
                                    <mat-option value="Grenada" >Grenada</mat-option>
                                    <mat-option value="Guatemala" >Guatemala</mat-option>
                                    <mat-option value="Guernsey" >Guernsey</mat-option>
                                    <mat-option value="Guinea" >Guinea</mat-option>
                                    <mat-option value="Guinea-Bissau" >Guinea-Bissau</mat-option>
                                    <mat-option value="Guyana" >Guyana</mat-option>
                                    <mat-option value="Haiti" >Haiti</mat-option>
                                    <mat-option value="Honduras" >Honduras</mat-option>
                                    <mat-option value="Hungary" >Hungary</mat-option>
                                    <mat-option value="Iceland" >Iceland</mat-option>
                                    <mat-option value="India" >India</mat-option>
                                    <mat-option value="Indonesia" >Indonesia</mat-option>
                                    <mat-option value="Iran" >Iran</mat-option>
                                    <mat-option value="Iraq" >Iraq</mat-option>
                                    <mat-option value="Ireland" >Ireland</mat-option>
                                    <mat-option value="Israel" >Israel</mat-option>
                                    <mat-option value="Italy" >Italy</mat-option>
                                    <mat-option value="Ivory Coast" >Ivory Coast</mat-option>
                                    <mat-option value="Jamaica" >Jamaica</mat-option>
                                    <mat-option value="Japan" >Japan</mat-option>
                                    <mat-option value="Jordan" >Jordan</mat-option>
                                    <mat-option value="Kazakhstan" >Kazakhstan</mat-option>
                                    <mat-option value="Kenya" >Kenya</mat-option>
                                    <mat-option value="Kiribati" >Kiribati</mat-option>
                                    <mat-option value="Kosovo" >Kosovo</mat-option>
                                    <mat-option value="Kuwait" >Kuwait</mat-option>
                                    <mat-option value="Kyrgyzstan" >Kyrgyzstan</mat-option>
                                    <mat-option value="Laos" >Laos</mat-option>
                                    <mat-option value="Latvia" >Latvia</mat-option>
                                    <mat-option value="Lebanon" >Lebanon</mat-option>
                                    <mat-option value="Lesotho" >Lesotho</mat-option>
                                    <mat-option value="Liberia" >Liberia</mat-option>
                                    <mat-option value="Libya" >Libya</mat-option>
                                    <mat-option value="Liechtenstein" >Liechtenstein</mat-option>
                                    <mat-option value="Lithuania" >Lithuania</mat-option>
                                    <mat-option value="Luxembourg" >Luxembourg</mat-option>
                                    <mat-option value="Macao" >Macao</mat-option>
                                    <mat-option value="Macedonia" >Macedonia</mat-option>
                                    <mat-option value="Madagascar" >Madagascar</mat-option>
                                    <mat-option value="Malawi" >Malawi</mat-option>
                                    <mat-option value="Malaysia" >Malaysia</mat-option>
                                    <mat-option value="Maldives" >Maldives</mat-option>
                                    <mat-option value="Mali" >Mali</mat-option>
                                    <mat-option value="Malta" >Malta</mat-option>
                                    <mat-option value="Marshall Islands" >Marshall Islands</mat-option>
                                    <mat-option value="Mauritania" >Mauritania</mat-option>
                                    <mat-option value="Mauritius" >Mauritius</mat-option>
                                    <mat-option value="Mexico" >Mexico</mat-option>
                                    <mat-option value="Micronesia" >Micronesia</mat-option>
                                    <mat-option value="Moldova" >Moldova</mat-option>
                                    <mat-option value="Monaco" >Monaco</mat-option>
                                    <mat-option value="Mongolia" >Mongolia</mat-option>
                                    <mat-option value="Montenegro" >Montenegro</mat-option>
                                    <mat-option value="Montserrat" >Montserrat</mat-option>
                                    <mat-option value="Morocco" >Morocco</mat-option>
                                    <mat-option value="Mozambique" >Mozambique</mat-option>
                                    <mat-option value="Myanmar" >Myanmar</mat-option>
                                    <mat-option value="Namibia" >Namibia</mat-option>
                                    <mat-option value="Nauru" >Nauru</mat-option>
                                    <mat-option value="Nepal" >Nepal</mat-option>
                                    <mat-option value="Netherlands" >Netherlands</mat-option>
                                    <mat-option value="New Caledonia" >New Caledonia</mat-option>
                                    <mat-option value="New Zealand" >New Zealand</mat-option>
                                    <mat-option value="Nicaragua" >Nicaragua</mat-option>
                                    <mat-option value="Niger" >Niger</mat-option>
                                    <mat-option value="Nigeria" >Nigeria</mat-option>
                                    <mat-option value="North Korea" >North Korea</mat-option>
                                    <mat-option value="Northern Mariana Islands" >Northern Mariana Islands</mat-option>
                                    <mat-option value="Norway" >Norway</mat-option>
                                    <mat-option value="Oman" >Oman</mat-option>
                                    <mat-option value="Pakistan" >Pakistan</mat-option>
                                    <mat-option value="Palau" >Palau</mat-option>
                                    <mat-option value="Palestine" >Palestine</mat-option>
                                    <mat-option value="Panama" >Panama</mat-option>
                                    <mat-option value="Papua New Guinea" >Papua New Guinea</mat-option>
                                    <mat-option value="Paraguay" >Paraguay</mat-option>
                                    <mat-option value="Peru" >Peru</mat-option>
                                    <mat-option value="Philippines" >Philippines</mat-option>
                                    <mat-option value="Poland" >Poland</mat-option>
                                    <mat-option value="Portugal" >Portugal</mat-option>
                                    <mat-option value="Puerto Rico" >Puerto Rico</mat-option>
                                    <mat-option value="Qatar" >Qatar</mat-option>
                                    <mat-option value="Republic of the Congo" >Republic of the Congo</mat-option>
                                    <mat-option value="Romania" >Romania</mat-option>
                                    <mat-option value="Russia" >Russia</mat-option>
                                    <mat-option value="Rwanda" >Rwanda</mat-option>
                                    <mat-option value="Saint Kitts and Nevis" >Saint Kitts and Nevis</mat-option>
                                    <mat-option value="Saint Lucia" >Saint Lucia</mat-option>
                                    <mat-option value="Saint Vincent and the Grenadines" >Saint Vincent and the Grenadines</mat-option>
                                    <mat-option value="Samoa" >Samoa</mat-option>
                                    <mat-option value="San Marino" >San Marino</mat-option>
                                    <mat-option value="Sao Tome and Principe" >Sao Tome and Principe</mat-option>
                                    <mat-option value="Saudi Arabia" >Saudi Arabia</mat-option>
                                    <mat-option value="Senegal" >Senegal</mat-option>
                                    <mat-option value="Serbia" >Serbia</mat-option>
                                    <mat-option value="Seychelles" >Seychelles</mat-option>
                                    <mat-option value="Sierra Leone" >Sierra Leone</mat-option>
                                    <mat-option value="Singapore" >Singapore</mat-option>
                                    <mat-option value="Slovakia" >Slovakia</mat-option>
                                    <mat-option value="Slovenia" >Slovenia</mat-option>
                                    <mat-option value="Solomon Islands" >Solomon Islands</mat-option>
                                    <mat-option value="Somalia" >Somalia</mat-option>
                                    <mat-option value="South Africa" >South Africa</mat-option>
                                    <mat-option value="South Korea" >South Korea</mat-option>
                                    <mat-option value="South Sudan" >South Sudan</mat-option>
                                    <mat-option value="Spain" >Spain</mat-option>
                                    <mat-option value="Sri Lanka" >Sri Lanka</mat-option>
                                    <mat-option value="Sudan" >Sudan</mat-option>
                                    <mat-option value="Suriname" >Suriname</mat-option>
                                    <mat-option value="Swaziland" >Swaziland</mat-option>
                                    <mat-option value="Sweden" >Sweden</mat-option>
                                    <mat-option value="Switzerland" >Switzerland</mat-option>
                                    <mat-option value="Syria" >Syria</mat-option>
                                    <mat-option value="Taiwan" >Taiwan</mat-option>
                                    <mat-option value="Tajikistan" >Tajikistan</mat-option>
                                    <mat-option value="Tanzania" >Tanzania</mat-option>
                                    <mat-option value="Thailand" >Thailand</mat-option>
                                    <mat-option value="Togo" >Togo</mat-option>
                                    <mat-option value="Tonga" >Tonga</mat-option>
                                    <mat-option value="Trinidad and Tobago" >Trinidad and Tobago</mat-option>
                                    <mat-option value="Tunisia" >Tunisia</mat-option>
                                    <mat-option value="Turkey" >Turkey</mat-option>
                                    <mat-option value="Turkmenistan" >Turkmenistan</mat-option>
                                    <mat-option value="Turks and Caicos Islands" >Turks and Caicos Islands</mat-option>
                                    <mat-option value="Tuvalu" >Tuvalu</mat-option>
                                    <mat-option value="Uganda" >Uganda</mat-option>
                                    <mat-option value="Ukraine" >Ukraine</mat-option>
                                    <mat-option value="United Arab Emirates" >United Arab Emirates</mat-option>
                                    <mat-option value="United Kingdom" >United Kingdom</mat-option>
                                    <mat-option value="United States" >United States</mat-option>
                                    <mat-option value="Uruguay" >Uruguay</mat-option>
                                    <mat-option value="Uzbekistan" >Uzbekistan</mat-option>
                                    <mat-option value="Vanuatu" >Vanuatu</mat-option>
                                    <mat-option value="Vatican City" >Vatican City</mat-option>
                                    <mat-option value="Venezuela" >Venezuela</mat-option>
                                    <mat-option value="Vietnam" >Vietnam</mat-option>
                                    <mat-option value="Yemen" >Yemen</mat-option>
                                    <mat-option value="Zambia" >Zambia</mat-option>
                                    <mat-option value="Zimbabwe" >Zimbabwe</mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                            <!-- SIN# -->
                            <div class="col-12 col-sm-4 question-id-text">
                              <mat-form-field class="id-input-fld">
                                <mat-label>SIN Number</mat-label>
                                <input matInput formControlName="sin">
                              </mat-form-field>
                            </div>

                          </div>
                          <!-- ** ID/SIN  ends -->
                          <!-- ID Info -->
                          <div class="col-12 question-sub-forms">
                            <!-- ID type -->
                            <div class="col-12 col-sm-4 question-id-text">
                              <mat-form-field class="id-input-fld">
                                <mat-label>Insured's ID type</mat-label>
                                <mat-select formControlName="idType">
                                  <mat-option value="Driver’s License">Driver’s License</mat-option>
                                  <mat-option value="Passport">Passport</mat-option>
                                  <mat-option value="Citizenship">Citizenship</mat-option>
                                  <mat-option value="Health Card (w/Photo)">Health Card (w/Photo)</mat-option>
                                  <mat-option value="Canadian Armed Forces Id Card">Canadian Armed Forces Id Card</mat-option>
                                  <mat-option value="Police Force Id">Police Force Id</mat-option>
                                  <mat-option value="Indian Status Card (w/Photo)">Indian Status Card (w/Photo)</mat-option>
                                  <mat-option value="Permanent Resident Card">Permanent Resident Card</mat-option>
                                  <mat-option value="Province Identification Card">Province Identification Card</mat-option>
                                  <mat-option value="Birth Certificate">Birth Certificate</mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                            <!-- ID Number -->
                            <div class="col-12 col-sm-4 question-id-text">
                              <mat-form-field class="id-input-fld">
                                <mat-label>ID Number</mat-label>
                                <input matInput 
                                formControlName="idNum">
                              </mat-form-field>
                            </div>
                            <!-- Province -->
                            <div class="col-12 col-sm-4 question-id-text">
                              <mat-form-field class="id-input-fld">
                                <mat-label>Province/Territory of issue</mat-label>
                                <mat-select formControlName="idProvince">
                                  <mat-option value="Alberta">Alberta</mat-option>
                                  <mat-option value="British Columbia">British Columbia</mat-option>
                                  <mat-option value="Manitoba">Manitoba</mat-option>
                                  <mat-option value="New Brunswick">New Brunswick</mat-option>
                                  <mat-option value="Newfoundland and Labrador">Newfoundland and Labrador</mat-option>
                                  <mat-option value="Northwest Territories">Northwest Territories</mat-option>
                                  <mat-option value="Nova Scotia">Nova Scotia</mat-option>
                                  <mat-option value="Nunavut">Nunavut</mat-option>
                                  <mat-option value="Ontario">Ontario</mat-option>
                                  <mat-option value="Prince Edward Island">Prince Edward Island</mat-option>
                                  <mat-option value="Quebec">Quebec</mat-option>
                                  <mat-option value="Saskatchewan">Saskatchewan</mat-option>
                                  <mat-option value="Yukon">Yukon</mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>

                          </div>
                          <!-- ** ID Info ends -->
                          <!-- Expiry -->
                          <div class="col-12 question-sub-forms">
                            <!-- Expiry -->
                            <div class="col-12 col-sm-4 question-id-text ">
                              <mat-form-field class="id-input-fld">
                                <mat-label>Expiry date</mat-label>
                                <input matInput 
                                formControlName="idExpiry">
                              </mat-form-field>
                            </div>
                          </div>
                          <!-- Expiry ends-->

                        </form>
                        <!-- IDENTIFICATION FORM ENDS --> 

                        <!-- ADDRESS TITLE -->
                        <div class="col-12 question-sub-title">
                            <div class="col-12 question-title-text" *ngIf="true">Address</div>
                        </div>
                        <!-- ADDRESS FORM -->
                        <form [formGroup]="personalInfoForm.get('addressForm')"
                        *ngIf="true">
                          <!-- Address type -->
                          <div class="col-12 question-sub-forms">
                            <div class="col-12 col-sm-4 question-id-text">
                              <mat-form-field class="id-input-fld">
                                <mat-label>Address Type</mat-label>
                                <mat-select
                                  formControlName="addressType">
                                    <mat-option value="default" disabled>Select...</mat-option>
                                    <mat-option value="house">House</mat-option>
                                    <mat-option value="town house">Town House</mat-option>
                                    <mat-option value="condo">Condo</mat-option>
                                    <mat-option value="apt">Apartment</mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                          </div>
                          <!-- ** Address type ends -->
                          <!-- Address  -->
                          <div class="col-12 question-sub-forms">
                            <div class="col-12 question-id-text">
                              <mat-form-field class="id-input-fld">
                                <mat-label>Address</mat-label>
                                <input matInput 
                                formControlName="address"
                                ngx-google-places-autocomplete
                                (onAddressChange)="handleAddressChange($event)"
                                autocomplete="new-password">
                              </mat-form-field>
                            </div>
                          </div>
                          <!-- ** Address ends -->
                          <!-- Postals  -->
                          <div class="col-12 question-sub-forms">
                            <div class="col-12 col-sm-4 question-id-text">
                              <mat-form-field class="id-input-fld">
                                <mat-label>Apartment/Unit</mat-label>
                                <input matInput 
                                formControlName="unit">
                              </mat-form-field>
                            </div>
                            <div class="col-12 col-sm-4 question-id-text">
                              <mat-form-field class="id-input-fld">
                                <mat-label>City</mat-label>
                                <input matInput 
                                formControlName="city">
                              </mat-form-field>
                            </div>
                            <div class="col-12 col-sm-4 question-id-text">
                              <mat-form-field class="id-input-fld">
                                <mat-label>Postal</mat-label>
                                <input matInput 
                                formControlName="postal">
                              </mat-form-field>
                            </div>
                          </div>
                          <!-- ** Address ends -->
                        </form>
                        <!-- ADDRESS FORM ENDS-->

                        <!-- CONTACT TITLE -->
                        <div class="col-12 question-sub-title">
                          <div class="col-12 question-title-text" *ngIf="true">Contact</div>
                        </div>
                        <!-- CONTACT FORM -->
                        <form [formGroup]="personalInfoForm.get('contact')"
                        *ngIf="true">
                          <!-- phone  -->
                          <div class="col-12 question-sub-forms">
                            <div class="col-12 col-sm-6 question-id-text">
                              <mat-form-field class="id-input-fld">
                                <mat-label>Phone</mat-label>
                                <input matInput 
                                formControlName="phone">
                              </mat-form-field>
                            </div>
                            <div class="col-12 col-sm-6 question-id-text">
                              <mat-form-field class="id-input-fld">
                                <mat-label>Alt. phone</mat-label>
                                <input matInput 
                                formControlName="altNum">
                              </mat-form-field>
                            </div>
                          </div>
                          <!-- ** phone ends -->
                          <!-- phone  -->
                          <div class="col-12 question-sub-forms">
                            <div class="col-12 question-id-text">
                              <mat-form-field class="id-input-fld">
                                <mat-label>Email</mat-label>
                                <input matInput 
                                formControlName="email">
                              </mat-form-field>
                            </div>
                          </div>
                          <!-- ** phone ends -->
                        </form>
                        <!-- CONTACT FORM ENDS-->
                        
                        <!-- PERSONAL Q1 -->
                        <div class="col-12 question border-table-top">
                          <form [formGroup]="personalInfoForm"  style="width: 100%;">
                            <div class="row">
                              <!-- Title -->
                              <div class="col-12 col-sm-9 question-text">
                                <p>
                                  Within the past 12 months, have you used by any means, a substance or product containing tobacco or nicotine (excluding cigars), or have you smoked (including electronic vaporizer or “vaping”) marijuana more than six times per week?
                                  <br><small><i>If YES, smoker rates applicable. </i></small> 
                                </p>
                                                            
                              </div>
                              <!--  -->

                              <!-- Buttons -->
                              <div class="col-12 col-sm-3 question-btn-hld">
                                <!-- <mat-radio-group> -->
                                  <div class="col-6 question-btn qYes">
                                    <mat-radio-group formControlName="weed" name="weed">
                                      <mat-radio-button  [value]="true" labelPosition="before">
                                        Yes
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                  <div class="col-6 question-btn qNo">
                                    <mat-radio-group formControlName="weed" name="weed">
                                      <mat-radio-button  [value]="false" labelPosition="before">
                                        No
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                <!-- </mat-radio-group> -->
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <!-- PERSONAL Q1 ENDS -->

                        <!-- End spacer -->
                        <div class="col-12 spacer-md"></div>


                        <!--  -->
                      </mat-expansion-panel>
                      <!-- ? PERSONAL INFORMATION ENDS -->

                      <!-- ??? A) QUESTION SECTION -->
                      <mat-expansion-panel 
                      [expanded]="step === 1" 
                      (opened)="setStep(1)" 
                      hideToggle>
                        <mat-expansion-panel-header style="height: 100%;">
                          <mat-panel-title>
                            A: Guaranteed Acceptance Life
                          </mat-panel-title>
                          <mat-panel-description>
                            &nbsp;
                            <mat-icon *ngIf="!step1" >help</mat-icon>
                            <mat-icon *ngIf="step1" class="icon-check">check_circle</mat-icon>
                          </mat-panel-description>
                        </mat-expansion-panel-header>
                        
                        <!-- A Q1 -->
                        <div class="col-12 question">
                          <form [formGroup]="questionSetA"  style="width: 100%;">
                            <div class="row">
                              <!-- Title -->
                              <div class="col-12 col-sm-9 question-text">
                                <p>
                                  <b>1:</b> Are you currently incapable of independently carrying out two or more of the basic activities of daily living such as getting up, walking, washing, toileting, dressing or feeding?
                                </p>
                              </div>
                              <!--  -->

                              <!-- Buttons -->
                              <div class="col-12 col-sm-3 question-btn-hld">
                                <!-- <mat-radio-group> -->
                                  <div class="col-6 question-btn qYes">
                                    <mat-radio-group formControlName="aq1" name="aq1">
                                      <mat-radio-button  value="yes" labelPosition="before">
                                        Yes
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                  <div class="col-6 question-btn qNo">
                                    <mat-radio-group formControlName="aq1" name="aq1">
                                      <mat-radio-button  value="no" labelPosition="before">
                                        No
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                <!-- </mat-radio-group> -->
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <!-- A Q1 ENDS -->
                        
                        <!-- A Q2 -->
                        <div class="col-12 question">
                          <form [formGroup]="questionSetA"  style="width: 100%;">
                            <div class="row">
                              <!-- Title -->
                              <div class="col-12 col-sm-9 question-text">
                                <p><b>2:</b> Are you currently a resident of a nursing home or nursing facility (excluding assisted living residences, retirement homes or senior living facilities), and are you currently bedridden or wheelchair bound?</p>
                                                              
                              </div>
                              <!--  -->

                              <!-- Buttons -->
                              <div class="col-12 col-sm-3 question-btn-hld">
                                <!-- <mat-radio-group> -->
                                  <div class="col-6 question-btn qYes">
                                    <mat-radio-group formControlName="aq2" name="aq2">
                                      <mat-radio-button  value="yes" labelPosition="before">
                                        Yes
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                  <div class="col-6 question-btn qNo">
                                    <mat-radio-group formControlName="aq2" name="aq2">
                                      <mat-radio-button  value="no" labelPosition="before">
                                        No
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                <!-- </mat-radio-group> -->
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <!-- A Q2 ENDS -->

                        <!-- A Q3 -->
                        <div class="col-12 question">
                          <form [formGroup]="questionSetA"  style="width: 100%;">
                            <div class="row">
                              <!-- Title -->
                              <div class="col-12 col-sm-9 question-text">
                                <p>                                <b>3:</b> Are you in need of an organ transplant, on a waiting list for an organ transplant or the recipient of an organ transplant (excluding corneal transplants)?
</p>
                              
                              </div>
                              <!--  -->

                              <!-- Buttons -->
                              <div class="col-12 col-sm-3 question-btn-hld">
                                <!-- <mat-radio-group> -->
                                  <div class="col-6 question-btn qYes">
                                    <mat-radio-group formControlName="aq3" name="aq3">
                                      <mat-radio-button  value="yes" labelPosition="before">
                                        Yes
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                  <div class="col-6 question-btn qNo">
                                    <mat-radio-group formControlName="aq3" name="aq3">
                                      <mat-radio-button  value="no" labelPosition="before">
                                        No
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                <!-- </mat-radio-group> -->
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <!-- A Q3 ENDS -->
                        
                        <!-- A Q4 -->
                        <div class="col-12 question">
                          <form [formGroup]="questionSetA"  style="width: 100%;">
                            <div class="row">
                              <!-- Title -->
                              <div class="col-12 col-sm-9 question-text">
                                <p>                                <b>4:</b> Within the past 30 days, have you been admitted to a hospital for more than 48 hours (excluding pregnancy)?                              
</p>
                              
                              </div>
                              <!--  -->

                              <!-- Buttons -->
                              <div class="col-12 col-sm-3 question-btn-hld">
                                <!-- <mat-radio-group> -->
                                  <div class="col-6 question-btn qYes">
                                    <mat-radio-group formControlName="aq4" name="aq4">
                                      <mat-radio-button  value="yes" labelPosition="before">
                                        Yes
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                  <div class="col-6 question-btn qNo">
                                    <mat-radio-group formControlName="aq4" name="aq4">
                                      <mat-radio-button  value="no" labelPosition="before">
                                        No
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                <!-- </mat-radio-group> -->
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <!-- A Q4 ENDS -->
                        
                        <!-- A Q5 SET!!! -->
                        <!-- Question Title -->
                        <div class="col-12 question">
                          <form [formGroup]="questionSetA"  style="width: 100%;">
                            <div class="row question-title">
                              <!-- Title -->
                              <div class="col-12 col-sm-9 question-text">
                                <p>                                <b>5:</b> Within the past 60 days, have you been advised by a physician:                              
</p>
                              
                              </div>
                              <!--  -->

                              <!-- Buttons -->
                              <div class="col-12 col-sm-3 question-btn-hld">
                                <!-- <mat-radio-group> -->
                                  <div class="col-6 question-btn qYes">
                                  </div>
                                  <div class="col-6 question-btn qNo">
                                  </div>
                                <!-- </mat-radio-group> -->
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <!-- Question (a) -->
                        <div class="col-12 question">
                          <form [formGroup]="questionSetA"  style="width: 100%;">
                            <div class="row">
                              <!-- Title -->
                              <div class="col-12 col-sm-9 question-text">
                                a) Of any abnormal diagnostic tests?                              
                              </div>
                              <!--  -->

                              <!-- Buttons -->
                              <div class="col-12 col-sm-3 question-btn-hld">
                                <!-- <mat-radio-group> -->
                                  <div class="col-6 question-btn qYes">
                                    <mat-radio-group formControlName="aq5a" name="aq5a">
                                      <mat-radio-button  value="yes" labelPosition="before">
                                        Yes
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                  <div class="col-6 question-btn qNo">
                                    <mat-radio-group formControlName="aq5a" name="aq5a">
                                      <mat-radio-button  value="no" labelPosition="before">
                                        No
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                <!-- </mat-radio-group> -->
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <!-- Question (b) -->
                        <div class="col-12 question">
                          <form [formGroup]="questionSetA"  style="width: 100%;">
                            <div class="row">
                              <!-- Title -->
                              <div class="col-12 col-sm-9 question-text">
                                b) To have surgery or a diagnostic test or special test of any type?                             
                              </div>
                              <!--  -->

                              <!-- Buttons -->
                              <div class="col-12 col-sm-3 question-btn-hld">
                                <!-- <mat-radio-group> -->
                                  <div class="col-6 question-btn qYes">
                                    <mat-radio-group formControlName="aq5b" name="aq5b">
                                      <mat-radio-button  value="yes" labelPosition="before">
                                        Yes
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                  <div class="col-6 question-btn qNo">
                                    <mat-radio-group formControlName="aq5b" name="aq5b">
                                      <mat-radio-button  value="no" labelPosition="before">
                                        No
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                <!-- </mat-radio-group> -->
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <!-- Question (b) -->
                        <div class="col-12 question">
                          <form [formGroup]="questionSetA"  style="width: 100%;">
                            <div class="row">
                              <!-- Title -->
                              <div class="col-12 col-sm-9 question-text">
                                c) To consult with a physician, medical institution or specialist that has not yet been completed?                          
                              
                              </div>
                              <!--  -->

                              <!-- Buttons -->
                              <div class="col-12 col-sm-3 question-btn-hld">
                                <!-- <mat-radio-group> -->
                                  <div class="col-6 question-btn qYes">
                                    <mat-radio-group formControlName="aq5c" name="aq5c">
                                      <mat-radio-button  value="yes" labelPosition="before">
                                        Yes
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                  <div class="col-6 question-btn qNo">
                                    <mat-radio-group formControlName="aq5c" name="aq5c">
                                      <mat-radio-button  value="no" labelPosition="before">
                                        No
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                <!-- </mat-radio-group> -->
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <!-- A Q5 SET!!! ENDS -->

                        <!-- A Q6 -->
                        <div class="col-12 question">
                          <form [formGroup]="questionSetA"  style="width: 100%;">
                            <div class="row">
                              <!-- Title -->
                              <div class="col-12 col-sm-9 question-text">
                                <p>                                <b>6:</b> Have you ever been diagnosed with a life threatening, critical or terminal condition for which a physician has estimated that you have 24 months or less to live?                              
</p>
                              
                              </div>
                              <!--  -->

                              <!-- Buttons -->
                              <div class="col-12 col-sm-3 question-btn-hld">
                                <!-- <mat-radio-group> -->
                                  <div class="col-6 question-btn qYes">
                                    <mat-radio-group formControlName="aq6" name="aq6">
                                      <mat-radio-button  value="yes" labelPosition="before">
                                        Yes
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                  <div class="col-6 question-btn qNo">
                                    <mat-radio-group formControlName="aq6" name="aq6">
                                      <mat-radio-button  value="no" labelPosition="before">
                                        No
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                <!-- </mat-radio-group> -->
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <!-- A Q6 ENDS -->

                        <!-- A Q7 -->
                        <div class="col-12 question">
                          <form [formGroup]="questionSetA"  style="width: 100%;">
                            <div class="row">
                              <!-- Title -->
                              <div class="col-12 col-sm-9 question-text">
                                <p>                                <b>7:</b> Have you ever had, been told you have, or been treated for Acquired Immunodeficiency Syndrome (AIDS) or have you ever tested positive for Immunodeficiency virus (HIV)?                              
</p>
                              
                              </div>
                              <!--  -->

                              <!-- Buttons -->
                              <div class="col-12 col-sm-3 question-btn-hld">
                                <!-- <mat-radio-group> -->
                                  <div class="col-6 question-btn qYes">
                                    <mat-radio-group formControlName="aq7" name="aq7">
                                      <mat-radio-button  value="yes" labelPosition="before">
                                        Yes
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                  <div class="col-6 question-btn qNo">
                                    <mat-radio-group formControlName="aq7" name="aq7">
                                      <mat-radio-button  value="no" labelPosition="before">
                                        No
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                <!-- </mat-radio-group> -->
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <!-- A Q7 ENDS -->
                        
                        <!-- A Q8 SET!!! -->
                        <!-- Question Title -->
                        <div class="col-12 question">
                          <form [formGroup]="questionSetA"  style="width: 100%;">
                            <div class="row question-title">
                              <!-- Title -->
                              <div class="col-12 col-sm-9 question-text">
                                <p>                                <b>8:</b> Within the past ten years, have you had, been told you have, been treated for, or been advised to have an investigation, that has not yet been completed, for:                              
</p>
                              
                              </div>
                              <!--  -->

                              <!-- Buttons -->
                              <div class="col-12 col-sm-3 question-btn-hld">
                                <!-- <mat-radio-group> -->
                                  <div class="col-6 question-btn qYes">
                                  </div>
                                  <div class="col-6 question-btn qNo">
                                  </div>
                                <!-- </mat-radio-group> -->
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <!-- Question (a) -->
                        <div class="col-12 question">
                          <form [formGroup]="questionSetA"  style="width: 100%;">
                            <div class="row">
                              <!-- Title -->
                              <div class="col-12 col-sm-9 question-text">
                                a) Metastatic cancer or more than one occurrence of cancer (excluding basal cell carcinoma)?                              
                              </div>
                              <!--  -->

                              <!-- Buttons -->
                              <div class="col-12 col-sm-3 question-btn-hld">
                                <!-- <mat-radio-group> -->
                                  <div class="col-6 question-btn qYes">
                                    <mat-radio-group formControlName="aq8a" name="aq8a">
                                      <mat-radio-button  value="yes" labelPosition="before">
                                        Yes
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                  <div class="col-6 question-btn qNo">
                                    <mat-radio-group formControlName="aq8a" name="aq8a">
                                      <mat-radio-button  value="no" labelPosition="before">
                                        No
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                <!-- </mat-radio-group> -->
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <!-- Question (b) -->
                        <div class="col-12 question">
                          <form [formGroup]="questionSetA"  style="width: 100%;">
                            <div class="row">
                              <!-- Title -->
                              <div class="col-12 col-sm-9 question-text">
                                b) Cystic Fibrosis or a chronic respiratory condition (excluding sleep apnea) which required the continuing administration of oxygen?                              
                              
                              </div>
                              <!--  -->

                              <!-- Buttons -->
                              <div class="col-12 col-sm-3 question-btn-hld">
                                <!-- <mat-radio-group> -->
                                  <div class="col-6 question-btn qYes">
                                    <mat-radio-group formControlName="aq8b" name="aq8b">
                                      <mat-radio-button  value="yes" labelPosition="before">
                                        Yes
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                  <div class="col-6 question-btn qNo">
                                    <mat-radio-group formControlName="aq8b" name="aq8b">
                                      <mat-radio-button  value="no" labelPosition="before">
                                        No
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                <!-- </mat-radio-group> -->
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <!-- Question (c) -->
                        <div class="col-12 question">
                          <form [formGroup]="questionSetA"  style="width: 100%;">
                            <div class="row">
                              <!-- Title -->
                              <div class="col-12 col-sm-9 question-text">
                                c) Dementia, Alzheimer's, Muscular Dystrophy, Huntington's Chorea or Amyotrophic Lateral Sclerosis (ALS)?                              
                              
                              </div>
                              <!--  -->

                              <!-- Buttons -->
                              <div class="col-12 col-sm-3 question-btn-hld">
                                <!-- <mat-radio-group> -->
                                  <div class="col-6 question-btn qYes">
                                    <mat-radio-group formControlName="aq8c" name="aq8c">
                                      <mat-radio-button  value="yes" labelPosition="before">
                                        Yes
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                  <div class="col-6 question-btn qNo">
                                    <mat-radio-group formControlName="aq8c" name="aq8c">
                                      <mat-radio-button  value="no" labelPosition="before">
                                        No
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                <!-- </mat-radio-group> -->
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <!-- Question (d) -->
                        <div class="col-12 question">
                          <form [formGroup]="questionSetA"  style="width: 100%;">
                            <div class="row">
                              <!-- Title -->
                              <div class="col-12 col-sm-9 question-text">
                                d) Congestive heart failure or cardiomyopathy?                              
                              
                              </div>
                              <!--  -->

                              <!-- Buttons -->
                              <div class="col-12 col-sm-3 question-btn-hld">
                                <!-- <mat-radio-group> -->
                                  <div class="col-6 question-btn qYes">
                                    <mat-radio-group formControlName="aq8d" name="aq8d">
                                      <mat-radio-button  value="yes" labelPosition="before">
                                        Yes
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                  <div class="col-6 question-btn qNo">
                                    <mat-radio-group formControlName="aq8d" name="aq8d">
                                      <mat-radio-button  value="no" labelPosition="before">
                                        No
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                <!-- </mat-radio-group> -->
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <!-- A Q8 SET ENDS!!! -->

                        <!-- A Q9 -->
                        <div class="col-12 question">
                          <form [formGroup]="questionSetA"  style="width: 100%;">
                            <div class="row">
                              <!-- Title -->
                              <div class="col-12 col-sm-9 question-text">
                                <p>                                <b>9:</b> Have you ever had, been treated for, or been diagnosed prior to age 40, with: chronic kidney disease, stroke (CVA), transient ischemic attack (TIA), aneurysm, coronary artery disease, heart bypass surgery, angioplasty, stent insertion, angina or heart attack?                              
</p>
                              
                              </div>
                              <!--  -->

                              <!-- Buttons -->
                              <div class="col-12 col-sm-3 question-btn-hld">
                                <!-- <mat-radio-group> -->
                                  <div class="col-6 question-btn qYes">
                                    <mat-radio-group formControlName="aq9" name="aq9">
                                      <mat-radio-button  value="yes" labelPosition="before">
                                        Yes
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                  <div class="col-6 question-btn qNo">
                                    <mat-radio-group formControlName="aq9" name="aq9">
                                      <mat-radio-button  value="no" labelPosition="before">
                                        No
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                <!-- </mat-radio-group> -->
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <!-- A Q9 ENDS -->

                        <!-- A Q10 -->
                        <div class="col-12 question">
                          <form [formGroup]="questionSetA"  style="width: 100%;">
                            <div class="row">
                              <!-- Title -->
                              <div class="col-12 col-sm-9 question-text">
                                <p>                                <b>10:</b> Within the past 12 months, have you used narcotics or barbiturates (except as prescribed by a physician), heroin, psychoactive drugs, cocaine, crack or other similar agents, or been a resident of a drug or alcohol treatment facility?                              
</p>
                              
                              </div>
                              <!--  -->

                              <!-- Buttons -->
                              <div class="col-12 col-sm-3 question-btn-hld">
                                <!-- <mat-radio-group> -->
                                  <div class="col-6 question-btn qYes">
                                    <mat-radio-group formControlName="aq10" name="aq10">
                                      <mat-radio-button  value="yes" labelPosition="before">
                                        Yes
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                  <div class="col-6 question-btn qNo">
                                    <mat-radio-group formControlName="aq10" name="aq10">
                                      <mat-radio-button  value="no" labelPosition="before">
                                        No
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                <!-- </mat-radio-group> -->
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <!-- A Q10 ENDS -->

                        <!-- A Q11 -->
                        <div class="col-12 question">
                          <form [formGroup]="questionSetA"  style="width: 100%;">
                            <div class="row">
                              <!-- Title -->
                              <div class="col-12 col-sm-9 question-text">
                                <p>                                <b>11:</b> Within the past 12 months, have you been convicted of, awaiting sentencing for, incarcerated for, or on probation, for a criminal offence; or do you currently have any criminal charges pending?                              
</p>
                              
                              </div>
                              <!--  -->

                              <!-- Buttons -->
                              <div class="col-12 col-sm-3 question-btn-hld">
                                <!-- <mat-radio-group> -->
                                  <div class="col-6 question-btn qYes">
                                    <mat-radio-group formControlName="aq11" name="aq11">
                                      <mat-radio-button  value="yes" labelPosition="before">
                                        Yes
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                  <div class="col-6 question-btn qNo">
                                    <mat-radio-group formControlName="aq11" name="aq11">
                                      <mat-radio-button  value="no" labelPosition="before">
                                        No
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                <!-- </mat-radio-group> -->
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <!-- A Q11 ENDS -->
                        <!-- Question Title -->
                        <div class="col-12 question">
                          <form [formGroup]="questionSetA"  style="width: 100%;">
                            <div class="row question-title">
                              <!-- Title -->
                              <div class="col-12 col-sm-9 question-text">
                                <p><b>12:</b> Please enter your height and weight.</p>
                              
                              </div>
                              <!--  -->

                              <!-- Buttons -->
                              <div class="col-12 col-sm-3 question-btn-hld">
                                <!-- <mat-radio-group> -->
                                  <div class="col-6 question-btn qYes">
                                  </div>
                                  <div class="col-6 question-btn qNo">
                                  </div>
                                <!-- </mat-radio-group> -->
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>

                        <!-- A Q12 height -->
                        <div class="col-12 question">
                          <form [formGroup]="questionSetA"  style="width: 100%;">
                            <div class="row">
                              <!-- height -->
                              <div class="col-12 col-sm-3 question-btn-hld">
                                  <mat-form-field class="id-input-fld">
                                    <mat-label>Height</mat-label>
                                    <mat-select
                                      formControlName="height">
                                        <mat-option 
                                          *ngFor="let w of heightArr"
                                          [value]="w.value">
                                          {{w.text}}
                                        </mat-option>
                                    </mat-select>
                                  </mat-form-field>
                              </div>
                              <!--  -->
                              <div class="col-0 col-sm-1 question-sub-forms"></div>
                              <!-- weight -->
                              <div class="col-12 col-sm-2 question-sub-forms">
                                <div class="question-id-text">
                                  <mat-form-field class="id-input-fld">
                                    <mat-label>Weight/lbs</mat-label>
                                    <input matInput 
                                    formControlName="weight">
                                  </mat-form-field>
                                </div>                  
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <!-- A Q12 ENDS -->

                        <!-- End spacer -->
                        <div class="col-12 spacer-md"></div>

                      </mat-expansion-panel>
                      <!-- ? A) QUESTION SECTION -->

                      <!-- ??? B) QUESTION SECTION -->
                      <mat-expansion-panel 
                      [expanded]="step === 2" 
                      (opened)="setStep(2)" 
                      hideToggle>
                        <mat-expansion-panel-header style="height: 100%;">
                          <mat-panel-title>
                            B: Deferred Life
                          </mat-panel-title>
                          <mat-panel-description>
                            &nbsp;
                            <mat-icon *ngIf="!step2">help</mat-icon>
                            <mat-icon *ngIf="step2" class="icon-check">check_circle</mat-icon>
                          </mat-panel-description>
                        </mat-expansion-panel-header>
                        
                        <!-- B Q1 SET!!! -->
                        <!-- Question Title -->
                        <div class="col-12 question">
                          <form [formGroup]="questionSetB"  style="width: 100%;">
                            <div class="row question-title">
                              <!-- Title -->
                              <div class="col-12 col-sm-9 question-text">
                                <p>
                                  <b>1:</b> Within the past 60 days, have you been advised by a physician:
                                </p>

                                                              
                              
                              </div>
                              <!--  -->

                              <!-- Buttons -->
                              <div class="col-12 col-sm-3 question-btn-hld">
                                <!-- <mat-radio-group> -->
                                  <div class="col-6 question-btn qYes">
                                  </div>
                                  <div class="col-6 question-btn qNo">
                                  </div>
                                <!-- </mat-radio-group> -->
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <!-- Question (a) -->
                        <div class="col-12 question">
                          <form [formGroup]="questionSetB"  style="width: 100%;">
                            <div class="row">
                              <!-- Title -->
                              <div class="col-12 col-sm-9 question-text">
                                a) Of any abnormal diagnostic tests?                              
                              </div>
                              <!--  -->

                              <!-- Buttons -->
                              <div class="col-12 col-sm-3 question-btn-hld">
                                <!-- <mat-radio-group> -->
                                  <div class="col-6 question-btn qYes">
                                    <mat-radio-group formControlName="bq1a" name="bq1a">
                                      <mat-radio-button  value="yes" labelPosition="before">
                                        Yes
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                  <div class="col-6 question-btn qNo">
                                    <mat-radio-group formControlName="bq1a" name="bq1a">
                                      <mat-radio-button  value="no" labelPosition="before">
                                        No
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                <!-- </mat-radio-group> -->
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <!-- Question (b) -->
                        <div class="col-12 question">
                          <form [formGroup]="questionSetB"  style="width: 100%;">
                            <div class="row">
                              <!-- Title -->
                              <div class="col-12 col-sm-9 question-text">
                                b) Circulatory problems in the legs and/or feet (peripheral arterial or vascular disease)?                              
                              </div>
                              <!--  -->

                              <!-- Buttons -->
                              <div class="col-12 col-sm-3 question-btn-hld">
                                <!-- <mat-radio-group> -->
                                  <div class="col-6 question-btn qYes">
                                    <mat-radio-group formControlName="bq1b" name="bq1b">
                                      <mat-radio-button  value="yes" labelPosition="before">
                                        Yes
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                  <div class="col-6 question-btn qNo">
                                    <mat-radio-group formControlName="bq1b" name="bq1b">
                                      <mat-radio-button  value="no" labelPosition="before">
                                        No
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                <!-- </mat-radio-group> -->
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <!-- Question (c) -->
                        <div class="col-12 question">
                          <form [formGroup]="questionSetB"  style="width: 100%;">
                            <div class="row">
                              <!-- Title -->
                              <div class="col-12 col-sm-9 question-text">
                                c) Chronic kidney disease, or been investigated or been advised to be investigated for polycystic kidney disease (PKD), or have a family history of PKD and have not been investigated?                              
                              </div>
                              <!--  -->

                              <!-- Buttons -->
                              <div class="col-12 col-sm-3 question-btn-hld">
                                <!-- <mat-radio-group> -->
                                  <div class="col-6 question-btn qYes">
                                    <mat-radio-group formControlName="bq1c" name="bq1c">
                                      <mat-radio-button  value="yes" labelPosition="before">
                                        Yes
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                  <div class="col-6 question-btn qNo">
                                    <mat-radio-group formControlName="bq1c" name="bq1c">
                                      <mat-radio-button  value="no" labelPosition="before">
                                        No
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                <!-- </mat-radio-group> -->
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <!-- Question (d) -->
                        <div class="col-12 question">
                          <form [formGroup]="questionSetB"  style="width: 100%;">
                            <div class="row">
                              <!-- Title -->
                              <div class="col-12 col-sm-9 question-text">
                                d) Liver disease such as, but not limited to, cirrhosis or hepatitis (excluding Hepatitis A or B)?                              
                              </div>
                              <!--  -->

                              <!-- Buttons -->
                              <div class="col-12 col-sm-3 question-btn-hld">
                                <!-- <mat-radio-group> -->
                                  <div class="col-6 question-btn qYes">
                                    <mat-radio-group formControlName="bq1d" name="bq1d">
                                      <mat-radio-button  value="yes" labelPosition="before">
                                        Yes
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                  <div class="col-6 question-btn qNo">
                                    <mat-radio-group formControlName="bq1d" name="bq1d">
                                      <mat-radio-button  value="no" labelPosition="before">
                                        No
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                <!-- </mat-radio-group> -->
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <!-- B Q1 SET!!! -->
                        
                        <!-- B Q2  -->
                        <div class="col-12 question">
                          <form [formGroup]="questionSetB"  style="width: 100%;">
                            <div class="row">
                              <!-- Title -->
                              <div class="col-12 col-sm-9 question-text">
                                <p>
                                  <b>2:</b> Are you under age 30 and have been diagnosed with diabetes (excluding gestational diabetes) or are undergoing investigation for diabetes or your blood sugar levels?
                                </p>
                                                              
                              </div>
                              <!--  -->

                              <!-- Buttons -->
                              <div class="col-12 col-sm-3 question-btn-hld">
                                <!-- <mat-radio-group> -->
                                  <div class="col-6 question-btn qYes">
                                    <mat-radio-group formControlName="bq2" name="bq2">
                                      <mat-radio-button  value="yes" labelPosition="before">
                                        Yes
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                  <div class="col-6 question-btn qNo">
                                    <mat-radio-group formControlName="bq2" name="bq2">
                                      <mat-radio-button  value="no" labelPosition="before">
                                        No
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                <!-- </mat-radio-group> -->
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <!-- B Q2  ENDS -->

                        <!-- End spacer -->
                        <div class="col-12 spacer-md"></div>

                      </mat-expansion-panel>
                      <!-- ? B) QUESTION SECTION -->

                      <!-- ??? C) QUESTION SECTION -->
                      <mat-expansion-panel 
                      [expanded]="step === 3" 
                      (opened)="setStep(3)" 
                      hideToggle>
                        <mat-expansion-panel-header style="height: 100%;">
                          <mat-panel-title>
                            C: Deferred Elite
                          </mat-panel-title>
                          <mat-panel-description>
                            &nbsp;
                            <mat-icon *ngIf="!step3">help</mat-icon>
                            <mat-icon *ngIf="step3" class="icon-check">check_circle</mat-icon>
                          </mat-panel-description>
                        </mat-expansion-panel-header>
                        
                        <!-- C Q1 -->
                        <div class="col-12 question">
                          <form [formGroup]="questionSetC"  style="width: 100%;">
                            <div class="row">
                              <!-- Title -->
                              <div class="col-12 col-sm-9 question-text">
                                <p>
                                  <b>1:</b> Within the past 12 months, have you had, been told you have, or been treated for: bipolar disorder, schizophrenia or psychosis?
                                </p>
                                                              
                              </div>
                              <!--  -->

                              <!-- Buttons -->
                              <div class="col-12 col-sm-3 question-btn-hld">
                                <!-- <mat-radio-group> -->
                                  <div class="col-6 question-btn qYes">
                                    <mat-radio-group formControlName="cq1" name="cq1">
                                      <mat-radio-button  value="yes" labelPosition="before">
                                        Yes
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                  <div class="col-6 question-btn qNo">
                                    <mat-radio-group formControlName="cq1" name="cq1">
                                      <mat-radio-button  value="no" labelPosition="before">
                                        No
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                <!-- </mat-radio-group> -->
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <!-- C Q1 ENDS -->
                        
                        <!-- C Q2 -->
                        <div class="col-12 question">
                          <form [formGroup]="questionSetC"  style="width: 100%;">
                            <div class="row">
                              <!-- Title -->
                              <div class="col-12 col-sm-9 question-text">
                                <p>
                                  <b>2:</b> Within the past three years, have you been treated for or received medical advice or counseling for the use of drugs or alcohol?
                                </p>
                                                              
                              
                              </div>
                              <!--  -->

                              <!-- Buttons -->
                              <div class="col-12 col-sm-3 question-btn-hld">
                                <!-- <mat-radio-group> -->
                                  <div class="col-6 question-btn qYes">
                                    <mat-radio-group formControlName="cq2" name="cq2">
                                      <mat-radio-button  value="yes" labelPosition="before">
                                        Yes
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                  <div class="col-6 question-btn qNo">
                                    <mat-radio-group formControlName="cq2" name="cq2">
                                      <mat-radio-button  value="no" labelPosition="before">
                                        No
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                <!-- </mat-radio-group> -->
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <!-- C Q2 ENDS -->
                        
                        <!-- C Q3 -->
                        <div class="col-12 question">
                          <form [formGroup]="questionSetC"  style="width: 100%;">
                            <div class="row">
                              <!-- Title -->
                              <div class="col-12 col-sm-9 question-text">
                                <p>
                                  <b>3:</b> Within the past three years, have you used narcotics or barbiturates (except as prescribed by a physician), heroin, psychoactive drugs, cocaine, crack or other similar agents?
                                </p>
                                                              
                              
                              </div>
                              <!--  -->

                              <!-- Buttons -->
                              <div class="col-12 col-sm-3 question-btn-hld">
                                <!-- <mat-radio-group> -->
                                  <div class="col-6 question-btn qYes">
                                    <mat-radio-group formControlName="cq3" name="cq3">
                                      <mat-radio-button  value="yes" labelPosition="before">
                                        Yes
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                  <div class="col-6 question-btn qNo">
                                    <mat-radio-group formControlName="cq3" name="cq3">
                                      <mat-radio-button  value="no" labelPosition="before">
                                        No
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                <!-- </mat-radio-group> -->
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <!-- C Q3 ENDS -->
                        
                        <!-- C Q4 SET!!! -->
                        <!-- Question Title -->
                        <div class="col-12 question">
                          <form [formGroup]="questionSetC"  style="width: 100%;">
                            <div class="row question-title">
                              <!-- Title -->
                              <div class="col-12 col-sm-9 question-text">
                                <p>
                                  <b>4:</b> Are You?
                                </p>
                                                              
                              
                              </div>
                              <!--  -->

                              <!-- Buttons -->
                              <div class="col-12 col-sm-3 question-btn-hld">
                                <!-- <mat-radio-group> -->
                                  <div class="col-6 question-btn qYes">
                                  </div>
                                  <div class="col-6 question-btn qNo">
                                  </div>
                                <!-- </mat-radio-group> -->
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <!-- Question (a) -->
                        <div class="col-12 question">
                          <form [formGroup]="questionSetC"  style="width: 100%;">
                            <div class="row">
                              <!-- Title -->
                              <div class="col-12 col-sm-9 question-text">
                                a) Are you age 54 or under and within the past three years, have you had treatment or surgery for or been diagnosed as having cardiac chest pain (angina), heart attack (myocardial infarction), coronary artery disease, heart bypass surgery, angioplasty, stent insertion, stroke (CVA) or chronic lung disease (excluding asthma)?                              
                              
                              </div>
                              <!--  -->

                              <!-- Buttons -->
                              <div class="col-12 col-sm-3 question-btn-hld">
                                <!-- <mat-radio-group> -->
                                  <div class="col-6 question-btn qYes">
                                    <mat-radio-group formControlName="cq4a" name="cq4a">
                                      <mat-radio-button  value="yes" labelPosition="before">
                                        Yes
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                  <div class="col-6 question-btn qNo">
                                    <mat-radio-group formControlName="cq4a" name="cq4a">
                                      <mat-radio-button  value="no" labelPosition="before">
                                        No
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                <!-- </mat-radio-group> -->
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <!-- Question (b) -->
                        <div class="col-12 question">
                          <form [formGroup]="questionSetC"  style="width: 100%;">
                            <div class="row">
                              <!-- Title -->
                              <div class="col-12 col-sm-9 question-text">
                                b) Are you age 55 or over and within the past two years, have you had treatment or surgery for or been diagnosed as having cardiac chest pain (angina), heart attack (myocardial infarction), coronary artery disease, heart bypass surgery, angioplasty, stent insertion, stroke (CVA) or chronic lung disease (excluding asthma)?                              
                              
                              </div>
                              <!--  -->

                              <!-- Buttons -->
                              <div class="col-12 col-sm-3 question-btn-hld">
                                <!-- <mat-radio-group> -->
                                  <div class="col-6 question-btn qYes">
                                    <mat-radio-group formControlName="cq4b" name="cq4b">
                                      <mat-radio-button  value="yes" labelPosition="before">
                                        Yes
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                  <div class="col-6 question-btn qNo">
                                    <mat-radio-group formControlName="cq4b" name="cq4b">
                                      <mat-radio-button  value="no" labelPosition="before">
                                        No
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                <!-- </mat-radio-group> -->
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <!-- C Q4 SET!!! ENDS -->
                        
                        <!-- C Q5 -->
                        <div class="col-12 question">
                          <form [formGroup]="questionSetC"  style="width: 100%;">
                            <div class="row">
                              <!-- Title -->
                              <div class="col-12 col-sm-9 question-text">
                                <p>
                                  <b>5:</b> Are you under age 55 with diabetes that was diagnosed more than 20 years ago and is currently treated with insulin?
                                </p>
                                                              
                              
                              </div>
                              <!--  -->

                              <!-- Buttons -->
                              <div class="col-12 col-sm-3 question-btn-hld">
                                <!-- <mat-radio-group> -->
                                  <div class="col-6 question-btn qYes">
                                    <mat-radio-group formControlName="cq5" name="cq5">
                                      <mat-radio-button  value="yes" labelPosition="before">
                                        Yes
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                  <div class="col-6 question-btn qNo">
                                    <mat-radio-group formControlName="cq5" name="cq5">
                                      <mat-radio-button  value="no" labelPosition="before">
                                        No
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                <!-- </mat-radio-group> -->
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <!-- C Q5 ENDS -->
                        
                        <!-- C Q6 -->
                        <div class="col-12 question">
                          <form [formGroup]="questionSetC"  style="width: 100%;">
                            <div class="row">
                              <!-- Title -->
                              <div class="col-12 col-sm-9 question-text">
                                <p>
                                  <b>6:</b> Do you have diabetes that is currently treated with insulin and the prescribed dosage of insulin increased within the past six months?
                                </p>
                                                              
                              
                              </div>
                              <!--  -->

                              <!-- Buttons -->
                              <div class="col-12 col-sm-3 question-btn-hld">
                                <!-- <mat-radio-group> -->
                                  <div class="col-6 question-btn qYes">
                                    <mat-radio-group formControlName="cq6" name="cq6">
                                      <mat-radio-button  value="yes" labelPosition="before">
                                        Yes
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                  <div class="col-6 question-btn qNo">
                                    <mat-radio-group formControlName="cq6" name="cq6">
                                      <mat-radio-button  value="no" labelPosition="before">
                                        No
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                <!-- </mat-radio-group> -->
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <!-- C Q6 ENDS -->
                        
                        <!-- C Q7 -->
                        <div class="col-12 question">
                          <form [formGroup]="questionSetC"  style="width: 100%;">
                            <div class="row">
                              <!-- Title -->
                              <div class="col-12 col-sm-9 question-text">
                                <p>
                                  <b>7:</b> Have you ever had, been told you have, or been treated for diabetes and any of the following: coronary artery disease, peripheral vascular disease, tingling and loss of feeling in the extremities (neuropathy), amputation, retinopathy, or stroke (CVA)?
                                </p>
                                                             
                              
                              </div>
                              <!--  -->

                              <!-- Buttons -->
                              <div class="col-12 col-sm-3 question-btn-hld">
                                <!-- <mat-radio-group> -->
                                  <div class="col-6 question-btn qYes">
                                    <mat-radio-group formControlName="cq7" name="cq7">
                                      <mat-radio-button  value="yes" labelPosition="before">
                                        Yes
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                  <div class="col-6 question-btn qNo">
                                    <mat-radio-group formControlName="cq7" name="cq7">
                                      <mat-radio-button  value="no" labelPosition="before">
                                        No
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                <!-- </mat-radio-group> -->
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <!-- C Q7 ENDS -->
                        
                        <!-- C Q8 SET!!! -->
                        <!-- Question Title -->
                        <div class="col-12 question">
                          <form [formGroup]="questionSetC"  style="width: 100%;">
                            <div class="row question-title">
                              <!-- Title -->
                              <div class="col-12 col-sm-9 question-text">
                                <p>
                                  <b>8:</b> Within the past three years have you had, been told you have, or been treated for:
                                </p>
                                                              
                              
                              </div>
                              <!--  -->

                              <!-- Buttons -->
                              <div class="col-12 col-sm-3 question-btn-hld">
                                <!-- <mat-radio-group> -->
                                  <div class="col-6 question-btn qYes">
                                  </div>
                                  <div class="col-6 question-btn qNo">
                                  </div>
                                <!-- </mat-radio-group> -->
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <!-- Question (a) -->
                        <div class="col-12 question">
                          <form [formGroup]="questionSetC"  style="width: 100%;">
                            <div class="row">
                              <!-- Title -->
                              <div class="col-12 col-sm-9 question-text">
                                a) Lung cancer?

                              </div>
                              <!--  -->

                              <!-- Buttons -->
                              <div class="col-12 col-sm-3 question-btn-hld">
                                <!-- <mat-radio-group> -->
                                  <div class="col-6 question-btn qYes">
                                    <mat-radio-group formControlName="cq8a" name="cq8a">
                                      <mat-radio-button  value="yes" labelPosition="before">
                                        Yes
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                  <div class="col-6 question-btn qNo">
                                    <mat-radio-group formControlName="cq8a" name="cq8a">
                                      <mat-radio-button  value="no" labelPosition="before">
                                        No
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                <!-- </mat-radio-group> -->
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <!-- Question (b) -->
                        <div class="col-12 question">
                          <form [formGroup]="questionSetC"  style="width: 100%;">
                            <div class="row">
                              <!-- Title -->
                              <div class="col-12 col-sm-9 question-text">
                                b) Colon cancer?

                              </div>
                              <!--  -->

                              <!-- Buttons -->
                              <div class="col-12 col-sm-3 question-btn-hld">
                                <!-- <mat-radio-group> -->
                                  <div class="col-6 question-btn qYes">
                                    <mat-radio-group formControlName="cq8b" name="cq8b">
                                      <mat-radio-button  value="yes" labelPosition="before">
                                        Yes
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                  <div class="col-6 question-btn qNo">
                                    <mat-radio-group formControlName="cq8b" name="cq8b">
                                      <mat-radio-button  value="no" labelPosition="before">
                                        No
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                <!-- </mat-radio-group> -->
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <!-- Question (c) -->
                        <div class="col-12 question">
                          <form [formGroup]="questionSetC"  style="width: 100%;">
                            <div class="row">
                              <!-- Title -->
                              <div class="col-12 col-sm-9 question-text">
                                c) Breast cancer, cervical cancer or uterine cancer?

                              </div>
                              <!--  -->

                              <!-- Buttons -->
                              <div class="col-12 col-sm-3 question-btn-hld">
                                <!-- <mat-radio-group> -->
                                  <div class="col-6 question-btn qYes">
                                    <mat-radio-group formControlName="cq8c" name="cq8c">
                                      <mat-radio-button  value="yes" labelPosition="before">
                                        Yes
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                  <div class="col-6 question-btn qNo">
                                    <mat-radio-group formControlName="cq8c" name="cq8c">
                                      <mat-radio-button  value="no" labelPosition="before">
                                        No
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                <!-- </mat-radio-group> -->
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <!-- Question (d) -->
                        <div class="col-12 question">
                          <form [formGroup]="questionSetC"  style="width: 100%;">
                            <div class="row">
                              <!-- Title -->
                              <div class="col-12 col-sm-9 question-text">
                                d) Malignant melanoma?

                              </div>
                              <!--  -->

                              <!-- Buttons -->
                              <div class="col-12 col-sm-3 question-btn-hld">
                                <!-- <mat-radio-group> -->
                                  <div class="col-6 question-btn qYes">
                                    <mat-radio-group formControlName="cq8d" name="cq8d">
                                      <mat-radio-button  value="yes" labelPosition="before">
                                        Yes
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                  <div class="col-6 question-btn qNo">
                                    <mat-radio-group formControlName="cq8d" name="cq8d">
                                      <mat-radio-button  value="no" labelPosition="before">
                                        No
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                <!-- </mat-radio-group> -->
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <!-- Question (e) -->
                        <div class="col-12 question">
                          <form [formGroup]="questionSetC"  style="width: 100%;">
                            <div class="row">
                              <!-- Title -->
                              <div class="col-12 col-sm-9 question-text">
                                e) Leukemia (all types), lymphoma or multiple myeloma?

                              </div>
                              <!--  -->

                              <!-- Buttons -->
                              <div class="col-12 col-sm-3 question-btn-hld">
                                <!-- <mat-radio-group> -->
                                  <div class="col-6 question-btn qYes">
                                    <mat-radio-group formControlName="cq8e" name="cq8e">
                                      <mat-radio-button  value="yes" labelPosition="before">
                                        Yes
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                  <div class="col-6 question-btn qNo">
                                    <mat-radio-group formControlName="cq8e" name="cq8e">
                                      <mat-radio-button  value="no" labelPosition="before">
                                        No
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                <!-- </mat-radio-group> -->
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <!-- C Q8 SET!!! ENDS -->
                        
                        <!-- C Q9 -->
                        <div class="col-12 question">
                          <form [formGroup]="questionSetC"  style="width: 100%;">
                            <div class="row">
                              <!-- Title -->
                              <div class="col-12 col-sm-9 question-text">
                                <p>
                                  <b>9:</b> Do you plan to travel outside North America, the Caribbean (excluding Haiti), the United Kingdom or the European Union countries for more than 12 consecutive weeks in the next 12 months?
                                </p>
                                                              
                              
                              </div>
                              <!--  -->

                              <!-- Buttons -->
                              <div class="col-12 col-sm-3 question-btn-hld">
                                <!-- <mat-radio-group> -->
                                  <div class="col-6 question-btn qYes">
                                    <mat-radio-group formControlName="cq9" name="cq9">
                                      <mat-radio-button  value="yes" labelPosition="before">
                                        Yes
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                  <div class="col-6 question-btn qNo">
                                    <mat-radio-group formControlName="cq9" name="cq9">
                                      <mat-radio-button  value="no" labelPosition="before">
                                        No
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                <!-- </mat-radio-group> -->
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <!-- C Q9 ENDS -->
                        
                        <!-- C Q10 -->
                        <div class="col-12 question">
                          <form [formGroup]="questionSetC"  style="width: 100%;">
                            <div class="row">
                              <!-- Title -->
                              <div class="col-12 col-sm-9 question-text">
                                <p>
                                  <b>10:</b> Have you had a weight loss of 10% of body weight or more within the past 12 months other than due to intentional dieting?
                                </p>
                                                              
                              
                              </div>
                              <!--  -->

                              <!-- Buttons -->
                              <div class="col-12 col-sm-3 question-btn-hld">
                                <!-- <mat-radio-group> -->
                                  <div class="col-6 question-btn qYes">
                                    <mat-radio-group formControlName="cq10" name="cq10">
                                      <mat-radio-button  value="yes" labelPosition="before">
                                        Yes
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                  <div class="col-6 question-btn qNo">
                                    <mat-radio-group formControlName="cq10" name="cq10">
                                      <mat-radio-button  value="no" labelPosition="before">
                                        No
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                <!-- </mat-radio-group> -->
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <!-- C Q10 ENDS -->
                        <!-- Question Title -->
                        <div class="col-12 question">
                          <form [formGroup]="questionSetA"  style="width: 100%;">
                            <div class="row question-title">
                              <!-- Title -->
                              <div class="col-12 col-sm-9 question-text">
                                <p><b>11:</b> Please enter your height and weight.</p>                             
                              </div>
                              <!--  -->

                              <!-- Buttons -->
                              <div class="col-12 col-sm-3 question-btn-hld">
                                <!-- <mat-radio-group> -->
                                  <div class="col-6 question-btn qYes">
                                  </div>
                                  <div class="col-6 question-btn qNo">
                                  </div>
                                <!-- </mat-radio-group> -->
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>

                        <!-- A Q12 height -->
                        <div class="col-12 question">
                          <form [formGroup]="questionSetC"  style="width: 100%;">
                            <div class="row">
                              <!-- height -->
                              <div class="col-12 col-sm-3 question-btn-hld">
                                  <mat-form-field class="id-input-fld">
                                    <mat-label>Height</mat-label>
                                    <mat-select
                                      formControlName="height">
                                        <mat-option 
                                          *ngFor="let w of heightArr"
                                          [value]="w.value">
                                          {{w.text}}
                                        </mat-option>
                                    </mat-select>
                                  </mat-form-field>
                              </div>
                              <!--  -->
                              <div class="col-0 col-sm-1 question-sub-forms"></div>
                              <!-- weight -->
                              <div class="col-12 col-sm-2 question-sub-forms">
                                <div class="question-id-text">
                                  <mat-form-field class="id-input-fld">
                                    <mat-label>Weight/lbs</mat-label>
                                    <input matInput 
                                    formControlName="weight">
                                  </mat-form-field>
                                </div>                  
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <!-- A Q12 ENDS -->

                        <!-- End spacer -->
                        <div class="col-12 spacer-md"></div>

                      </mat-expansion-panel>
                      <!-- ? C) QUESTION SECTION -->

                      <!-- ??? D) QUESTION SECTION  -->
                      <mat-expansion-panel 
                      [expanded]="step === 4" 
                      (opened)="setStep(4)" 
                      hideToggle>
                        <mat-expansion-panel-header style="height: 100%;">
                          <mat-panel-title>
                            D: Simplified Elite
                          </mat-panel-title>
                          <mat-panel-description>
                            &nbsp;
                            <mat-icon *ngIf="!step4">help</mat-icon>
                            <mat-icon *ngIf="step4" class="icon-check">check_circle</mat-icon>
                          </mat-panel-description>
                        </mat-expansion-panel-header>
                        
                        <!-- D Q1 -->
                        <div class="col-12 question">
                          <form [formGroup]="questionSetD"  style="width: 100%;">
                            <div class="row">
                              <!-- Title -->
                              <div class="col-12 col-sm-9 question-text">
                                <p>
                                  <b>1:</b> Within the past 12 months, have you been told you have, been treated for, or are you currently under investigation for multiple sclerosis?
                                </p>
                                                              
                              
                              </div>
                              <!--  -->

                              <!-- Buttons -->
                              <div class="col-12 col-sm-3 question-btn-hld">
                                <!-- <mat-radio-group> -->
                                  <div class="col-6 question-btn qYes">
                                    <mat-radio-group formControlName="dq1" name="dq1">
                                      <mat-radio-button  value="yes" labelPosition="before">
                                        Yes
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                  <div class="col-6 question-btn qNo">
                                    <mat-radio-group formControlName="dq1" name="dq1">
                                      <mat-radio-button  value="no" labelPosition="before">
                                        No
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                <!-- </mat-radio-group> -->
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <!-- D Q1 ENDS -->
                        
                        <!-- D Q2 -->
                        <div class="col-12 question">
                          <form [formGroup]="questionSetD"  style="width: 100%;">
                            <div class="row">
                              <!-- Title -->
                              <div class="col-12 col-sm-9 question-text">
                                <p>
                                  <b>2:</b> Have you ever had or been treated for cancer including, but not limited to, leukemia and lymphoma (excluding basal cell carcinoma)?
                                </p>
                                                             
                              
                              </div>
                              <!--  -->

                              <!-- Buttons -->
                              <div class="col-12 col-sm-3 question-btn-hld">
                                <!-- <mat-radio-group> -->
                                  <div class="col-6 question-btn qYes">
                                    <mat-radio-group formControlName="dq2" name="dq2">
                                      <mat-radio-button  value="yes" labelPosition="before">
                                        Yes
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                  <div class="col-6 question-btn qNo">
                                    <mat-radio-group formControlName="dq2" name="dq2">
                                      <mat-radio-button  value="no" labelPosition="before">
                                        No
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                <!-- </mat-radio-group> -->
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <!-- D Q2 ENDS -->
                        
                        <!-- D Q3 -->
                        <div class="col-12 question">
                          <form [formGroup]="questionSetD"  style="width: 100%;">
                            <div class="row">
                              <!-- Title -->
                              <div class="col-12 col-sm-9 question-text">
                                <p>
                                  <b>3:</b> Within the past six months, have you been told you have or been treated for diabetes?
                                </p>
                                                              
                              
                              </div>
                              <!--  -->

                              <!-- Buttons -->
                              <div class="col-12 col-sm-3 question-btn-hld">
                                <!-- <mat-radio-group> -->
                                  <div class="col-6 question-btn qYes">
                                    <mat-radio-group formControlName="dq3" name="dq3">
                                      <mat-radio-button  value="yes" labelPosition="before">
                                        Yes
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                  <div class="col-6 question-btn qNo">
                                    <mat-radio-group formControlName="dq3" name="dq3">
                                      <mat-radio-button  value="no" labelPosition="before">
                                        No
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                <!-- </mat-radio-group> -->
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <!-- D Q3 ENDS -->
                        
                        <!-- D Q4 -->
                        <div class="col-12 question">
                          <form [formGroup]="questionSetD"  style="width: 100%;">
                            <div class="row">
                              <!-- Title -->
                              <div class="col-12 col-sm-9 question-text">
                                <p>
                                  <b>4:</b> Within the past three years, have you been incarcerated or on probation for a criminal offence or are criminal charges now pending excluding a single DUI?
                                </p>
                                                              
                              
                              </div>
                              <!--  -->

                              <!-- Buttons -->
                              <div class="col-12 col-sm-3 question-btn-hld">
                                <!-- <mat-radio-group> -->
                                  <div class="col-6 question-btn qYes">
                                    <mat-radio-group formControlName="dq4" name="dq4">
                                      <mat-radio-button  value="yes" labelPosition="before">
                                        Yes
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                  <div class="col-6 question-btn qNo">
                                    <mat-radio-group formControlName="dq4" name="dq4">
                                      <mat-radio-button  value="no" labelPosition="before">
                                        No
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                <!-- </mat-radio-group> -->
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <!-- D Q4 ENDS -->
                        
                        <!-- D Q5 -->
                        <div class="col-12 question">
                          <form [formGroup]="questionSetD"  style="width: 100%;">
                            <div class="row">
                              <!-- Title -->
                              <div class="col-12 col-sm-9 question-text">
                                <p>
                                  <b>5:</b> Within the past two years, have you been involved in the operation of an aircraft as a pilot (scheduled commercial pilots excluded) or involved in any hazardous sports, or do you plan to do so within the next year?
                                </p>
                                                              
                              
                              </div>
                              <!--  -->

                              <!-- Buttons -->
                              <div class="col-12 col-sm-3 question-btn-hld">
                                <!-- <mat-radio-group> -->
                                  <div class="col-6 question-btn qYes">
                                    <mat-radio-group formControlName="dq5" name="dq5">
                                      <mat-radio-button  value="yes" labelPosition="before">
                                        Yes
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                  <div class="col-6 question-btn qNo">
                                    <mat-radio-group formControlName="dq5" name="dq5">
                                      <mat-radio-button  value="no" labelPosition="before">
                                        No
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                <!-- </mat-radio-group> -->
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <!-- D Q5 ENDS -->
                        
                        <!-- D Q6 -->
                        <div class="col-12 question">
                          <form [formGroup]="questionSetD"  style="width: 100%;">
                            <div class="row">
                              <!-- Title -->
                              <div class="col-12 col-sm-9 question-text">
                                <p>
                                  <b>6:</b> Within the past two years, has your driver's license been suspended or revoked, or have you had more than three moving violations within the past 12 months?
                                </p>
                                                              
                              
                              </div>
                              <!--  -->

                              <!-- Buttons -->
                              <div class="col-12 col-sm-3 question-btn-hld">
                                <!-- <mat-radio-group> -->
                                  <div class="col-6 question-btn qYes">
                                    <mat-radio-group formControlName="dq6" name="dq6">
                                      <mat-radio-button  value="yes" labelPosition="before">
                                        Yes
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                  <div class="col-6 question-btn qNo">
                                    <mat-radio-group formControlName="dq6" name="dq6">
                                      <mat-radio-button  value="no" labelPosition="before">
                                        No
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                <!-- </mat-radio-group> -->
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <!-- D Q6 ENDS -->

                        <!-- End spacer -->
                        <div class="col-12 spacer-md"></div>
                        
                        <!-- Default Content -->

                      </mat-expansion-panel>
                      <!-- ? D) QUESTION SECTION  -->

                      <!-- ??? E) QUESTION SECTION-->
                      <mat-expansion-panel *ngIf="true"
                      [expanded]="step === 5" 
                      (opened)="setStep(5)" 
                      hideToggle>
                        <mat-expansion-panel-header style="height: 100%;">
                          <mat-panel-title>
                            E: Preferred
                          </mat-panel-title>
                          <mat-panel-description>
                            &nbsp;
                            <mat-icon *ngIf="!step5">help</mat-icon>
                            <mat-icon *ngIf="step5" class="icon-check">check_circle</mat-icon>
                          </mat-panel-description>
                        </mat-expansion-panel-header>
                        
                        <!-- E Q1 -->
                        <div class="col-12 question">
                          <form [formGroup]="questionSetE"  style="width: 100%;">
                            <div class="row">
                              <!-- Title -->
                              <div class="col-12 col-sm-9 question-text">
                                <p>
                                  <b>1:</b> Have you ever been prescribed a medication that was for more than 30 days for a medical condition?
                                </p>
                                                              
                              </div>
                              <!--  -->
                              
                              <!-- Buttons -->
                              <div class="col-12 col-sm-3 question-btn-hld">
                                <!-- <mat-radio-group> -->
                                  <div class="col-6 question-btn qYes">
                                    <mat-radio-group formControlName="eq1" name="eq1">
                                      <mat-radio-button  value="yes" labelPosition="before">
                                        Yes
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                  <div class="col-6 question-btn qNo">
                                    <mat-radio-group formControlName="eq1" name="eq1">
                                      <mat-radio-button  value="no" labelPosition="before">
                                        No
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                <!-- </mat-radio-group> -->
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <!-- E Q1 ENDS -->

                        <!-- E Q2 TITLE -->
                        <div class="col-12 question" *ngIf="questionSetE.get('eq1').value === 'yes'">
                          <form [formGroup]="questionSetC"  style="width: 100%;">
                            <div class="row question-title">
                              <!-- Title -->
                              <div class="col-12 col-sm-9 question-text">
                                If YES, please advise the name of the prescription(s) and the nature of the medical condition they were prescribed for.                             
                              
                              </div>
                              <!--  -->

                              <!-- Buttons -->
                              <div class="col-12 col-sm-3 question-btn-hld">
                                <!-- <mat-radio-group> -->
                                  <div class="col-6 question-btn qYes">
                                  </div>
                                  <div class="col-6 question-btn qNo">
                                  </div>
                                <!-- </mat-radio-group> -->
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <!-- E Q2 TITLE ENDS-->

                        <!-- E Q2 -->
                        <div class="col-12 question" *ngIf="questionSetE.get('eq1').value === 'yes'">
                          <form [formGroup]="questionSetE"  style="width: 100%;">
                            <!-- details  -->
                            <div class="col-12 question-sub-forms">
                              <div class="col-12 question-id-text">
                                <mat-form-field class="id-input-fld">
                                  <mat-label>Details</mat-label>
                                  <input matInput 
                                  formControlName="details">
                                </mat-form-field>
                              </div>
                            </div>
                            <!-- ** details ends -->
                          </form>
                        </div>
                        <!-- E Q2 ENDS-->

                        <!-- E Q3 TITLE -->
                        <div class="col-12 question" *ngIf="questionSetE.get('eq1').value === 'yes'">
                          <form [formGroup]="questionSetC"  style="width: 100%;">
                            <div class="row question-title">
                              <!-- Title -->
                              <div class="col-12 col-sm-9 question-text">
                                Physicians information
                              </div>
                              <!--  -->

                              <!-- Buttons -->
                              <div class="col-12 col-sm-3 question-btn-hld">
                                <!-- <mat-radio-group> -->
                                  <div class="col-6 question-btn qYes">
                                  </div>
                                  <div class="col-6 question-btn qNo">
                                  </div>
                                <!-- </mat-radio-group> -->
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <!-- E Q3 TITLE ENDS-->

                        <!-- E Q3 -->
                        <form [formGroup]="questionSetE"  style="width: 100%;" *ngIf="questionSetE.get('eq1').value === 'yes'">
                          <!-- phone  -->
                          <div class="col-12 question-sub-forms">
                            <div class="col-12 col-sm-6 question-id-text">
                              <mat-form-field class="id-input-fld">
                                <mat-label>Your physician’s name</mat-label>
                                <input matInput 
                                formControlName="physician">
                              </mat-form-field>
                            </div>
                            <!--  -->
                            <div class="col-12 col-sm-6 question-id-text">
                              <mat-form-field class="id-input-fld">
                                <mat-label>Date last consulted</mat-label>
                                <input matInput 
                                formControlName="dateConsulted">
                              </mat-form-field>
                            </div>
                          </div>
                          <!-- ** phone ends -->
                        </form>
                        <!-- E Q3 ENDS -->

                        <!-- E Q4 -->
                        <form [formGroup]="questionSetE"  style="width: 100%;" *ngIf="questionSetE.get('eq1').value === 'yes'">
                          <!-- details  -->
                          <div class="col-12 question-sub-forms">
                            <div class="col-12 question-id-text">
                              <mat-form-field class="id-input-fld">
                                <mat-label>Address of your physician</mat-label>
                                <input matInput 
                                formControlName="physicianAddress"
                                ngx-google-places-autocomplete
                                (onAddressChange)="handleAddressChange($event)">
                              </mat-form-field>
                            </div>
                          </div>
                          <!-- ** details ends -->
                        </form>
                        <!-- E Q4 ENDS-->

                        <!-- E Q5 -->
                        <form [formGroup]="questionSetE"  style="width: 100%;" *ngIf="questionSetE.get('eq1').value === 'yes'">
                          <!-- details  -->
                          <div class="col-12 question-sub-forms">
                            <div class="col-12 question-id-text">
                              <mat-form-field class="id-input-fld">
                                <mat-label>Reason for consult</mat-label>
                                <input matInput 
                                formControlName="reason">
                              </mat-form-field>
                            </div>
                          </div>
                          <!-- ** details ends -->
                        </form>
                        <!-- E Q5 ENDS-->

                        <!-- End spacer -->
                        <div class="col-12 spacer-md"></div>
                        
                        <!-- Default Content -->

                      </mat-expansion-panel>
                      <!-- ? E) QUESTION SECTION ENDS -->

                      <!-- ??? F) QUESTION SECTION -->
                      <mat-expansion-panel *ngIf="true"
                      [expanded]="step === 6" 
                      (opened)="setStep(6)" 
                      hideToggle>
                        <mat-expansion-panel-header style="height: 100%;">
                          <mat-panel-title>
                            F: Preferred Elite
                          </mat-panel-title>
                          <mat-panel-description>
                            &nbsp;
                            <mat-icon *ngIf="!step6">help</mat-icon>
                            <mat-icon *ngIf="step6" class="icon-check">check_circle</mat-icon>
                          </mat-panel-description>
                        </mat-expansion-panel-header>
                        
                        <!-- F Q1 -->
                        <div class="col-12 question">
                          <form [formGroup]="questionSetF"  style="width: 100%;">
                            <div class="row">
                              <!-- Title -->
                              <div class="col-12 col-sm-9 question-text">
                                <p>
                                  <b>1:</b> Within the past 12 months, have you been told you have, been treated for, or are you currently under investigation for multiple sclerosis?
                                </p>
                                                              
                              
                              </div>
                              <!--  -->

                              <!-- Buttons -->
                              <div class="col-12 col-sm-3 question-btn-hld">
                                <!-- <mat-radio-group> -->
                                  <div class="col-6 question-btn qYes">
                                    <mat-radio-group formControlName="fq1" name="fq1">
                                      <mat-radio-button  value="yes" labelPosition="before">
                                        Yes
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                  <div class="col-6 question-btn qNo">
                                    <mat-radio-group formControlName="fq1" name="fq1">
                                      <mat-radio-button  value="no" labelPosition="before">
                                        No
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                <!-- </mat-radio-group> -->
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <!-- F Q1 ENDS -->

                        <!-- F Q2 TITLE -->
                        <div class="col-12 question" *ngIf="questionSetF.get('fq1').value === 'yes'">
                          <form [formGroup]="questionSetF"  style="width: 100%;">
                            <div class="row question-title">
                              <!-- Title -->
                              <div class="col-12 col-sm-9 question-text">
                                If YES, please provide details including age and cause of death or diagnosis of each.                              
                              </div>
                              <!--  -->

                              <!-- Buttons -->
                              <div class="col-12 col-sm-3 question-btn-hld">
                                <!-- <mat-radio-group> -->
                                  <div class="col-6 question-btn qYes">
                                  </div>
                                  <div class="col-6 question-btn qNo">
                                  </div>
                                <!-- </mat-radio-group> -->
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <!-- F Q2 TITLE ENDS-->

                        <!-- F Q2 -->
                        <div class="col-12 question" *ngIf="questionSetF.get('fq1').value === 'yes'">
                          <form [formGroup]="questionSetF"  style="width: 100%;">
                            <!-- details  -->
                            <div class="col-12 question-sub-forms">
                              <div class="col-12 question-id-text">
                                <mat-form-field class="id-input-fld">
                                  <mat-label>Details</mat-label>
                                  <input matInput 
                                  formControlName="details">
                                </mat-form-field>
                              </div>
                            </div>
                            <!-- ** details ends -->
                          </form>
                        </div>
                        <!-- F Q2 ENDS-->

                        <!-- End spacer -->
                        <div class="col-12 spacer-md"></div>
                        
                        <!-- Default Content -->

                      </mat-expansion-panel>
                      <!-- ? F) QUESTION SECTION ENDS -->

                      <!-- ??? BENEFICIARY -->
                      <mat-expansion-panel 
                      [expanded]="step === 7" 
                      (opened)="setStep(7)" 
                      hideToggle
                      *ngIf="true">
                        <mat-expansion-panel-header style="height: 100%;">
                          <mat-panel-title>
                            Beneficiary
                          </mat-panel-title>
                          <mat-panel-description>
                            &nbsp;
                            <mat-icon *ngIf="!step7">help</mat-icon>
                            <mat-icon *ngIf="step7" class="icon-check">check_circle</mat-icon>
                          </mat-panel-description>
                        </mat-expansion-panel-header>

                        <!-- Beneficiary Group -->
                        <form [formGroup]="questionSetBene">
                          <ng-container formArrayName="beneInputArr">
                            <div *ngFor="let bene of beneInputArr.controls; let i = index;">
                              <!-- STATUS FORM -->                        
                              <div [formGroupName]="i"
                                style="width: 100%;">
                                <!-- Status -->
                                <div class="col-12 question-sub-forms">
                                  <div class="col-12 col-sm-4 question-id-text">
                                    <mat-form-field class="id-input-fld">
                                      <mat-label>Relationship to insured</mat-label>
                                      <mat-select formControlName="insured">
                                        <mat-option value="default" disabled>Assignee...</mat-option>
                                        <mat-option value="Aunt">Aunt</mat-option>
                                        <mat-option value="Brother">Brother</mat-option>
                                        <mat-option value="Brother-in-Law">Brother-in-Law</mat-option>
                                        <mat-option value="Business">Business</mat-option>
                                        <mat-option value="Common-Law-Husband">Common-Law-Husband</mat-option>
                                        <mat-option value="Common-Law-Wife">Common-Law-Wife</mat-option>
                                        <mat-option value="Cousin">Cousin</mat-option>
                                        <mat-option value="Daughter">Daughter</mat-option>
                                        <mat-option value="Daughter-in-Law">Daughter-in-Law</mat-option>
                                        <mat-option value="Employer">Employer</mat-option>
                                        <mat-option value="Father">Father</mat-option>
                                        <mat-option value="Father-In-Law">Father-In-Law</mat-option>
                                        <mat-option value="Fiancé">Fiancé</mat-option>
                                        <mat-option value="Friend">Friend</mat-option>
                                        <mat-option value="Goddaughter">Goddaughter</mat-option>
                                        <mat-option value="Godmother">Godmother</mat-option>
                                        <mat-option value="Godson">Godson</mat-option>
                                        <mat-option value="Grand-Niece">Grand-Niece</mat-option>
                                        <mat-option value="Granddaughter">Granddaughter</mat-option>
                                        <mat-option value="Grandfather">Grandfather</mat-option>
                                        <mat-option value="Grandmother">Grandmother</mat-option>
                                        <mat-option value="Grandson">Grandson</mat-option>
                                        <mat-option value="Great-Grandfather">Great-Grandfather</mat-option>
                                        <mat-option value="Guardian">Guardian</mat-option>
                                        <mat-option value="Husband">Husband</mat-option>
                                        <mat-option value="Mortuary">Mortuary</mat-option>
                                        <mat-option value="Mother">Mother</mat-option>
                                        <mat-option value="Mother-in-Law">Mother-in-Law</mat-option>
                                        <mat-option value="Nephew">Nephew</mat-option>
                                        <mat-option value="Niece">Niece</mat-option>
                                        <mat-option value="Other">Other</mat-option>
                                        <mat-option value="President">President</mat-option>
                                        <mat-option value="Self">Self</mat-option>
                                        <mat-option value="Shareholder">Shareholder</mat-option>
                                        <mat-option value="Sister">Sister</mat-option>
                                        <mat-option value="Sister-in-Law">Sister-in-Law</mat-option>
                                        <mat-option value="Son">Son</mat-option>
                                        <mat-option value="Son-in-Law">Son-in-Law </mat-option>
                                      </mat-select>
                                    </mat-form-field>
                                  </div>
                                  <div class="col-12 col-sm-4 question-id-text ">
                                    <mat-form-field class="id-input-fld">
                                      <mat-label>%</mat-label>
                                      <input matInput 
                                      formControlName="percent">
                                    </mat-form-field>
                                  </div>
                                  <div class="col-12 col-sm-4 question-id-text">
                                    <mat-form-field class="id-input-fld">
                                      <mat-label>Status</mat-label>
                                      <mat-select formControlName="status">
                                        <mat-option value="Revocable">Revocable</mat-option>
                                        <mat-option value="Irrevocable">Irrevocable</mat-option>
                                      </mat-select>
                                    </mat-form-field>
                                  </div>
                                </div>
                                <!-- ** Name ends-->
      
                              </div>
                              <!-- STATUS FORM ENDS --> 
                              
                              <!--  NAME FORM -->                        
                              <div [formGroupName]="i"
                              style="width: 100%;">
                                <!-- NAME FORM  -->
                                <div class="col-12 question-sub-forms">
                                  <div class="col-12 col-sm-4 question-id-text">
                                    <mat-form-field class="id-input-fld">
                                      <mat-label>First Name</mat-label>
                                      <input matInput 
                                      formControlName="firstNameBene">
                                    </mat-form-field>
                                  </div>
                                  <div class="col-12 col-sm-4 question-id-text ">
                                    <mat-form-field class="id-input-fld">
                                      <mat-label>Last Name</mat-label>
                                      <input matInput 
                                      formControlName="lastNameBene">
                                    </mat-form-field>
                                  </div>
                                  <div class="col-12 col-sm-4 question-id-text">
                                    <mat-form-field class="id-input-fld">
                                      <mat-label>Date of Birth</mat-label>
                                      <input  
                                        [matDatepicker]="beneDatepicker"
                                        [min]="minDate"
                                        [max]="maxDate"
                                        startView="multi-years"
                                        formControlName="birthBene"
                                        matInput>
                                      <mat-datepicker-toggle 
                                        [for]="beneDatepicker"
                                        matSuffix>
                                      </mat-datepicker-toggle>
                                      <mat-datepicker 
                                        #beneDatepicker 
                                        >
                                      </mat-datepicker>
                                    </mat-form-field>
                                  </div>
                                </div>
                                <!--  FORM ENDS-->  
      
                              </div>
                              <!-- NAME FORM ENDS -->

                              <!-- ADD/REMOVE BUTTON -->
                              <div class="row bene-btn-row border-table-top">
                                <div class="col-4 beneText"></div>
                                <div class="col-4" *ngIf="i == 0"></div>
                                <div class="col-4 beneBtn-hld" *ngIf="i>0"
                                  (click)="removeBeneClick(i)">
                                  <div class="beneBtn">
                                    REMOVE BENEFICIARY
                                  </div>
                                </div>
                                <div class="col-4 beneBtn-hld"
                                  (click)="addBeneClick()">
                                  <div class="beneBtn">
                                    ADD BENEFICIARY
                                  </div>
                                </div>
                              </div>
                              <!-- ADD/REMOVE BUTTON ENDS -->

                            </div>
                          </ng-container>
                        </form>
                        <!-- Beneficiary Group Ends -->

                        <!-- End spacer -->
                        <div class="col-12 spacer-md"></div>
                        
                        <!-- Default Content -->

                      </mat-expansion-panel>
                      <!-- ??? BENEFICIARY ENDS-->

                      <!-- ??? POLICY OWNER-->
                      <mat-expansion-panel 
                      [expanded]="step === 8" 
                      (opened)="setStep(8)" 
                      hideToggle>
                        <mat-expansion-panel-header style="height: 100%;">
                          <mat-panel-title>
                            Policy Owner
                          </mat-panel-title>
                          <mat-panel-description>
                            &nbsp;
                            <mat-icon *ngIf="!step8">help</mat-icon>
                            <mat-icon *ngIf="step8" class="icon-check">check_circle</mat-icon>
                          </mat-panel-description>
                        </mat-expansion-panel-header>
                        
                        <!-- POLICY Q1 -->
                        <div class="col-12 question">
                          <form [formGroup]="questionSetPolicy"  style="width: 100%;">
                            <div class="row">
                              <!-- Title -->
                              <div class="col-12 col-sm-9 question-text">
                                Who is the policy owner?
                              </div>
                              <!--  -->

                              <!-- Buttons -->
                              <div class="col-12 col-sm-3 question-btn-hld">
                                <!-- <mat-radio-group> -->
                                  <div class="col-6 question-btn qYes">
                                    <mat-radio-group formControlName="current" name="current">
                                      <mat-radio-button  value="insured" labelPosition="before">
                                        Insured
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                  <div class="col-6 question-btn qNo">
                                    <mat-radio-group formControlName="current" name="current">
                                      <mat-radio-button  value="other" labelPosition="before">
                                        Other
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                <!-- </mat-radio-group> -->
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <!-- POLICY Q1 ENDS -->

                        <!-- POLICY OTHER TITLE -->
                        <div class="col-12 question" *ngIf="questionSetPolicy.get('current').value  === 'other'">
                          <form [formGroup]="questionSetPolicy"  style="width: 100%;">
                            <div class="row question-title">
                              <!-- Title -->
                              <div class="col-12 col-sm-9 question-text">
                                Please provide the policy owner's information.                              
                              </div>
                              <!--  -->

                              <!-- Buttons -->
                              <div class="col-12 col-sm-3 question-btn-hld">
                                <!-- <mat-radio-group> -->
                                  <div class="col-6 question-btn qYes">
                                  </div>
                                  <div class="col-6 question-btn qNo">
                                  </div>
                                <!-- </mat-radio-group> -->
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <!-- POLICY OTHER TITLE ENDS-->

                        <!-- IDENTIFICATION TITLE -->
                        <div class="col-12 question-sub-title" *ngIf="questionSetPolicy.get('current').value  === 'other'">
                          <div class="col-12 question-title-text">Identification</div>
                        </div>
                        <!-- IDENTIFICATION FORM -->                        
                        <form [formGroup]="questionSetPolicy"  *ngIf="questionSetPolicy.get('current').value  === 'other'"
                          style="width: 100%;">
                          <!-- Name -->
                          <div class="col-12 question-sub-forms">
                            <div class="col-12 col-sm-4 question-id-text">
                              <mat-form-field class="id-input-fld">
                                <mat-label>First Name</mat-label>
                                <input matInput 
                                formControlName="firstName">
                              </mat-form-field>
                            </div>
                            <div class="col-12 col-sm-4 question-id-text ">
                              <mat-form-field class="id-input-fld">
                                <mat-label>Middle Name</mat-label>
                                <input matInput 
                                formControlName="middleName">
                              </mat-form-field>
                            </div>
                            <div class="col-12 col-sm-4 question-id-text">
                              <mat-form-field class="id-input-fld">
                                <mat-label>Last Name</mat-label>
                                <input matInput 
                                formControlName="lastName">
                              </mat-form-field>
                            </div>
                          </div>
                          <!-- ** Name ends-->

                          
                          <!-- ID/SIN -->
                          <div class="col-12 question-sub-forms">
                            <!-- relationship -->
                            <div class="col-12 col-sm-4 question-id-text">
                              <mat-form-field class="id-input-fld">
                                <mat-label>Relationship to Insured</mat-label>
                                <mat-select formControlName="relation">
                                  <mat-option value="default" disabled>Assignee...</mat-option>
                                  <mat-option value="Aunt">Aunt</mat-option>
                                  <mat-option value="Brother">Brother</mat-option>
                                  <mat-option value="Brother-in-Law">Brother-in-Law</mat-option>
                                  <mat-option value="Business">Business</mat-option>
                                  <mat-option value="Common-Law-Husband">Common-Law-Husband</mat-option>
                                  <mat-option value="Common-Law-Wife">Common-Law-Wife</mat-option>
                                  <mat-option value="Cousin">Cousin</mat-option>
                                  <mat-option value="Daughter">Daughter</mat-option>
                                  <mat-option value="Daughter-in-Law">Daughter-in-Law</mat-option>
                                  <mat-option value="Employer">Employer</mat-option>
                                  <mat-option value="Father">Father</mat-option>
                                  <mat-option value="Father-In-Law">Father-In-Law</mat-option>
                                  <mat-option value="Fiancé">Fiancé</mat-option>
                                  <mat-option value="Friend">Friend</mat-option>
                                  <mat-option value="Goddaughter">Goddaughter</mat-option>
                                  <mat-option value="Godmother">Godmother</mat-option>
                                  <mat-option value="Godson">Godson</mat-option>
                                  <mat-option value="Grand-Niece">Grand-Niece</mat-option>
                                  <mat-option value="Granddaughter">Granddaughter</mat-option>
                                  <mat-option value="Grandfather">Grandfather</mat-option>
                                  <mat-option value="Grandmother">Grandmother</mat-option>
                                  <mat-option value="Grandson">Grandson</mat-option>
                                  <mat-option value="Great-Grandfather">Great-Grandfather</mat-option>
                                  <mat-option value="Guardian">Guardian</mat-option>
                                  <mat-option value="Husband">Husband</mat-option>
                                  <mat-option value="Mortuary">Mortuary</mat-option>
                                  <mat-option value="Mother">Mother</mat-option>
                                  <mat-option value="Mother-in-Law">Mother-in-Law</mat-option>
                                  <mat-option value="Nephew">Nephew</mat-option>
                                  <mat-option value="Niece">Niece</mat-option>
                                  <mat-option value="Other">Other</mat-option>
                                  <mat-option value="President">President</mat-option>
                                  <mat-option value="Self">Self</mat-option>
                                  <mat-option value="Shareholder">Shareholder</mat-option>
                                  <mat-option value="Sister">Sister</mat-option>
                                  <mat-option value="Sister-in-Law">Sister-in-Law</mat-option>
                                  <mat-option value="Son">Son</mat-option>
                                  <mat-option value="Son-in-Law">Son-in-Law </mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                            <!-- Country of Birth -->
                            <div class="col-12 col-sm-4 question-id-text"></div>
                            <!-- SIN# -->
                            <div class="col-12 col-sm-4 question-id-text">
                              <mat-form-field class="id-input-fld">
                                <mat-label>SIN Number</mat-label>
                                <input matInput 
                                formControlName="sin">
                              </mat-form-field>
                            </div>

                          </div>
                          <!-- ** ID/SIN  ends -->
                          <!-- ID Info -->
                          <div class="col-12 question-sub-forms">
                            <!-- citizen -->
                            <div class="col-12 col-sm-4 question-id-text">
                              <mat-form-field class="id-input-fld">
                                <mat-label>Insured's ID type</mat-label>
                                <input matInput 
                                formControlName="idType">
                              </mat-form-field>
                            </div>
                            <!-- Country of Birth -->
                            <div class="col-12 col-sm-4 question-id-text">
                              <mat-form-field class="id-input-fld">
                                <mat-label>ID Number</mat-label>
                                <input matInput 
                                formControlName="idNum">
                              </mat-form-field>
                            </div>
                            <!-- SIN# -->
                            <div class="col-12 col-sm-4 question-id-text">
                              <mat-form-field class="id-input-fld">
                                <mat-label>Province/Territory of issue</mat-label>
                                <input matInput 
                                formControlName="idProvince">
                              </mat-form-field>
                            </div>

                          </div>
                          <!-- ** ID Info ends -->
                          <!-- Expiry -->
                          <div class="col-12 question-sub-forms">
                            <!-- Expiry -->
                            <div class="col-12 col-sm-4 question-id-text ">
                              <mat-form-field class="id-input-fld">
                                <mat-label>Expiry date</mat-label>
                                <input matInput 
                                formControlName="idExpiry">
                              </mat-form-field>
                            </div>
                          </div>
                          <!-- Expiry ends-->

                        </form>
                        <!-- IDENTIFICATION FORM ENDS --> 

                        <!-- ADDRESS TITLE -->
                        <div class="col-12 question-sub-title" *ngIf="questionSetPolicy.get('current').value  === 'other'">
                          <div class="col-12 question-title-text" *ngIf="true">Address</div>
                        </div>
                        <!-- ADDRESS FORM -->
                        <form [formGroup]="questionSetPolicy" *ngIf="questionSetPolicy.get('current').value  === 'other'">
                          <!-- Address type -->
                          <div class="col-12 question-sub-forms">
                            <div class="col-12 col-sm-4 question-id-text">
                              <mat-form-field class="id-input-fld">
                                <mat-label>Address Type</mat-label>
                                <mat-select
                                  formControlName="addressType">
                                    <mat-option value="default" disabled>Select...</mat-option>
                                    <mat-option value="house">House</mat-option>
                                    <mat-option value="town house">Town House</mat-option>
                                    <mat-option value="condo">Condo</mat-option>
                                    <mat-option value="apt">Apartment</mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                          </div>
                          <!-- ** Address type ends -->
                          <!-- Address  -->
                          <div class="col-12 question-sub-forms">
                            <div class="col-12 question-id-text">
                              <mat-form-field class="id-input-fld">
                                <mat-label>Address</mat-label>
                                <input matInput 
                                formControlName="address"
                                ngx-google-places-autocomplete
                                (onAddressChange)="handleAddressChange($event)"
                                autocomplete="new-password">
                              </mat-form-field>
                            </div>
                          </div>
                          <!-- ** Address ends -->
                          <!-- Postals  -->
                          <div class="col-12 question-sub-forms">
                            <div class="col-12 col-sm-4 question-id-text">
                              <mat-form-field class="id-input-fld">
                                <mat-label>Address/Unit</mat-label>
                                <input matInput 
                                formControlName="unit">
                              </mat-form-field>
                            </div>
                            <div class="col-12 col-sm-4 question-id-text">
                              <mat-form-field class="id-input-fld">
                                <mat-label>City</mat-label>
                                <input matInput 
                                formControlName="city">
                              </mat-form-field>
                            </div>
                            <div class="col-12 col-sm-4 question-id-text">
                              <mat-form-field class="id-input-fld">
                                <mat-label>Postal</mat-label>
                                <input matInput 
                                formControlName="postal">
                              </mat-form-field>
                            </div>
                          </div>
                          <!-- ** Address ends -->
                        </form>
                        <!-- ADDRESS FORM ENDS-->

                        <!-- End spacer -->
                        <div class="col-12 spacer-md"></div>
                        
                        <!-- Default Content -->

                      </mat-expansion-panel>
                      <!-- ? POLICY OWNER ENDS -->

                      <!-- ??? HISTORY -->
                      <mat-expansion-panel 
                      [expanded]="step === 9" 
                      (opened)="setStep(9)" 
                      hideToggle>
                        <mat-expansion-panel-header style="height: 100%;">
                          <mat-panel-title>
                            Insurance History
                          </mat-panel-title>
                          <mat-panel-description>
                            &nbsp;
                            <mat-icon *ngIf="!step9">help</mat-icon>
                            <mat-icon *ngIf="step9" class="icon-check">check_circle</mat-icon>
                          </mat-panel-description>
                        </mat-expansion-panel-header>

                        <!-- Replacement TITLE -->
                        <div class="col-12 question-sub-title">
                            <div class="col-12 question-title-text">Replacement?</div>
                        </div>
                        
                        <!-- HISTORY Q1 -->
                        <div class="col-12 question">
                          <form [formGroup]="questionSetHistory"  style="width: 100%;">
                            <div class="row">
                              <!-- Title -->
                              <div class="col-12 col-sm-9 question-text">
                                Will premiums be stopped, or coverage be reduced or discontinued on any existing life insurance coverage or annuity if the insurance applied for in this application is issued?                              
                              </div>
                              <!--  -->

                              <!-- Buttons -->
                              <div class="col-12 col-sm-3 question-btn-hld">
                                <!-- <mat-radio-group> -->
                                  <div class="col-6 question-btn qYes">
                                    <mat-radio-group formControlName="historyQ1" name="historyQ1">
                                      <mat-radio-button  value="yes" labelPosition="before">
                                        Yes
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                  <div class="col-6 question-btn qNo">
                                    <mat-radio-group formControlName="historyQ1" name="historyQ1">
                                      <mat-radio-button  value="no" labelPosition="before">
                                        No
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                <!-- </mat-radio-group> -->
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <!-- HISTORY Q1 ENDS -->

                        <!-- HISTORY Q1 pop up -->
                          <form [formGroup]="questionSetHistory" *ngIf="questionSetHistory.get('historyQ1').value === 'yes'">
                            <!-- Pop up -->
                            <div class="col-12 question-sub-forms">
                              <div class="col-12 col-sm-4 question-id-text">
                                <mat-form-field class="id-input-fld">
                                  <mat-label>Company</mat-label>
                                  <input matInput 
                                  formControlName="hCompany">
                                </mat-form-field>
                              </div>
                              <div class="col-12 col-sm-4 question-id-text ">
                                <mat-form-field class="id-input-fld">
                                  <mat-label>Amount</mat-label>
                                  <input matInput 
                                  formControlName="hAmount">
                                </mat-form-field>
                              </div>
                              <div class="col-12 col-sm-4 question-id-text">
                                <mat-form-field class="id-input-fld">
                                  <mat-label>Plan</mat-label>
                                  <input matInput 
                                  formControlName="hPlan">
                                </mat-form-field>
                              </div>
                            </div>
                            <!-- ** Name ends-->
                          </form>
                        <!-- HISTORY Q1 pop ups ends -->

                        <!-- Replacement TITLE -->
                        <div class="col-12 question-sub-title">
                          <div class="col-12 question-title-text">Simultaneous Issuance</div>
                        </div>
                        
                        <!-- HISTORY Q1 -->
                        <div class="col-12 question">
                          <form [formGroup]="questionSetHistory"  style="width: 100%;">
                            <div class="row">
                              <!-- Title -->
                              <div class="col-12 col-sm-9 question-text">
                                Are there other insurance applications to issue at the same date of this application (for family members, business partners, etc.)?                              
                              </div>
                              <!--  -->

                              <!-- Buttons -->
                              <div class="col-12 col-sm-3 question-btn-hld">
                                <!-- <mat-radio-group> -->
                                  <div class="col-6 question-btn qYes">
                                    <mat-radio-group formControlName="sameDate" name="sameDate">
                                      <mat-radio-button  value="yes" labelPosition="before">
                                        Yes
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                  <div class="col-6 question-btn qNo">
                                    <mat-radio-group formControlName="sameDate" name="sameDate">
                                      <mat-radio-button  value="no" labelPosition="before">
                                        No
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                <!-- </mat-radio-group> -->
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <!-- HISTORY Q1 ENDS -->

                        <!-- HISTORY Q2 ENDS -->
                        <form [formGroup]="questionSetHistory">
                          <!-- Address  -->
                          <div class="col-12 question-sub-forms">
                            <div class="col-12 question-id-text">
                              <mat-form-field class="id-input-fld">
                                <mat-label>Applicant's first and last name</mat-label>
                                <input matInput 
                                formControlName="applicant">
                              </mat-form-field>
                            </div>
                          </div>
                          <!-- ** Address ends -->
                        </form>

                        <!-- End spacer -->
                        <div class="col-12 spacer-md"></div>
                        
                        <!-- Default Content -->

                      </mat-expansion-panel>
                      <!-- ? HISTORY ENDS -->

                      <!-- ??? PAYMENT -->
                      <mat-expansion-panel 
                      [expanded]="step === 10" 
                      (opened)="setStep(10)" 
                      hideToggle>
                        <mat-expansion-panel-header style="height: 100%;">
                          <mat-panel-title>
                            Payment
                          </mat-panel-title>
                          <mat-panel-description>
                            &nbsp;
                            <mat-icon *ngIf="!step10">help</mat-icon>
                            <mat-icon *ngIf="step10" class="icon-check">check_circle</mat-icon>
                          </mat-panel-description>
                        </mat-expansion-panel-header>

                        <!-- PAYMENT Q1 -->
                        <!--  PAYOR TITLE -->
                        <div class="col-12 question-sub-title">
                            <div class="col-12 question-title-text">Payor</div>
                        </div>
                        <!--  PAYOR Q1 -->
                        <div class="col-12 question">
                          <form [formGroup]="questionSetPayment"  style="width: 100%;">
                            <div class="row">
                              <!-- Title -->
                              <div class="col-12 col-sm-7 question-text">
                                Select the type of Payor. 
                              </div>
                              <!--  -->

                              <!-- Buttons -->
                              <div class="col-12 col-sm-5 question-btn-hld">
                                <mat-form-field class="id-input-fld">
                                  <mat-label>Payor</mat-label>
                                  <mat-select formControlName="payor">
                                    <mat-option value="insured">Insured</mat-option>
                                    <mat-option value="policyHld">Policy Holder</mat-option>
                                    <mat-option value="other">Other</mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <!--  PAYOR Q1 ENDS -->
                        <!-- PAYMENT Q1 ENDS -->
                        <!--  -->
                        <!-- PAYMENT Q2 -->
                        <!--  ACCOUNT TYPE Q2 -->
                        <div class="col-12 question">
                          <form [formGroup]="questionSetPayment"  style="width: 100%;">
                            <div class="row">
                              <!-- Title -->
                              <div class="col-12 col-sm-7 question-text">
                                Select the type of account?                              
                              </div>
                              <!--  -->

                              <!-- Buttons -->
                              <div class="col-12 col-sm-5 question-btn-hld">
                                <mat-form-field class="id-input-fld">
                                  <mat-label>Account Holder</mat-label>
                                  <mat-select formControlName="typeAcc">
                                    <mat-option value="personal">Personal Account</mat-option>
                                    <mat-option value="joint">Joint Account</mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <!--  ACCOUNT TYPE Q2 ENDS -->
                        <!-- PAYMENT Q2 ENDS -->
                        <!-- PAYMENT Q3  -->
                        <!-- If Joint Account -->
                        <!-- Question Title -->
                        <div class="col-12 question" *ngIf="questionSetPayment.get('typeAcc').value === 'joint'">
                          <form style="width: 100%;">
                            <div class="row question-title">
                              <!-- Title -->
                              <div class="col-12 col-sm-9 question-text">
                                Please give the full name of account holder.                             
                              
                              </div>
                              <!--  -->

                              <!-- Buttons -->
                              <div class="col-12 col-sm-3 question-btn-hld">
                                <!-- <mat-radio-group> -->
                                  <div class="col-6 question-btn qYes">
                                  </div>
                                  <div class="col-6 question-btn qNo">
                                  </div>
                                <!-- </mat-radio-group> -->
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <form [formGroup]="questionSetPayment" *ngIf="questionSetPayment.get('typeAcc').value === 'joint'">
                          <!-- phone  -->
                          <div class="col-12 question-sub-forms">
                            <div class="col-12 col-sm-6 question-id-text">
                              <mat-form-field class="id-input-fld">
                                <mat-label>First Name</mat-label>
                                <input matInput 
                                formControlName="firstNameAcc">
                              </mat-form-field>
                            </div>
                            <div class="col-12 col-sm-6 question-id-text">
                              <mat-form-field class="id-input-fld">
                                <mat-label>Last Name</mat-label>
                                <input matInput 
                                formControlName="lastNameAcc">
                              </mat-form-field>
                            </div>
                          </div>
                          <!-- ** phone ends -->
                        </form>
                        <!-- If Joint Account ends -->
                        <!-- PAYMENT Q3 ENDS -->

                        <!-- PAYMENT Q3 -->
                        <!--  PAYMENT TITLE -->
                        <div class="col-12 question-sub-title">
                          <div class="col-12 question-title-text">Payment</div>
                        </div>
                        <!--  PAYMENT BANK PICS -->
                        <div class="col-12 question-sub-title">
                          <div class="col-12 question-title-text">...Bank Pics Here...</div>
                        </div>
                        <!--  PAYMENT MONTHLY DISPLAY -->
                        <div class="col-12 question-sub-title">
                          <div class="col-12 question-title-text">...Monthly Display Here...</div>
                        </div>
                        <!-- PAYMENT INFO -->
                        <form [formGroup]="questionSetBank">
                          <!-- Address type -->
                          <div class="col-12 question-sub-forms">
                            <div class="col-12 col-sm-4 question-id-text">
                              <mat-form-field class="id-input-fld">
                                <mat-label>Account Type</mat-label>
                                <mat-select formControlName="typeAcc">
                                  <mat-option value="Chequing">Chequing</mat-option>
                                  <mat-option value="Savings">Savings</mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                          </div>
                          <!-- ** Address type ends -->
                          <!-- bankNfo  -->
                          <div class="col-12 question-sub-forms">
                            <div class="col-12 col-sm-4 question-id-text">
                              <mat-form-field class="id-input-fld">
                                <mat-label>Transit Number</mat-label>
                                <input matInput 
                                formControlName="transit"
                                min="5"
                                max="5"
                                >
                              </mat-form-field>
                            </div>
                            <div class="col-12 col-sm-4 question-id-text">
                              <mat-form-field class="id-input-fld">
                                <mat-label>Institution Number</mat-label>
                                <input matInput 
                                formControlName="institution"
                                min="3"
                                max="3">
                              </mat-form-field>
                            </div>
                            <div class="col-12 col-sm-4 question-id-text">
                              <mat-form-field class="id-input-fld">
                                <mat-label>Account Number</mat-label>
                                <input matInput 
                                formControlName="numAcc"
                                min="5"
                                max="15">
                              </mat-form-field>
                            </div>
                          </div>
                          <!-- ** Address ends -->
                        </form>
                        <!-- PRE-AUTH -->
                        <!-- TITLE -->
                        <div class="col-12 question border-table-top">
                          <form style="width: 100%;">
                            <div class="row question-title">
                              <!-- Title -->
                              <div class="col-12 col-sm-9 question-text">
                                Which day of the month do you want premiums debited?                             
                              
                              </div>
                              <!--  -->

                              <!-- Buttons -->
                              <div class="col-12 col-sm-3 question-btn-hld">
                                <!-- <mat-radio-group> -->
                                  <div class="col-6 question-btn qYes">
                                  </div>
                                  <div class="col-6 question-btn qNo">
                                  </div>
                                <!-- </mat-radio-group> -->
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <!-- MONTH -->
                        <div class="col-12 question">
                          <form [formGroup]="questionSetBank"  style="width: 100%;">
                            <div class="row">
                              <!-- Title -->
                              <div class="col-12 col-sm-2 question-text" 
                                style="display:flex; align-items: center;">
                                Payment on the:                              
                              </div>
                              <!--  -->

                              <!-- Buttons -->
                              <div class="col-12 col-sm-10 question-btn-hld">
                                <!-- <mat-radio-group> -->
                                  <div class="col-6 col-sm-2 question-btn qYes">
                                    <mat-form-field class="id-input-fld">
                                      <mat-label>Day</mat-label>
                                      <mat-select formControlName="month">
                                        <mat-option value="1">1</mat-option>
                                        <mat-option value="2">2</mat-option>
                                        <mat-option value="3">3</mat-option>
                                        <mat-option value="4">4</mat-option>
                                        <mat-option value="5">5</mat-option>
                                        <mat-option value="6">6</mat-option>
                                        <mat-option value="7">7</mat-option>
                                        <mat-option value="8">8</mat-option>
                                        <mat-option value="9">9</mat-option>
                                        <mat-option value="10">10</mat-option>
                                        <mat-option value="11">11</mat-option>
                                        <mat-option value="12">12</mat-option>
                                        <mat-option value="13">13</mat-option>
                                        <mat-option value="14">14</mat-option>
                                        <mat-option value="15">15</mat-option>
                                        <mat-option value="16">16</mat-option>
                                        <mat-option value="17">17</mat-option>
                                        <mat-option value="18">18</mat-option>
                                        <mat-option value="19">19</mat-option>
                                        <mat-option value="20">20</mat-option>
                                        <mat-option value="21">21</mat-option>
                                        <mat-option value="22">22</mat-option>
                                        <mat-option value="23">23</mat-option>
                                        <mat-option value="24">24</mat-option>
                                        <mat-option value="25">25</mat-option>
                                        <mat-option value="26">26</mat-option>
                                        <mat-option value="27">27</mat-option>
                                        <mat-option value="28">28</mat-option>
                                      </mat-select>
                                    </mat-form-field>
                                  </div>
                                  <div class="col-6 col-sm-10 question-btn qNo"
                                    style="text-align:left;">
                                    Of Each Month.
                                  </div>
                                <!-- </mat-radio-group> -->
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <!-- PAYMENT Q3 ENDS -->

                        <!-- PAYMENT Q4  -->
                        <!--  PAYMENT TAX -->
                        <div class="col-12 question-sub-title">
                          <div class="col-12 question-title-text">Owner’s International Tax Status</div>
                        </div>
                        <!--  TAX Q5 -->
                        <div class="col-12 question">
                          <form [formGroup]="questionSetBank"  style="width: 100%;">
                            <div class="row">
                              <!-- Title -->
                              <div class="col-12 col-sm-9 question-text">
                                Are you a U.S. Resident for tax purposes, or a U.S. citizen, and/or a resident of another country for tax purposes?
                               
                               
                              </div>
                              <!--  -->

                              <!-- Buttons -->
                              <div class="col-12 col-sm-3 question-btn-hld">
                                <!-- <mat-radio-group> -->
                                  <div class=" col-6 question-btn qNo">
                                    <mat-radio-group formControlName="tax" name="tax">
                                      <mat-radio-button  value="yes" labelPosition="before">
                                        Yes
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                  <div class=" col-6 question-btn qNo">
                                    <mat-radio-group formControlName="tax" name="tax">
                                      <mat-radio-button  value="no" labelPosition="before">
                                        No
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                <!-- </mat-radio-group> -->
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <!-- TITLE TAX YES-->
                        <div class="col-12 question border-table-top" *ngIf="questionSetBank.get('tax').value === 'yes'">
                          <form style="width: 100%;">
                            <div class="row question-title">
                              <!-- Title -->
                              <div class="col-12 col-sm-9 question-text">
                                If YES, provide U.S. Tax Identification Number and/or Name of Country(ies) and Tax Identification Number(s)                             
                              
                              </div>
                              <!--  -->

                              <!-- Buttons -->
                              <div class="col-12 col-sm-3 question-btn-hld">
                                <!-- <mat-radio-group> -->
                                  <div class="col-6 question-btn qYes">
                                  </div>
                                  <div class="col-6 question-btn qNo">
                                  </div>
                                <!-- </mat-radio-group> -->
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <!-- TAX FORM  -->
                        <form [formGroup]="questionSetBank" *ngIf="questionSetBank.get('tax').value === 'yes'">
                          <!-- tax forms  -->
                          <div class="col-12 question-sub-forms">
                            <div class="col-12 col-sm-6 question-id-text">
                              <mat-form-field class="id-input-fld">
                                <mat-label>Country List</mat-label>
                                <mat-select formControlName="countryList">
                                    <mat-option value="Afghanistan" >Afghanistan</mat-option>
                                    <mat-option value="Albania" >Albania</mat-option>
                                    <mat-option value="Algeria" >Algeria</mat-option>
                                    <mat-option value="American Samoa" >American Samoa</mat-option>
                                    <mat-option value="Andorra" >Andorra</mat-option>
                                    <mat-option value="Angola" >Angola</mat-option>
                                    <mat-option value="Antigua and Barbuda" >Antigua and Barbuda</mat-option>
                                    <mat-option value="Argentina" >Argentina</mat-option>
                                    <mat-option value="Armenia" >Armenia</mat-option>
                                    <mat-option value="Aruba" >Aruba</mat-option>
                                    <mat-option value="Australia" >Australia</mat-option>
                                    <mat-option value="Austria" >Austria</mat-option>
                                    <mat-option value="Azerbaijan" >Azerbaijan</mat-option>
                                    <mat-option value="Bahamas" >Bahamas</mat-option>
                                    <mat-option value="Bahrain" >Bahrain</mat-option>
                                    <mat-option value="Bangladesh" >Bangladesh</mat-option>
                                    <mat-option value="Barbados" >Barbados</mat-option>
                                    <mat-option value="Belarus" >Belarus</mat-option>
                                    <mat-option value="Belgium" >Belgium</mat-option>
                                    <mat-option value="Belize" >Belize</mat-option>
                                    <mat-option value="Benin" >Benin</mat-option>
                                    <mat-option value="Bermuda" >Bermuda</mat-option>
                                    <mat-option value="Bhutan" >Bhutan</mat-option>
                                    <mat-option value="Bolivia" >Bolivia</mat-option>
                                    <mat-option value="Bonaire" >Bonaire</mat-option>
                                    <mat-option value="Bosnia and Herzegovina" >Bosnia and Herzegovina</mat-option>
                                    <mat-option value="Botswana" >Botswana</mat-option>
                                    <mat-option value="Brazil" >Brazil</mat-option>
                                    <mat-option value="British Virgin Islands" >British Virgin Islands</mat-option>
                                    <mat-option value="Brunei" >Brunei</mat-option>
                                    <mat-option value="Bulgaria" >Bulgaria</mat-option>
                                    <mat-option value="Burkina Faso" >Burkina Faso</mat-option>
                                    <mat-option value="Burundi" >Burundi</mat-option>
                                    <mat-option value="Cambodia" >Cambodia</mat-option>
                                    <mat-option value="Cameroon" >Cameroon</mat-option>
                                    <mat-option value="Canada" >Canada</mat-option>
                                    <mat-option value="Cape Verde" >Cape Verde</mat-option>
                                    <mat-option value="Cayman Islands" >Cayman Islands</mat-option>
                                    <mat-option value="Central African Republic" >Central African Republic</mat-option>
                                    <mat-option value="Chad" >Chad</mat-option>
                                    <mat-option value="Chile" >Chile</mat-option>
                                    <mat-option value="China" >China</mat-option>
                                    <mat-option value="China (Hong Kong)" >China (Hong Kong)</mat-option>
                                    <mat-option value="Colombia" >Colombia</mat-option>
                                    <mat-option value="Comoros" >Comoros</mat-option>
                                    <mat-option value="Costa Rica" >Costa Rica</mat-option>
                                    <mat-option value="Croatia" >Croatia</mat-option>
                                    <mat-option value="Cuba" >Cuba</mat-option>
                                    <mat-option value="Curacao" >Curacao</mat-option>
                                    <mat-option value="Cyprus" >Cyprus</mat-option>
                                    <mat-option value="Czech Republic" >Czech Republic</mat-option>
                                    <mat-option value="Democratic Republic of the Congo" >Democratic Republic of the Congo</mat-option>
                                    <mat-option value="Denmark" >Denmark</mat-option>
                                    <mat-option value="Djibouti" >Djibouti</mat-option>
                                    <mat-option value="Dominica" >Dominica</mat-option>
                                    <mat-option value="Dominican Republic" >Dominican Republic</mat-option>
                                    <mat-option value="East Timor" >East Timor</mat-option>
                                    <mat-option value="Ecuador" >Ecuador</mat-option>
                                    <mat-option value="Egypt" >Egypt</mat-option>
                                    <mat-option value="El Salvador" >El Salvador</mat-option>
                                    <mat-option value="Equatorial Guinea" >Equatorial Guinea</mat-option>
                                    <mat-option value="Eritrea" >Eritrea</mat-option>
                                    <mat-option value="Estonia" >Estonia</mat-option>
                                    <mat-option value="Ethiopia" >Ethiopia</mat-option>
                                    <mat-option value="Falkland Islands" >Falkland Islands</mat-option>
                                    <mat-option value="Fiji" >Fiji</mat-option>
                                    <mat-option value="Finland" >Finland</mat-option>
                                    <mat-option value="France" >France</mat-option>
                                    <mat-option value="Gabon" >Gabon</mat-option>
                                    <mat-option value="Gambia" >Gambia</mat-option>
                                    <mat-option value="Georgia" >Georgia</mat-option>
                                    <mat-option value="Germany" >Germany</mat-option>
                                    <mat-option value="Ghana" >Ghana</mat-option>
                                    <mat-option value="Greece" >Greece</mat-option>
                                    <mat-option value="Greenland" >Greenland</mat-option>
                                    <mat-option value="Grenada" >Grenada</mat-option>
                                    <mat-option value="Guatemala" >Guatemala</mat-option>
                                    <mat-option value="Guernsey" >Guernsey</mat-option>
                                    <mat-option value="Guinea" >Guinea</mat-option>
                                    <mat-option value="Guinea-Bissau" >Guinea-Bissau</mat-option>
                                    <mat-option value="Guyana" >Guyana</mat-option>
                                    <mat-option value="Haiti" >Haiti</mat-option>
                                    <mat-option value="Honduras" >Honduras</mat-option>
                                    <mat-option value="Hungary" >Hungary</mat-option>
                                    <mat-option value="Iceland" >Iceland</mat-option>
                                    <mat-option value="India" >India</mat-option>
                                    <mat-option value="Indonesia" >Indonesia</mat-option>
                                    <mat-option value="Iran" >Iran</mat-option>
                                    <mat-option value="Iraq" >Iraq</mat-option>
                                    <mat-option value="Ireland" >Ireland</mat-option>
                                    <mat-option value="Israel" >Israel</mat-option>
                                    <mat-option value="Italy" >Italy</mat-option>
                                    <mat-option value="Ivory Coast" >Ivory Coast</mat-option>
                                    <mat-option value="Jamaica" >Jamaica</mat-option>
                                    <mat-option value="Japan" >Japan</mat-option>
                                    <mat-option value="Jordan" >Jordan</mat-option>
                                    <mat-option value="Kazakhstan" >Kazakhstan</mat-option>
                                    <mat-option value="Kenya" >Kenya</mat-option>
                                    <mat-option value="Kiribati" >Kiribati</mat-option>
                                    <mat-option value="Kosovo" >Kosovo</mat-option>
                                    <mat-option value="Kuwait" >Kuwait</mat-option>
                                    <mat-option value="Kyrgyzstan" >Kyrgyzstan</mat-option>
                                    <mat-option value="Laos" >Laos</mat-option>
                                    <mat-option value="Latvia" >Latvia</mat-option>
                                    <mat-option value="Lebanon" >Lebanon</mat-option>
                                    <mat-option value="Lesotho" >Lesotho</mat-option>
                                    <mat-option value="Liberia" >Liberia</mat-option>
                                    <mat-option value="Libya" >Libya</mat-option>
                                    <mat-option value="Liechtenstein" >Liechtenstein</mat-option>
                                    <mat-option value="Lithuania" >Lithuania</mat-option>
                                    <mat-option value="Luxembourg" >Luxembourg</mat-option>
                                    <mat-option value="Macao" >Macao</mat-option>
                                    <mat-option value="Macedonia" >Macedonia</mat-option>
                                    <mat-option value="Madagascar" >Madagascar</mat-option>
                                    <mat-option value="Malawi" >Malawi</mat-option>
                                    <mat-option value="Malaysia" >Malaysia</mat-option>
                                    <mat-option value="Maldives" >Maldives</mat-option>
                                    <mat-option value="Mali" >Mali</mat-option>
                                    <mat-option value="Malta" >Malta</mat-option>
                                    <mat-option value="Marshall Islands" >Marshall Islands</mat-option>
                                    <mat-option value="Mauritania" >Mauritania</mat-option>
                                    <mat-option value="Mauritius" >Mauritius</mat-option>
                                    <mat-option value="Mexico" >Mexico</mat-option>
                                    <mat-option value="Micronesia" >Micronesia</mat-option>
                                    <mat-option value="Moldova" >Moldova</mat-option>
                                    <mat-option value="Monaco" >Monaco</mat-option>
                                    <mat-option value="Mongolia" >Mongolia</mat-option>
                                    <mat-option value="Montenegro" >Montenegro</mat-option>
                                    <mat-option value="Montserrat" >Montserrat</mat-option>
                                    <mat-option value="Morocco" >Morocco</mat-option>
                                    <mat-option value="Mozambique" >Mozambique</mat-option>
                                    <mat-option value="Myanmar" >Myanmar</mat-option>
                                    <mat-option value="Namibia" >Namibia</mat-option>
                                    <mat-option value="Nauru" >Nauru</mat-option>
                                    <mat-option value="Nepal" >Nepal</mat-option>
                                    <mat-option value="Netherlands" >Netherlands</mat-option>
                                    <mat-option value="New Caledonia" >New Caledonia</mat-option>
                                    <mat-option value="New Zealand" >New Zealand</mat-option>
                                    <mat-option value="Nicaragua" >Nicaragua</mat-option>
                                    <mat-option value="Niger" >Niger</mat-option>
                                    <mat-option value="Nigeria" >Nigeria</mat-option>
                                    <mat-option value="North Korea" >North Korea</mat-option>
                                    <mat-option value="Northern Mariana Islands" >Northern Mariana Islands</mat-option>
                                    <mat-option value="Norway" >Norway</mat-option>
                                    <mat-option value="Oman" >Oman</mat-option>
                                    <mat-option value="Pakistan" >Pakistan</mat-option>
                                    <mat-option value="Palau" >Palau</mat-option>
                                    <mat-option value="Palestine" >Palestine</mat-option>
                                    <mat-option value="Panama" >Panama</mat-option>
                                    <mat-option value="Papua New Guinea" >Papua New Guinea</mat-option>
                                    <mat-option value="Paraguay" >Paraguay</mat-option>
                                    <mat-option value="Peru" >Peru</mat-option>
                                    <mat-option value="Philippines" >Philippines</mat-option>
                                    <mat-option value="Poland" >Poland</mat-option>
                                    <mat-option value="Portugal" >Portugal</mat-option>
                                    <mat-option value="Puerto Rico" >Puerto Rico</mat-option>
                                    <mat-option value="Qatar" >Qatar</mat-option>
                                    <mat-option value="Republic of the Congo" >Republic of the Congo</mat-option>
                                    <mat-option value="Romania" >Romania</mat-option>
                                    <mat-option value="Russia" >Russia</mat-option>
                                    <mat-option value="Rwanda" >Rwanda</mat-option>
                                    <mat-option value="Saint Kitts and Nevis" >Saint Kitts and Nevis</mat-option>
                                    <mat-option value="Saint Lucia" >Saint Lucia</mat-option>
                                    <mat-option value="Saint Vincent and the Grenadines" >Saint Vincent and the Grenadines</mat-option>
                                    <mat-option value="Samoa" >Samoa</mat-option>
                                    <mat-option value="San Marino" >San Marino</mat-option>
                                    <mat-option value="Sao Tome and Principe" >Sao Tome and Principe</mat-option>
                                    <mat-option value="Saudi Arabia" >Saudi Arabia</mat-option>
                                    <mat-option value="Senegal" >Senegal</mat-option>
                                    <mat-option value="Serbia" >Serbia</mat-option>
                                    <mat-option value="Seychelles" >Seychelles</mat-option>
                                    <mat-option value="Sierra Leone" >Sierra Leone</mat-option>
                                    <mat-option value="Singapore" >Singapore</mat-option>
                                    <mat-option value="Slovakia" >Slovakia</mat-option>
                                    <mat-option value="Slovenia" >Slovenia</mat-option>
                                    <mat-option value="Solomon Islands" >Solomon Islands</mat-option>
                                    <mat-option value="Somalia" >Somalia</mat-option>
                                    <mat-option value="South Africa" >South Africa</mat-option>
                                    <mat-option value="South Korea" >South Korea</mat-option>
                                    <mat-option value="South Sudan" >South Sudan</mat-option>
                                    <mat-option value="Spain" >Spain</mat-option>
                                    <mat-option value="Sri Lanka" >Sri Lanka</mat-option>
                                    <mat-option value="Sudan" >Sudan</mat-option>
                                    <mat-option value="Suriname" >Suriname</mat-option>
                                    <mat-option value="Swaziland" >Swaziland</mat-option>
                                    <mat-option value="Sweden" >Sweden</mat-option>
                                    <mat-option value="Switzerland" >Switzerland</mat-option>
                                    <mat-option value="Syria" >Syria</mat-option>
                                    <mat-option value="Taiwan" >Taiwan</mat-option>
                                    <mat-option value="Tajikistan" >Tajikistan</mat-option>
                                    <mat-option value="Tanzania" >Tanzania</mat-option>
                                    <mat-option value="Thailand" >Thailand</mat-option>
                                    <mat-option value="Togo" >Togo</mat-option>
                                    <mat-option value="Tonga" >Tonga</mat-option>
                                    <mat-option value="Trinidad and Tobago" >Trinidad and Tobago</mat-option>
                                    <mat-option value="Tunisia" >Tunisia</mat-option>
                                    <mat-option value="Turkey" >Turkey</mat-option>
                                    <mat-option value="Turkmenistan" >Turkmenistan</mat-option>
                                    <mat-option value="Turks and Caicos Islands" >Turks and Caicos Islands</mat-option>
                                    <mat-option value="Tuvalu" >Tuvalu</mat-option>
                                    <mat-option value="Uganda" >Uganda</mat-option>
                                    <mat-option value="Ukraine" >Ukraine</mat-option>
                                    <mat-option value="United Arab Emirates" >United Arab Emirates</mat-option>
                                    <mat-option value="United Kingdom" >United Kingdom</mat-option>
                                    <mat-option value="United States" >United States</mat-option>
                                    <mat-option value="Uruguay" >Uruguay</mat-option>
                                    <mat-option value="Uzbekistan" >Uzbekistan</mat-option>
                                    <mat-option value="Vanuatu" >Vanuatu</mat-option>
                                    <mat-option value="Vatican City" >Vatican City</mat-option>
                                    <mat-option value="Venezuela" >Venezuela</mat-option>
                                    <mat-option value="Vietnam" >Vietnam</mat-option>
                                    <mat-option value="Yemen" >Yemen</mat-option>
                                    <mat-option value="Zambia" >Zambia</mat-option>
                                    <mat-option value="Zimbabwe" >Zimbabwe</mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                            <div class="col-12 col-sm-6 question-id-text">
                              <mat-form-field class="id-input-fld">
                                <mat-label>Tax ID Number</mat-label>
                                <input matInput 
                                formControlName="taxId">
                              </mat-form-field>
                            </div>
                          </div>
                          <!-- ** tax forms ends -->
                        </form>
                        <!-- PAYMENT Q5 ENDS -->

                        <!-- End spacer -->
                        <div class="col-12 spacer-md"></div>
                        
                        <!-- Default Content -->

                      </mat-expansion-panel>
                      <!-- ? PAYMENT ENDS -->

                      <!-- ??? DECLARATION -->
                      <mat-expansion-panel 
                      [expanded]="step === 11" 
                      (opened)="setStep(11)" 
                      hideToggle>
                        <mat-expansion-panel-header style="height: 100%;">
                          <mat-panel-title>
                            Declarations
                          </mat-panel-title>
                          <mat-panel-description>
                            &nbsp;
                            <mat-icon *ngIf="!step11">help</mat-icon>
                            <mat-icon *ngIf="step11" class="icon-check">check_circle</mat-icon>
                          </mat-panel-description>
                        </mat-expansion-panel-header>

                        <!-- DEC Q1 -->
                        <!-- THIRD TITLE -->
                        <div class="col-12 question-sub-title">
                            <div class="col-12 question-title-text">Third Party Determination</div>
                        </div>
                        <!-- DECLARATIONS THIRD Q1 -->
                        <div class="col-12 question">
                          <form [formGroup]="questionSetDec"  style="width: 100%;">
                            <div class="row">
                              <!-- Title -->
                              <div class="col-12 col-sm-9 question-text">
                                Is a third party involved with this application for insurance, or will a third party have the use of, or access to, the cash value of the policy?                              
                              </div>
                              <!--  -->

                              <!-- Buttons -->
                              <div class="col-12 col-sm-3 question-btn-hld">
                                <!-- <mat-radio-group> -->
                                  <div class="col-6 question-btn qYes">
                                    <mat-radio-group formControlName="third" name="third">
                                      <mat-radio-button  value="yes" labelPosition="before">
                                        Yes
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                  <div class="col-6 question-btn qNo">
                                    <mat-radio-group formControlName="third" name="third">
                                      <mat-radio-button  value="no" labelPosition="before">
                                        No
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                <!-- </mat-radio-group> -->
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <!-- DECLARATIONS THIRD Q1 ENDS -->
                        <!-- IF YES TITLE -->
                        <div class="col-12 question" *ngIf="questionSetDec.get('third').value === 'yes'">
                          <form [formGroup]="questionSetB"  style="width: 100%;">
                            <div class="row question-title">
                              <!-- Title -->
                              <div class="col-12 col-sm-9 question-text">
                                If YES, complete a separate Third Party Determination form CPP011 for each third party.                              
                              
                              </div>
                              <!--  -->

                              <!-- Buttons -->
                              <div class="col-12 col-sm-3 question-btn-hld">
                                <!-- <mat-radio-group> -->
                                  <div class="col-6 question-btn qYes">
                                  </div>
                                  <div class="col-6 question-btn qNo">
                                  </div>
                                <!-- </mat-radio-group> -->
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <!-- THIRD POP-UP TITLE -->
                        <div class="col-12 question-sub-title" *ngIf="questionSetDec.get('third').value === 'yes'">
                          <div class="col-12 question-title-text">
                            The advisor must complete the Third Party Determination form and submit it via mail or e-mail. If you have any questions please contact Broker Services at 1-877-796-9090 or email sales@cpp.ca.<br>
                            <a href="#">Click to view the Third Party Determination form in a separate window.</a>
                          </div>
                        </div>
                        <!-- ELECTRONIC TITLE -->
                        <div class="col-12 question-sub-title">
                          <div class="col-12 question-title-text">
                            Electronic Policy
                          </div>
                        </div>
                        <!-- ELECTRONIC PAPER -->
                        <div class="col-12 question">
                          <form [formGroup]="questionSetDec"  style="width: 100%;">
                            <div class="row">
                              <!-- Title -->
                              <div class="col-12 col-sm-9 question-text">
                                An electronic policy contract will be issued once the policy has been approved. A paper policy contract option is available.<br><br>
                                In today’s environment, it’s more important than ever to reduce in-person appointments. That’s why we strongly urge you to consider having the policy delivered electronically. You can download it, after which you can print it at your convenience. Your Advisor will be pleased to schedule a time with you to answer all your questions.                              
                              </div>
                              <!--  -->

                              <!-- Buttons -->
                              <div class="col-12 col-sm-3 question-btn-hld">
                                <!-- <mat-radio-group> -->
                                  <div class="col-6 question-btn qYes">
                                  </div>
                                  <div class="col-6 question-btn qNo">
                                  </div>
                                <!-- </mat-radio-group> -->
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <!-- DECLARATIONS THIRD POP-UP TEXT ENDS -->
                        <!-- DECLARATIONS THIRD POP-UP Q1 -->
                        <div class="col-12 question">
                          <form [formGroup]="questionSetDec"  style="width: 100%;">
                            <div class="row">
                              <!-- Title -->
                              <div class="col-12 col-sm-9 question-text">
                                Would you like to receive a paper policy?                              
                              </div>
                              <!--  -->

                              <!-- Buttons -->
                              <div class="col-12 col-sm-3 question-btn-hld">
                                <!-- <mat-radio-group> -->
                                  <div class="col-6 question-btn qYes">
                                    <mat-radio-group formControlName="paper" name="paper">
                                      <mat-radio-button  value="yes" labelPosition="before">
                                        Yes
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                  <div class="col-6 question-btn qNo">
                                    <mat-radio-group formControlName="paper" name="paper">
                                      <mat-radio-button  value="no" labelPosition="before">
                                        No
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                <!-- </mat-radio-group> -->
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <!-- DECLARATIONS THIRD POP-UP Q1 ENDS -->
                        <!-- DEC Q1 ENDS -->

                        <!-- DEC Q2 -->
                        <!-- GENERAL TITLE -->
                        <div class="col-12 question-sub-title">
                          <div class="col-12 question-title-text">GENERAL DECLARATION</div>
                        </div>
                        <!-- DECLARATIONS GENERAL TEXT -->
                        <div class="col-12 question">
                          <p>
                            <br>
                            <a href="../../../assets/pdf/declarations.pdf">Declarations</a><br>
                          </p>
                        </div>
                        <!-- DECLARATIONS PRE-AUTH Q3 -->
                        <div class="col-12 question">
                          <form [formGroup]="questionSetDec"  style="width: 100%;">
                            <div class="row">
                              <!-- Title -->
                              <div class="col-12 col-sm-9 question-text">
                                By selecting Yes, I the proposed life insured, declare that I have reviewed the Canada Protection Plan Application for Life Insurance PDF file link provided above.                              </div>
                              <!--  -->

                              <!-- Buttons -->
                              <div class="col-12 col-sm-3 question-btn-hld">
                                <!-- <mat-radio-group> -->
                                  <div class="col-6 question-btn qYes">
                                    <mat-radio-group formControlName="preAuth" name="preAuth">
                                      <mat-radio-button  value="yes" labelPosition="before">
                                        Yes
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                  <div class="col-6 question-btn qNo">
                                    <mat-radio-group formControlName="preAuth" name="preAuth">
                                      <mat-radio-button  value="no" labelPosition="before">
                                        No
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                <!-- </mat-radio-group> -->
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <!-- DEC Q3 ENDS -->

                        <!-- End spacer -->
                        <div class="col-12 spacer-md"></div>
                        
                        <!-- Default Content -->

                      </mat-expansion-panel>
                      <!-- ? DECLARATION ENDS -->

                    </mat-accordion>
                  </div>
                </div>
                <!-- *** QUESTIONS ENDS -->
                <button
                type="button"
                aria-label="Toggle sidenav"
                mat-raised-button
                (click)="drawer.toggle()">
                View Info
              </button>

              </div>
              <!-- * MAIN CONTENT ENDS -->
              <!-- %% -->


              <!--  -->
            </div>
          </div>
        </mat-sidenav-content>
        <!-- *** CPP APP CONTENT ENDS -->
        <!--  -->

      </mat-sidenav-container>
      <!-- Mat-sidenav-container -->
    </div>
  </div>
</div>
