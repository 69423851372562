import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class QuoteApiService {
  
  // 
  constructor(
    private http: HttpClient 
  ) { } 
  // 

  // 
  getQuote(data, location){
    return this.http.post(
      location,
      data,
      {
        observe: 'response'
      }
    )
  }
  // 
}
