import { Component, OnInit, Input,OnChanges, SimpleChanges, SimpleChange } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnChanges, OnInit {
  @Input() footerColor;
  changeFooterColor = "red";

  constructor() { }

  ngOnInit(): void {
    console.log(`This is in the footer: ${this.footerColor}`);
  }
  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    const currentColor: SimpleChange = changes.footerColor;
    console.log('Maybe this will work?');
    console.log(currentColor.previousValue);
    console.log('Current value');
    console.log(currentColor.currentValue);
    this.changeFooterColor = currentColor.currentValue;
    
  }

}
