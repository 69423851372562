<!-- ### Main Page ################################################### -->
<!-- MAIN CONTENT -->
<div class="main-container">
  <!--  -->


  <!--  -->
  <!-- ++++++++++++++++++++++++++++++++ -->
  <!-- PAGE TITLE AND PROGRESS BAR +++++++++++++++++++++++++++++++++++++++++ -->
  <div class="row justify-content-between align-items-center app-progress"
  [ngStyle]="{'background-image': 'linear-gradient(90deg, #3F51B5  '+pPercent+'%, #F6F6F7 '+pPercent+'%)'}">
    <!-- <div class="col-12 p-bar" style=""></div> -->
    <div class="col-2">
      <div class="step-circle" [class.complete]="true">1</div>
      <div class="spacer-xs"></div>
      <!-- <div class="step-title">Quick Quote</div> -->
    </div>
    <div class="col-2">
      <div class="step-circle" [class.complete]="stepNum >= 3">2</div>
      <div class="spacer-xs"></div>
      <!-- <div class="step-title">Personal Info</div> -->
    </div>
    <div class="col-2">
      <div class="step-circle" [class.complete]="stepNum >= 5">3</div>
      <div class="spacer-xs"></div>
      <!-- <div class="step-title">Eligibility</div> -->
    </div>
    <div class="col-2">
      <div class="step-circle" [class.complete]="stepNum >= 7">4</div>
      <div class="spacer-xs"></div>
      <!-- <div class="step-title">Pay & Policy</div> -->
    </div>
  </div>
  <!--  -->
  <!-- App Progress mobile -->
  <div class="row app-progress-mobile">
    <div class="col-12"><div class="spacer-sm"></div></div>
    <div class="col-1">
      <div class="step-circle complete">1</div>
    </div>
    <div class="col-11">
      <div class="step-title-mobile"><span class="step-of">of 4:  </span>Quick Quote</div>
    </div>
    <div class="col-12">
      <div class="spacer-xs"></div>
    </div>
  </div>   
  <!--  -->
  <style>
  </style>
  <!-- PAGE TITLE AND PROGRESS BAR ENDS +++++++++++++++++++++++++++++++++++++++++ -->
  <!-- ++++++++++++++++++++++++++++++++ -->
  <!--  -->


  <!--  -->
  <!-- Stepper Main Page -->
  <mat-horizontal-stepper linear
  #stepperMainRef
  [selectedIndex]="stepNum"
  (selectionChange)="stepperMain($event)">

    <!-- %% -->
    <!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->
    <!-- !!! NEEDS ANALYSIS !!!  -->
    <!-- <mat-step [completed]="true"> -->
    <mat-step [completed]="true">
      <!--  -->
      <div class="sub-container">
        <!-- mat-card -->
        <mat-card 
        (mouseover)="isActiveToggle(true)"
        (mouseout)="isActiveToggle(false)"
        [class.mat-elevation-z2]="!isActive"
        [class.mat-elevation-z8]="isActive"
        class="section-card card-needs">
          <!-- Card Header -->
          <mat-card-header>
            <mat-card-title>
              <!-- Title -->
              <div class="mat-headline">
                <div class="spacer-sm"></div>
                <span>Needs Analysis.</span>
                <div class="spacer-sm"></div>
              </div>
              <!--  -->
            </mat-card-title>
          </mat-card-header>
          <!-- *** -->
          <mat-card-content>
            <div class="row">
    
              <!-- Picture -->
              <div class="col-4">
                <img src="../../../assets/svg/needs.svg" alt="Insurance Type">
              </div>
              <!--  -->
    
              <!-- Needs Analysis Amounts Output -->
              <div class="col-7">
                <form [formGroup]="quoteForm">
                  <!-- sub title -->
                  <div class="col-12">
                    <div class="mat-title">
                      <mat-icon
                        [matTooltip]="needsToolTipArr['title']">
                        help
                      </mat-icon>
                      <span><b> Need help deciding your coverage?</b></span>
                    </div>                   
                  </div>
                  <!--  -->
                  <div class="col-12 spacer-sm"></div>

                  <!-- Continue or skip buttons -->
                  <div class="col-12">
                    <div class="row">
                      <div class="col-6"> 
                      </div>
                      <div class="col-6">
                        <button  
                        mat-raised-button  
                        color="primary"
                        matStepperNext>
                          Skip
                        </button>
                      </div>
                    </div>
                  </div>
                  <!--  -->
                  <div class="col-12 spacer-sm"></div>

                  <!-- Needs Amounts Output -->
                  <div class="row">
                    <!-- Totals full output -------------------- -->
                    <!-- Cash totals -->
                    <div class="col-12">
                      <div class="row needs-totals totals-cash">
                        <div class="col-12"><hr></div>
                        <div class="col-2 totals-icon" [ngStyle]="{'color':incomeTabBool === true ? '#92c83e' : '' }">
                          <mat-icon>credit_card</mat-icon>
                        </div>
                        <div class="col-7 totals-text">
                          <p>TOTAL CASH NEEDS</p>
                        </div>
                        <div class="col-3 totals-output" [ngStyle]="{'color':incomeTabBool === true ? '#92c83e' : '' }">
                          <p>{{needsForm.get('cashTotals').value | currency}}</p>
                        </div>
                      </div>
                    </div>
                    <!--  -->
                    <!-- Income totals -->
                    <div class="col-12">
                      <div class="row needs-totals totals-income">
                        <div class="col-12"><hr></div>
                        <div class="col-2 totals-icon" [ngStyle]="{'color':assetsTabBool === true ? '#92c83e' : '' }">
                          <mat-icon>work</mat-icon>
                        </div>
                        <div class="col-7 totals-text">
                          <p>INCOME REPLACEMENT</p>
                        </div>
                        <div class="col-3 totals-output" [ngStyle]="{'color':assetsTabBool === true ? '#92c83e' : '' }">
                          <p>{{needsForm.get('incomeTotals').value | currency}}</p>
                        </div>
                      </div>
                    </div>
                    <!--  -->
                    <!-- Assets totals -->
                    <div class="col-12">
                      <div class="row needs-totals totals-assets">
                        <div class="col-12"><hr></div>
                        <div class="col-2 totals-icon" [ngStyle]="{'color':totalsTabBool === true ? '#92c83e' : '' }">
                          <mat-icon>home</mat-icon>
                        </div>
                        <div class="col-7 totals-text">
                          <p>ASSETS & INSURANCE</p>
                        </div>
                        <div class="col-3 totals-output" [ngStyle]="{'color':totalsTabBool === true ? '#92c83e' : '' }">
                          <p>{{needsForm.get('assetsTotals').value | currency}}</p>
                        </div>
                      </div>
                    </div>
                    <!--  -->
                    <!-- All totals -->
                    <div class="col-12">
                      <div class="row needs-totals totals-assets">
                        <div class="col-12"><hr></div>
                        <div class="col-2 totals-icon">
                          <!-- <mat-icon>home</mat-icon> -->
                        </div>
                        <div class="col-7 totals-text" [ngStyle]="{'color':totalsTabBool === true ? '#92c83e' : '' }">
                          <p><b>Total:</b></p>
                        </div>
                        <div class="col-3 totals-output" [ngStyle]="{'color':totalsTabBool === true ? '#92c83e' : '' }">
                          <p>{{needsForm.get('needsTotals').value | currency}}</p>
                        </div>
                      </div>
                    </div>
                    <!--  -->

                  </div>
                </form>
              </div>
              <!--  -->
            </div>
            <!-- right side ends -->

            <!--  -->
            <!-- NEEDS ANALYSIS COMPONENT ------------------------ -->
            <div class="row needs-component">
              <div class="col-12 spacer-sm"></div>
              <div class="col-12 needs-tab-group">
                <mat-tab-group 
                  #needsTabRef
                  [selectedIndex]="tabNum"
                  mat-align-tabs="end">
                  <!--  -->
                  <!-- TAB 1 Cash ::::::: -->
                  <mat-tab label="Cash Needs">
                    <div class="container needs-sub-container cash-container">
                      <div class="row needs-question-row">
                        <div class="col-12 spacer-sm"></div>
                        <!-- // -->
                        <!-- *** MAIN CONTENT TAB CASH-->

                        <!-- Tab title -->
                        <div class="col-12">
                          <div class="spacer-sm"></div>
                          <div class="mat-title">What is your immediate cash needs?</div>
                          <!-- <div class="spacer-sm"></div> -->
                        </div>
                        <div class="col-12 ">
                          <div class="" style="text-align: end; margin-right:20px">
                            <p>Enter amount</p>
                          </div>
                        </div>
                        <!-- *** -->

                        <!--  -->
                        <!-- Question 1 final-->
                        <div class="col-12 needs-question">
                          <mat-card>
                            <mat-card-content>
                              <div class="row">
                                <div class="col-1 needs-question-icon">
                                  <mat-icon
                                  [matTooltip]="needsToolTipArr['final']">
                                    info</mat-icon>
                                </div>
                                <div class="col-7 needs-question-title">
                                  <div class="mat-h3">Final expenses</div>
                                  <div class="mat-body-1">(Cremation $5,000-$10,000. Burial $7,500-$30,000)</div>
                                </div>
                                <div class="col-4 needs-question-input">
    
                                  <!-- question final form -->
                                  <form [formGroup]="needsForm" >
                                    <mat-form-field appearance="outline">
                                      <input 
                                        name="final"
                                        formControlName="final"
                                        type="text"
                                        placeholder="$0.00"
                                        currencyMask
                                        [options]="{prefix:'$', thousands: ',', decimal: '.'}"                                        
                                        matInput>
                                    </mat-form-field>
                                  </form>
                                  <!--  -->
                                </div>
                              </div>
                            </mat-card-content>
                          </mat-card>
                        </div>
                        <div class="col-12 spacer-xs"></div>
                        <!-- Question 1 -->
                        <!--  -->

                        <!--  -->
                        <!-- Question 2 mortgage-->
                        <div class="col-12 needs-question" 
                        *ngIf="needsForm.get('final'). value > 0 || needsForm.get('final'). value != null">
                          <mat-card>
                            <mat-card-content>
                              <div class="row">
                                <div class="col-1 needs-question-icon">
                                  <mat-icon
                                  [matTooltip]="needsToolTipArr['mortgage']">
                                    info</mat-icon>
                                </div>
                                <div class="col-7 needs-question-title">
                                  <div class="mat-h3">Mortgage</div>
                                  <div class="mat-body-1">(Outstanding loan balance)</div>
                                </div>
                                <div class="col-4 needs-question-input">
    
                                  <!-- question mortgage form -->
                                  <form [formGroup]="needsForm" >
                                    <mat-form-field appearance="outline">
                                      <input 
                                        name="mortgage"
                                        formControlName="mortgage"
                                        type="text"
                                        placeholder="$0.00"
                                        currencyMask
                                        [options]="{prefix:'$', thousands: ',', decimal: '.'}"                                        
                                        matInput>
                                    </mat-form-field>
                                  </form>
                                  <!--  -->
                                </div>
                              </div>
                            </mat-card-content>
                          </mat-card>
                        </div>
                        <div class="col-12 spacer-xs"></div>
                        <!-- Question 2 -->
                        <!--  -->

                        <!--  -->
                        <!-- Question 3 loans-->
                        <div class="col-12 needs-question"
                        *ngIf="needsForm.get('mortgage'). value > 0 || needsForm.get('mortgage'). value != null">
                          <mat-card>
                            <mat-card-content>
                              <div class="row">
                                <div class="col-1 needs-question-icon">
                                  <mat-icon
                                  [matTooltip]="needsToolTipArr['loans']">
                                    info</mat-icon>
                                </div>
                                <div class="col-7 needs-question-title">
                                  <div class="mat-h3">Other loans</div>
                                  <div class="mat-body-1">(to pay off loans, credit cards, etc.)</div>
                                </div>
                                <div class="col-4 needs-question-input">
    
                                  <!-- question loans form -->
                                  <form [formGroup]="needsForm" >
                                    <mat-form-field appearance="outline">
                                      <input 
                                        name="loans"
                                        formControlName="loans"
                                        type="text"
                                        placeholder="$0.00"
                                        currencyMask
                                        [options]="{prefix:'$', thousands: ',', decimal: '.'}"                                        
                                        matInput>
                                    </mat-form-field>
                                  </form>
                                  <!--  -->
                                </div>
                              </div>
                            </mat-card-content>
                          </mat-card>
                        </div>
                        <div class="col-12 spacer-xs"></div>
                        <!-- Question 3 -->
                        <!--  -->

                        <!--  -->
                        <!-- Question 4 emergency-->
                        <div class="col-12 needs-question"
                        *ngIf="needsForm.get('loans'). value > 0 || needsForm.get('loans'). value != null">
                          <mat-card>
                            <mat-card-content>
                              <div class="row">
                                <div class="col-1 needs-question-icon">
                                  <mat-icon
                                  [matTooltip]="needsToolTipArr['emergency']">
                                    info</mat-icon>
                                </div>
                                <div class="col-7 needs-question-title">
                                  <div class="mat-h3">Emergency funds</div>
                                  <div class="mat-body-1">(3-6 months’ of pay)</div>
                                </div>
                                <div class="col-4 needs-question-input">
    
                                  <!-- question emergency form -->
                                  <form [formGroup]="needsForm" >
                                    <mat-form-field appearance="outline">
                                      <input 
                                        name="emergency"
                                        formControlName="emergency"
                                        type="text"
                                        placeholder="$0.00"
                                        currencyMask
                                        [options]="{prefix:'$', thousands: ',', decimal: '.'}"                                        
                                        matInput>
                                    </mat-form-field>
                                  </form>
                                  <!--  -->
                                </div>
                              </div>
                            </mat-card-content>
                          </mat-card>
                        </div>
                        <div class="col-12 spacer-xs"></div>
                        <!-- Question 4 -->
                        <!--  -->

                        <!--  -->
                        <!-- Question 5 child-->
                        <div class="col-12 needs-question"
                        *ngIf="needsForm.get('emergency'). value > 0 || needsForm.get('emergency'). value != null">
                          <mat-card>
                            <mat-card-content>
                              <div class="row">
                                <div class="col-1 needs-question-icon">
                                  <mat-icon
                                  [matTooltip]="needsToolTipArr['child']">
                                    info</mat-icon>
                                </div>
                                <div class="col-7 needs-question-title">
                                  <div class="mat-h3">Children’s education fund</div>
                                  <div class="mat-body-1">($10,000-$30,000 per year, per child)</div>
                                </div>
                                <div class="col-4 needs-question-input">
    
                                  <!-- question child form -->
                                  <form [formGroup]="needsForm" >
                                    <mat-form-field appearance="outline">
                                      <input 
                                        name="child"
                                        formControlName="child"
                                        type="text"
                                        placeholder="$0.00"
                                        currencyMask
                                        [options]="{prefix:'$', thousands: ',', decimal: '.'}"                                        
                                        matInput>
                                    </mat-form-field>
                                  </form>
                                  <!--  -->
                                </div>
                              </div>
                            </mat-card-content>
                          </mat-card>
                        </div>
                        <div class="col-12 spacer-xs"></div>
                        <!-- Question 5 -->
                        <!--  -->

                        <!--  -->
                        <!-- Next button -->
                        <div class="col-12 spacer-sm"></div>
                        <div class="col-12 needs-button-next">
                          <button 
                          (click)="needsCashTotal()"
                          *ngIf="needsForm.get('child'). value > 0 || needsForm.get('child'). value != null"
                          mat-raised-button
                          color="primary">
                            NEXT
                          </button>
                        </div>
                        <!--  -->

                        <!-- *** MAIN CONTENT ENDS -->
                        <!-- // -->
                        <div class="col-12 spacer-lg"></div>
                        <!--  -->
                        
                      </div><!-- row end -->
                    </div><!-- card end -->
                  </mat-tab>
                  <!-- :: -->

                  <!-- TAB 2 Income ::::::: -->
                  <mat-tab label="Income Replacement" [disabled]="!incomeTabBool">
                    <div class="container needs-sub-container income-container">
                      <div class="row needs-question-row">
                        <div class="col-12 spacer-sm"></div>
                        <!-- // -->
                        <!-- *** MAIN CONTENT -->

                        <!-- Tab title -->
                        <div class="col-12">
                          <div class="spacer-sm"></div>
                          <div class="mat-title">How much income replacement is needed?</div>
                          <!-- <div class="spacer-sm"></div> -->
                        </div>
                        <div class="col-12 ">
                          <div class="" style="text-align: end; margin-right:20px">
                            <p>Enter amount</p>
                          </div>
                        </div>
                        <!-- *** -->

                        <!--  -->
                        <!-- Tab 2 Question 1  monthlyIncome-->
                        <div class="col-12 needs-question">
                          <mat-card>
                            <mat-card-content>
                              <div class="row">
                                <div class="col-1 needs-question-icon single-line">
                                  <mat-icon
                                  [matTooltip]="needsToolTipArr['monthInc']">
                                    info</mat-icon>
                                </div>
                                <div class="col-7 needs-question-title single-line">
                                  <div class="mat-h3">Monthly income to take care of your family</div>
                                </div>
                                <div class="col-4 needs-question-input">
    
                                  <!-- question final form -->
                                  <form [formGroup]="needsForm" >
                                    <mat-form-field appearance="outline">
                                      <input 
                                        name="monthlyIncome"
                                        formControlName="monthlyIncome"
                                        type="text"
                                        placeholder="$0.00"
                                        currencyMask
                                        [options]="{prefix:'$', thousands: ',', decimal: '.'}"                                        
                                        matInput>
                                    </mat-form-field>
                                  </form>
                                  <!--  -->
                                </div>
                              </div>
                            </mat-card-content>
                          </mat-card>
                        </div>
                        <div class="col-12 spacer-xs"></div>
                        <!-- Question 1 -->
                        <!--  -->

                        <!--  -->
                        <!-- Tab 2 Question 2  incomeYears-->
                        <div class="col-12 needs-question" 
                        *ngIf="needsForm.get('monthlyIncome'). value > 0 || needsForm.get('monthlyIncome'). value != null">
                          <mat-card>
                            <mat-card-content>
                              <div class="row">
                                <div class="col-1 needs-question-icon single-line">
                                  <mat-icon
                                  [matTooltip]="needsToolTipArr['yearInc']">
                                    info</mat-icon>
                                </div>
                                <div class="col-7 needs-question-title single-line">
                                  <div class="mat-h3">For how many years?</div>
                                </div>
                                <div class="col-4 needs-question-input">
    
                                  <!-- question final form -->
                                  <form [formGroup]="needsForm" >
                                    <mat-form-field appearance="outline">
                                      <input 
                                        name="incomeYears"
                                        formControlName="incomeYears"
                                        type="text"  
                                        placeholder="Years"                                     
                                        matInput>
                                    </mat-form-field>
                                  </form>
                                  <!--  -->
                                </div>
                              </div>
                            </mat-card-content>
                          </mat-card>
                        </div>
                        <div class="col-12 spacer-xs"></div>
                        <!-- Question 2 -->
                        <!--  -->

                        <!--  -->
                        <!-- Next button -->
                        <div class="col-12 spacer-sm"></div>
                        <div class="col-12 needs-button-next">
                          <button 
                          (click)="needsIncomeTotal()" 
                          *ngIf="needsForm.get('incomeYears'). value > 0 || needsForm.get('incomeYears'). value != null"
                          mat-raised-button
                          color="primary">
                            NEXT
                          </button>
                        </div>
                        <!--  -->

                        <!-- *** MAIN CONTENT ENDS -->
                        <!-- // -->
                        <div class="col-12 spacer-lg"></div>
                      </div>
                    </div>
                  </mat-tab>
                  <!-- :: -->

                  <!-- TAB 3 Assets ::::::: -->
                  <mat-tab label="Assets & Insurance" [disabled]="!assetsTabBool">
                    <div class="container needs-sub-container income-container">
                      <div class="row needs-question-row">
                        <div class="col-12 spacer-sm"></div>
                        <!-- // -->
                        <!-- *** MAIN CONTENT -->

                        <!-- Tab title -->
                        <div class="col-12">
                          <div class="spacer-sm"></div>
                          <div class="mat-title">Do you have assets?</div>
                          <!-- <div class="spacer-sm"></div> -->
                        </div>
                        <div class="col-12 ">
                          <div class="" style="text-align: end; margin-right:20px">
                            <p>Enter amount</p>
                          </div>
                        </div>
                        <!-- *** -->

                        <!--  -->
                        <!-- Question 1 savings-->
                        <div class="col-12 needs-question">
                          <mat-card>
                            <mat-card-content>
                              <div class="row">
                                <div class="col-1 needs-question-icon">
                                  <mat-icon
                                  [matTooltip]="needsToolTipArr['savings']">
                                    info</mat-icon>
                                </div>
                                <div class="col-7 needs-question-title">
                                  <div class="mat-h3">Savings</div>
                                  <div class="mat-body-1">(savings and investment accounts, TFSAs, etc.)</div>
                                </div>
                                <div class="col-4 needs-question-input">
    
                                  <!-- question final form -->
                                  <form [formGroup]="needsForm" >
                                    <mat-form-field appearance="outline">
                                      <input 
                                        name="savings"
                                        formControlName="savings"
                                        type="text"
                                        placeholder="$0.00"
                                        currencyMask
                                        [options]="{prefix:'$', thousands: ',', decimal: '.'}"                                        
                                        matInput>
                                    </mat-form-field>
                                  </form>
                                  <!--  -->
                                </div>
                              </div>
                            </mat-card-content>
                          </mat-card>
                        </div>
                        <div class="col-12 spacer-xs"></div>
                        <!-- Question 1 -->
                        <!--  -->

                        <!--  -->
                        <!-- Question 2 assets-->
                        <div class="col-12 needs-question" 
                        *ngIf="needsForm.get('savings'). value > 0 || needsForm.get('savings'). value != null">
                          <mat-card>
                            <mat-card-content>
                              <div class="row">
                                <div class="col-1 needs-question-icon">
                                  <mat-icon
                                  [matTooltip]="needsToolTipArr['assets']">
                                    info</mat-icon>
                                </div>
                                <div class="col-7 needs-question-title">
                                  <div class="mat-h3">Other assets</div>
                                  <div class="mat-body-1">(property, business, vehicles, etc.)</div>
                                </div>
                                <div class="col-4 needs-question-input">
    
                                  <!-- question final form -->
                                  <form [formGroup]="needsForm" >
                                    <mat-form-field appearance="outline">
                                      <input 
                                        name="assets"
                                        formControlName="assets"
                                        type="text"
                                        placeholder="$0.00"
                                        currencyMask
                                        [options]="{prefix:'$', thousands: ',', decimal: '.'}"                                        
                                        matInput>
                                    </mat-form-field>
                                  </form>
                                  <!--  -->
                                </div>
                              </div>
                            </mat-card-content>
                          </mat-card>
                        </div>
                        <div class="col-12 spacer-xs"></div>
                        <!-- Question 2 -->
                        <!--  -->

                        <!--  -->
                        <!-- Question 3 existingLife-->
                        <div class="col-12 needs-question" 
                        *ngIf="needsForm.get('assets'). value > 0 || needsForm.get('assets'). value != null">
                          <mat-card>
                            <mat-card-content>
                              <div class="row">
                                <div class="col-1 needs-question-icon">
                                  <mat-icon
                                  [matTooltip]="needsToolTipArr['existing']">
                                    info</mat-icon>
                                </div>
                                <div class="col-7 needs-question-title">
                                  <div class="mat-h3">Existing life insurance</div>
                                  <div class="mat-body-1">(including group plans, individual policies)</div>
                                </div>
                                <div class="col-4 needs-question-input">
    
                                  <!-- question final form -->
                                  <form [formGroup]="needsForm" >
                                    <mat-form-field appearance="outline">
                                      <input 
                                        name="existingLife"
                                        formControlName="existingLife"
                                        type="text"
                                        placeholder="$0.00"
                                        currencyMask
                                        [options]="{prefix:'$', thousands: ',', decimal: '.'}"                                        
                                        matInput>
                                    </mat-form-field>
                                  </form>
                                  <!--  -->
                                </div>
                              </div>
                            </mat-card-content>
                          </mat-card>
                        </div>
                        <div class="col-12 spacer-xs"></div>
                        <!-- Question 3 -->
                        <!--  -->

                        <!--  -->
                        <!-- Next button -->
                        <div class="col-12 spacer-sm"></div>
                        <div class="col-12 needs-button-next">
                          <button  
                          (click)="needsAssetTotal()"
                          *ngIf="needsForm.get('existingLife'). value > 0 || needsForm.get('existingLife'). value != null"
                          mat-raised-button
                          color="warn">
                            DONE
                          </button>
                        </div>
                        <!--  -->

                        <!-- *** MAIN CONTENT ENDS -->
                        <!-- // -->
                        <div class="col-12 spacer-lg"></div>
                      </div>
                    </div>
                  </mat-tab>
                  <!-- :: -->

                  <!-- TAB 4 Totals ::::::: -->
                  <mat-tab label="Suggested Coverage" [disabled]="!totalsTabBool">
                    <div class="container needs-sub-container income-container">
                      <div class="row needs-question-row">
                        <div class="col-12 spacer-sm"></div>
                        <!-- // -->
                        <!-- *** MAIN CONTENT -->

                        <div class="col-12 needs-question" >
                          <mat-card>
                            <mat-card-content>
                              <div class="mat-h2">
                                This is your suggested coverage: {{needsForm.get('needsTotals').value | currency}}
                              </div>
                            </mat-card-content>
                          </mat-card>
                        </div>

                        <!-- *** MAIN CONTENT ENDS -->
                        <!-- // -->
                        <div class="col-12 spacer-lg"></div>
                      </div>
                    </div>
                  </mat-tab>
                  <!--  -->
                </mat-tab-group>
              </div>
            </div>
            <!-- NEEDS ANALYSIS COMPONENT ends ------------------- -->
            <!--  -->

          </mat-card-content>
          <!-- Card Footer -->
          <mat-card-footer>
            <div class="spacer-sm"></div>
          </mat-card-footer>
          <!-- *** -->
        </mat-card>

        <!-- *** Stepper nav buttons -->
        <div class="row stepper-header-btn">
          <div class="col-12 spacer-sm"></div>
          <div class="col-6 needs-button-prev">
            <!-- <button 
            mat-raised-button 
            matStepperPrevious
            >Back</button> -->
          </div>
          <div class="col-6 needs-button-next">
            <button
            mat-raised-button 
            matStepperNext
            color="primary"
            >Next</button>
          </div>
        </div>
        <!--  -->
        <div class="spacer-lg"></div>
      </div>
      <!--  -->
    </mat-step>
    <!-- !!! NEEDS ANALYSIS ends !!!  -->
    <!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->
    <!-- %% -->

    <!-- %% -->
    <!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->
    <!-- !!! TYPE OF INSURANCE !!!  -->
    <mat-step [completed]="typeValidator">
      <!--  -->
      <div class="sub-container">
        <mat-card 
        (mouseover)="isActiveToggle(true)"
        (mouseout)="isActiveToggle(false)"
        [class.mat-elevation-z2]="!isActive"
        [class.mat-elevation-z8]="isActive"
        class="section-card card-type">
          <!-- Card Header -->
          <mat-card-header>
            <mat-card-title>
              <!-- Title -->
              <div class="mat-headline">
                <div class="spacer-sm"></div>
                <span>Choose your insurance protection.</span>
                <div class="spacer-sm"></div>
              </div>
              <!--  -->
            </mat-card-title>
          </mat-card-header>
          <!-- *** -->

          <!-- *** -->
          <mat-card-content>
            <div class="row">
              <!-- Picture -->
              <div class="col-4">
                <img src="../../../assets/svg/insurance-type.svg" alt="Insurance Type">
              </div>
              <!--  -->
              <!-- Insurance Type Form -->
              <div class="col-7">
                <div class="row">
                  <!-- Input -->
                  <div class="col-12">
                    <form [formGroup]="quoteForm">
                      <!--Insurance type -->
                      <p>
                        <mat-form-field>
                          <mat-label>Insurance Protection</mat-label>
                          <mat-select 
                            formControlName="type" 
                            name="type">
                              <mat-option value="burI">Burial Insurance</mat-option>
                              <mat-option value="creI">Cremation Insurance</mat-option>
                              <mat-option value="diaI">Diabetes Insurance</mat-option>
                              <mat-option value="canI">Cancer Insurance</mat-option>
                              <mat-option value="lifI">Life Insurance</mat-option>
                              <mat-option value="nonI">Non-Medical Life Insurance</mat-option>
                              <mat-option value="proI">Income Protection Insurance</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </p>
                    </form>
                  </div>
                  <!-- Output -->
                  <!-- Coverage Limit Output -->
                  <div class="col-12" *ngIf="quoteForm.get('type').value != null">
                    <div class="mat-title">
                      <span><b>Coverage limit for {{coverageNameArr[quoteForm.get('type').value]}}</b></span><br>
                      <span>{{covMin | currency}} to {{covMax |currency}}</span>
                    </div>
                    <div class="mat-h3" [innerHTML]="coverageTextArr[quoteForm.get('type').value]"></div>
                  </div>
                  <div class="col-12 spacer-md"></div>
                  <div class="col-12 content-center">
                    <button 
                    mat-raised-button 
                    matStepperPrevious
                    ><mat-icon>contact_support</mat-icon>Need help deciding your coverage?</button>                    
                  </div>
                  <!--  -->
                </div>
                <!--  -->
              </div>
              <div class="col-3"></div>
              <div class="col-12 spacer-sm"></div>
              <!--  -->
            </div>
          </mat-card-content>
          <!-- *** -->
          <!-- Card Footer -->
          <mat-card-footer>
            <div class="spacer-sm"></div>
          </mat-card-footer>
          <!-- *** -->
        </mat-card>
        <!-- *** Stepper nav buttons -->
        <div class="row stepper-header-btn">
          <div class="col-12 spacer-sm"></div>
          <div class="col-6 needs-button-prev">
            <!-- <button 
            mat-raised-button 
            matStepperPrevious
            >Back</button> -->
          </div>
          <div class="col-6 needs-button-next">
            <button
            [disabled]="!typeValidator"
            mat-raised-button 
            matStepperNext
            color="primary"
            >Next</button>
          </div>
        </div>
        <!--  -->
        <div class="spacer-lg"></div>
      </div>
      <!--  -->
    </mat-step>
    <!-- !!! TYPE OF INSURANCE ends !!!  -->
    <!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->
      <!-- %% -->
    
    <!-- %% -->
    <!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->
    <!-- !!! GENDER !!!  -->
    <mat-step [completed]="genderValidator">
    <!-- <mat-step [completed]="genderValidator"> -->
      <!--  -->
      <div class="sub-container">
        <form [formGroup]="quoteForm">
          <!-- mat card Start -->
          <mat-card 
          (mouseover)="isActiveToggle(true)"
          (mouseout)="isActiveToggle(false)"
          [class.mat-elevation-z2]="!isActive"
          [class.mat-elevation-z8]="isActive"
          class="section-card card-gender">
          <!-- Card Header -->
          <mat-card-header>
            <mat-card-title>
              <!-- Title -->
              <div class="mat-headline">
                <div class="spacer-sm"></div>
                <span>What is your gender?</span>
                <div class="spacer-sm"></div>
              </div>
              <!--  -->
            </mat-card-title>
          </mat-card-header>
          <!-- *** -->

          <mat-card-content>
            <div class="row gender-hld">
              <!-- Radio Buttons Gender -->
              <div class="col-0 col-sm-2"></div>
              <!-- Male Radio button -->
              <div class="col-12 col-sm-4">
                <label class="gender-radio-label gender-male"
                [ngClass]="{'radio-fade': quoteForm.get('gender').value !== 'male' && quoteForm.get('gender').value !== null}">
                  
                    <!-- Input -->
                    <input 
                      type="radio" 
                      formControlName="gender"
                      value="male" 
                      id="">
                    <!--  -->
                  <!-- </form> -->

                  <!-- Male -->
                    <div class="col-12">
                      <img src="../../../assets/img/male-flat.png" 
                        class="gender-png"
                        alt="Male">
                    </div>
                    <div class="col-12"
                    [ngClass]="{'radio-green': quoteForm.get('gender').value == 'male' && quoteForm.get('gender').value !== null}">
                      <div class="spacer-sm"></div>
                      <div class="mat-title">MALE</div>
                    </div>
                  <!--  -->
                </label>
              </div>
              <!--  -->

              <!-- Female Radio button -->
              <div class="col-12 col-sm-4">
                <label class="gender-radio-label gender-female"
                [ngClass]="{'radio-fade': quoteForm.get('gender').value !== 'female' && quoteForm.get('gender').value !== null}">

                  <!-- Input -->
                  <input 
                    type="radio" 
                    formControlName="gender"
                    value="female" 
                    id="">
                  <!--  -->

                  <!-- Female -->
                  <div class="col-12">
                    <img src="../../../assets/img/female-flat.png" 
                      class="gender-png"
                      alt="Female">
                  </div>
                  <div class="col-12">
                    <div class="spacer-sm"></div>
                    <div class="mat-title"
                      [ngClass]="{'radio-green': quoteForm.get('gender').value == 'female' && quoteForm.get('gender').value !== null}">
                      FEMALE
                    </div>
                  </div>
                  <!--  -->
                </label>
              </div>
              <!--  -->
              <div class="col-0 col-sm-2"></div>
            </div>
            <!--  -->
            </mat-card-content>
            <!-- Card Footer -->
            <!-- <mat-card-footer>
              <div class="spacer-xs"></div>
            </mat-card-footer> -->
            <!-- *** -->
          </mat-card>
        </form>
        <!--  -->
        <!-- *** Stepper nav buttons -->
        <div class="row stepper-header-btn">
          <div class="col-12 spacer-sm"></div>
          <div class="col-6 needs-button-prev">
            <button 
            mat-raised-button 
            matStepperPrevious
            >Back</button>
          </div>
          <div class="col-6 needs-button-next">
            <button
            [disabled]="!genderValidator"
            mat-raised-button 
            matStepperNext
            color="primary"
            >Next</button>
          </div>
        </div>
        <!--  -->
        <div class="spacer-lg"></div>
      </div>
      <!--  -->
    </mat-step>
    <!-- !!! GENDER ends !!!  -->
    <!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->
    <!-- %% -->

    <!-- %% -->
    <!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->
    <!-- !!! SMOKER !!!  -->
    <!-- <mat-step [completed]="true"> -->
    <mat-step [completed]="smokerValidator">
      <!--  -->
      <div class="sub-container">
        <form [formGroup]="quoteForm">
          <!-- mat-card -->
          <mat-card 
          (mouseover)="isActiveToggle(true)"
          (mouseout)="isActiveToggle(false)"
          [class.mat-elevation-z2]="!isActive"
          [class.mat-elevation-z8]="isActive"
          class="section-card card-smoker">
            <!-- Card Header -->
            <mat-card-header>
              <mat-card-title>
                <!-- Title -->
                <div class="mat-headline">
                  <div class="spacer-sm"></div>
                  <span>Have you smoked in the past year?</span>
                  <div class="spacer-sm"></div>
                </div>
                <!--  -->
              </mat-card-title>
            </mat-card-header>
            <!-- *** -->

            <!-- Card Content -->
            <mat-card-content>
              <!-- smoker content -->
              <div class="row gender-hld">
                <!-- Radio Buttons Smoker -->
                <div class="col-0 col-sm-2"></div>
                <!-- non-smoker -->
                <div class="col-12 col-sm-4">
                  <label class="gender-radio-label gender-smoker"
                  [ngClass]="{'radio-fade': quoteForm.get('smoker').value  && quoteForm.get('smoker').value !== null}">
                    
                      <!-- Input -->
                      <input 
                        type="radio" 
                        formControlName="smoker"
                        [value]="false" 
                        id="">
                      <!--  -->
                    <!-- </form> -->
  
                    <!-- Non-Smoker -->
                      <div class="col-12">
                        <img src="../../../assets/img/nonsmoking.png" 
                          class="gender-png"
                          alt="NON-SMOKER">
                      </div>
                      <div class="col-12"
                      [ngClass]="{'radio-green': !quoteForm.get('smoker').value && quoteForm.get('smoker').value !== null}">
                        <div class="spacer-sm"></div>
                        <div class="mat-title">NON-SMOKER</div>
                      </div>
                    <!--  -->
                  </label>
                </div>
                <!--  -->
  
                <!-- Smoker -->
                <div class="col-12 col-sm-4">
                  <label class="gender-radio-label gender-smoker"
                  [ngClass]="{'radio-fade': !quoteForm.get('smoker').value && quoteForm.get('smoker').value !== null}">
  
                      <!-- Input -->
                      <input 
                        type="radio" 
                        formControlName="smoker"
                        [value]="true" 
                        id="">
                      <!--  -->
  
                    <!-- smoker -->
                      <div class="col-12">
                        <img src="../../../assets/img/smoking.png" 
                          class="gender-png"
                          alt="smoker">
                      </div>
                      <div class="col-12"
                      [ngClass]="{'radio-green': quoteForm.get('smoker').value && quoteForm.get('smoker').value !== null}">
                        <div class="spacer-sm"></div>
                        <div class="mat-title">SMOKER</div>
                      </div>
                    <!--  -->
                  </label>
                </div>
                <!--  -->
                <div class="col-0 col-sm-2"></div>
              </div>
              <!-- *** -->

            </mat-card-content>
            <!-- *** -->
            
            <!-- Card Footer -->
            <mat-card-footer>
              <div class="spacer-sm top-space"></div>
            </mat-card-footer>
            <!-- *** -->
          </mat-card>
          <!-- *** -->
        </form>
        <!--  -->
        <!-- *** Stepper nav buttons -->
        <div class="row stepper-header-btn">
          <div class="col-12 spacer-sm"></div>
          <div class="col-6 needs-button-prev">
            <button 
            mat-raised-button 
            matStepperPrevious
            >Back</button>
          </div>
          <div class="col-6 needs-button-next">
            <button
            [disabled]="!smokerValidator"
            mat-raised-button 
            matStepperNext
            color="primary"
            >Next</button>
          </div>
        </div>
        <!--  -->
        <div class="spacer-lg"></div>
      </div>
      <!--  -->
    </mat-step>
    <!-- !!! SMOKER ends !!!  -->
    <!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->
    <!-- %% -->

    <!-- %% -->
    <!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->
    <!-- !!! BIRTH DATE !!!  -->
    <!-- <mat-step [completed]="true"> -->
    <mat-step [completed]="birthDateValidator">
      <!--  -->
      <div class="sub-container">
        <!-- mat-card -->
        <mat-card 
        (mouseover)="isActiveToggle(true)"
        (mouseout)="isActiveToggle(false)"
        [class.mat-elevation-z2]="!isActive"
        [class.mat-elevation-z8]="isActive"
        class="section-card card-type">
          <!-- Card Header -->
          <mat-card-header>
            <mat-card-title>
              <!-- Title -->
              <div class="mat-headline">
                <div class="spacer-sm"></div>
                <span>What is your date of birth?</span>
                <div class="spacer-sm"></div>
              </div>
              <!--  -->
            </mat-card-title>
          </mat-card-header>
          <!--  -->

          <mat-card-content>
            <div class="row">
    
              <!-- Picture -->
              <div class="col-4">
                <img src="../../../assets/svg/birthday.svg" alt="Insurance Type">
              </div>
              <!--  -->
    
              <!-- Birth Date Form -->
              <div class="col-7">
                <form [formGroup]="quoteForm">
                  <!-- DatePicker -->
                  <mat-form-field>
                    <mat-label>Birth Date</mat-label>
                    <input  
                      [matDatepicker]="ageDatepicker"
                      [min]="minDate"
                      [max]="maxDate"
                      startView="multi-years"
                      formControlName="birthDate"
                      matInput>
                    <mat-datepicker-toggle 
                      [for]="ageDatepicker"
                      matSuffix>
                    </mat-datepicker-toggle>
                    <mat-datepicker 
                      #ageDatepicker 
                      >
                    </mat-datepicker>
                  </mat-form-field>
                  <!--  -->
                </form>
              </div>
              <!--  -->
            </div>

          </mat-card-content>
          <!-- *** -->
          <!-- Card Footer -->
          <mat-card-footer>
            <div class="spacer-sm"></div>
          </mat-card-footer>
          <!-- *** -->
        </mat-card>
        <!-- *** -->
        <!--  -->
        <!-- *** Stepper nav buttons -->
        <div class="row stepper-header-btn">
          <div class="col-12 spacer-sm"></div>
          <div class="col-6 needs-button-prev">
            <button 
            mat-raised-button 
            matStepperPrevious
            >Back</button>
          </div>
          <div class="col-6 needs-button-next">
            <button
            [disabled]="!birthDateValidator"
            mat-raised-button 
            matStepperNext
            color="primary"
            >Next</button>
          </div>
        </div>
        <!--  -->
        <div class="spacer-lg"></div>
      </div>
      <!--  -->
    </mat-step>
    <!-- !!! BIRTH DATE ends !!!  -->
    <!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->
    <!-- %% -->

    <!-- %% -->
    <!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->
    <!-- !!! COVERAGE !!!  -->
    <!-- <mat-step [completed]="true"> -->
    <mat-step [completed]="coverageValidator">
      <!--  -->
      <div class="sub-container">
        <!-- mat-card -->
        <mat-card 
        (mouseover)="isActiveToggle(true)"
        (mouseout)="isActiveToggle(false)"
        [class.mat-elevation-z2]="!isActive"
        [class.mat-elevation-z8]="isActive"
        class="section-card card-type">
          <!-- Card Header -->
          <mat-card-header>
            <mat-card-title>
              <!-- Title -->
              <div class="mat-headline">
                <div class="spacer-sm"></div>
                <span>How much coverage do you want?</span>
                <div class="spacer-sm"></div>
              </div>
              <!--  -->
            </mat-card-title>
          </mat-card-header>
          <!-- *** -->

          <mat-card-content>
            <div class="row">
    
              <!-- Picture -->
              <div class="col-4">
                <img src="../../../assets/svg/coverage.svg" alt="Insurance Type">
              </div>
              <!--  -->
    
              <!-- Coverage Form -->
              <div class="col-7">
                <form [formGroup]="quoteForm">
                  <div class="col-12">
                    <mat-slider 
                    [thumbLabel]="true"
                    [displayWith]="formatLabel"
                    tickInterval="1000"
                    [step]="covStep"
                    [min]="covMin"
                    [max]="covMax"
                    formControlName="coverage"
                    color="primary"></mat-slider>
                  </div>
                  <div class="col-12">
                    <div class="coverage-slider-output">
                      <div class="mat-title">
                        <span><b>Coverage Amount: </b>{{quoteForm.get('coverage').value | currency}}</span>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <!--  -->
            </div>

          </mat-card-content>
          <!-- Card Footer -->
          <mat-card-footer>
            <div class="spacer-sm"></div>
          </mat-card-footer>
          <!-- *** -->
        </mat-card>
        <!-- *** -->
        <!--  -->
        <!-- *** Stepper nav buttons -->
        <div class="row stepper-header-btn">
          <div class="col-12 spacer-sm"></div>
          <div class="col-6 needs-button-prev">
            <button 
            mat-raised-button 
            matStepperPrevious
            >Back</button>
          </div>
          <div class="col-6 needs-button-next">
            <button
            [disabled]="!coverageValidator"
            mat-raised-button 
            matStepperNext
            color="primary"
            >Next</button>
          </div>
        </div>
        <!--  -->
        <div class="spacer-lg"></div>
      </div>
      <!--  -->
    </mat-step>
    <!-- !!! COVERAGE ends !!!  -->
    <!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->
    <!-- %% -->

    <!-- %% -->
    <!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->
    <!-- !!! CONTACT INFO !!!  -->
    <!-- <mat-step [completed]="true"> -->
    <mat-step [completed]="contactValidator">
      <!--  -->
      <div class="sub-container">
        <!-- mat-card -->
        <mat-card 
        (mouseover)="isActiveToggle(true)"
        (mouseout)="isActiveToggle(false)"
        [class.mat-elevation-z2]="!isActive"
        [class.mat-elevation-z8]="isActive"
        class="section-card card-type">
        <!-- Card Header -->
          <mat-card-header>
            <mat-card-title>
              <!-- Title -->
              <div class="mat-headline">
                <div class="spacer-sm"></div>
                <span>Your contact information.</span>
                <div class="spacer-sm"></div>
              </div>
              <!--  -->
            </mat-card-title>
          </mat-card-header>
          <!-- *** -->
          
          <mat-card-content>
            <div class="row">
              <!-- Picture -->
              <div class="col-4">
                <img src="../../../assets/svg/info.svg" alt="Info">
              </div>
              <!--  -->
    
              <!-- Coverage Form -->
              <div class="col-7">
                <form [formGroup]="quoteForm">
    
                  <!-- Name -->
                  <mat-form-field>
                    <mat-label>Name</mat-label>
                    <input 
                      formControlName="name"
                      name="name"
                      type="text"
                      matInput>
                  </mat-form-field>
                  <!--  -->
                  
                  <!-- Email -->
                  <mat-form-field>
                    <mat-label>Email</mat-label>
                    <input 
                      formControlName="email"
                      name="email"
                      type="text"
                      matInput>
                  </mat-form-field>
                  <!--  -->
                  
                  <!-- Phone -->
                  <mat-form-field>
                    <mat-label>Phone</mat-label>
                    <input 
                      name="phone"
                      formControlName="phone"
                      type="text"
                      minlength="10"
                      maxlength="15"
                      matInput>
                  </mat-form-field>
                  <!--  -->
    
                  <!-- Email -->
    
                </form>
              </div>
              <!--  -->
            </div>

          </mat-card-content>
          <!-- Card Footer -->
          <mat-card-footer>
            <div class="spacer-sm"></div>
          </mat-card-footer>
          <!-- *** -->
        </mat-card>
        <!-- *** -->
        <!--  -->
        <!-- *** Stepper nav buttons -->
        <div class="row stepper-header-btn">
          <div class="col-12 spacer-sm"></div>
          <div class="col-6 needs-button-prev">
            <button 
            mat-raised-button 
            matStepperPrevious
            >Back</button>
          </div>
          <div class="col-6 needs-button-next">
            <button
            [disabled]="!contactValidator"
            mat-raised-button 
            matStepperNext
            color="primary"
            >View Your Quote</button>
          </div>
        </div>
        <!--  -->
        <div class="spacer-lg"></div>
      </div>
      <!--  -->
    </mat-step>
    <!-- !!! CONTACT INFO ends !!!  -->
    <!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->
    <!-- %% -->
    
    <!-- %% -->
    <!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->
    <!-- QUOTES PAGE *** -->
    <mat-step  [completed]="false">
      <div class="sub-container quote-step">
        <!-- * -->

        <!--  -->
        <!-- *** -->
        <!-- ***************************** -->
        <!-- CHANGE COVERAGE *************************************** -->
        <div class="spacer-sm"></div>
        <mat-card class="list-card-hover">
          <mat-card-content>
            <div class="row">
              <div class="col-12 spacer-sm"></div>
              <div class="col-5">
                <div class="mat-headline">Change your coverage amount.</div>
              </div>
              <!-- Coverage Form -->
              <div class="col-7">
                <form [formGroup]="quoteForm">
                  <div class="col-12">
                    <mat-slider 
                    [thumbLabel]="true"
                    [displayWith]="formatLabel"
                    tickInterval="1000"
                    [step]="covStep"
                    [min]="covMin"
                    [max]="covMax"
                    formControlName="coverage"
                    color="primary"></mat-slider>
                  </div>
                  <div class="col-12">
                    <div class="coverage-slider-output">
                      <div class="mat-title">
                        <span><b>Coverage Amount: </b>{{quoteForm.get('coverage').value | currency}}</span>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <!--  -->
            </div>
          </mat-card-content>
        </mat-card>
        <!-- CHANGE COVERAGE ENDS *************************************** -->
        <!-- ***************************** -->
        <!-- *** -->
        
        <div class="spacer-sm"></div>
        <!-- New loop design for top quotes -->
        <!--  -->
        <!-- *** -->
        <!-- ******************************** -->
        <!-- TERM LIFE TOP QUOTE *************************************** -->
        <div class="termTopHld" 
        *ngIf="quoteForm.get('type').value != 'burI' && quoteForm.get('type').value != 'creI'">
          <div *ngFor="let quote of topQuotesTermArr; let i = index;">
            <div class="" *ngIf="i < 1">
              <!-- New Term Top Quote -->
              <div class="mat-title">
                <span><b>Recommended term life quote</b></span>
              </div>
              
              <mat-card 
              (mouseover)="isActiveToggle(false)"
              (mouseout)="isActiveToggle(false)"
              [class.mat-elevation-z2]="!isActive"
              [class.mat-elevation-z8]="isActive"
              class="section-card card-top-quote list-card-hover">
                <!-- Card Content -->
                <mat-card-content>
                  <div class="row qq-card-content qq-top-term">
                    <!-- side img -->
                    <div class="col-0 col-sm-3">
                    </div>
                    <div class="col-6 col-sm-3 qq-card-company-hld">
                      <img src="{{displayInfoArr[0][quote.term].img}}" class="img-fluid">
                      <hr>
                      Coverage<br>
                      {{quoteForm.get('coverage').value | currency}}
                    </div>
                    <div class="col-6 col-sm-3 qq-card-info-hld">
                      
                      <div class="h-spacer spacer-md"></div>
                      <div class="qq-card-amount ">
                        <!-- <div class="mat-subheading-1">TERM 10 Years</div> -->
                        Term Life <br>
                        {{displayInfoArr[0][quote.term].policyNameReg}}
                        
                        <hr>
  
                        <div class="mat-title color-primary"><strong>{{quote.value | currency}}</strong></div>
                        
                        <button mat-stroked-button color="primary"
                          (click)="applyRouter(displayInfoArr[0][quote.term].policyNameCode, displayInfoArr[0][quote.term].companyNameAng)">
                          Apply Now</button>
                      </div>
                    </div>
                    <div class="col-0 col-sm-3"></div>
                  </div>
                </mat-card-content>
                <!-- *** -->
      
              </mat-card>
              <!-- New Term Top Quote ends -->
              <!-- *** -->
            </div>
          </div>
        </div>
        <!-- *** -->
        <!--  -->

        <div class="spacer-md"></div>

        <!--  -->
        <!-- *** -->
        <!-- ******************************** -->
        <!-- WHOLE LIFE TOP QUOTE *************************************** -->
        <div *ngFor="let quote of topQuotesWLArr; let i = index;">
          <div class="" *ngIf="i < 1">
            <!-- New Whole Life Top Quote -->
            <div class="mat-title">
              <span><b>Recommended whole life quote</b></span>
            </div>
            
            <mat-card 
            (mouseover)="isActiveToggle(false)"
            (mouseout)="isActiveToggle(false)"
            [class.mat-elevation-z2]="!isActive"
            [class.mat-elevation-z8]="isActive"
            class="section-card card-top-quote list-card-hover">
              <!-- Card Content -->
              <mat-card-content>
                <div class="row qq-card-content qq-top-term">
                  <!-- side img -->
                  <div class="col-0 col-sm-3">
                  </div>
                  <div class="col-6 col-sm-3 qq-card-company-hld">
                    <img src="{{displayInfoArr[0][quote.company].img}}"  class="img-fluid">
                    <hr>
                    Coverage<br>
                    {{quoteForm.get('coverage').value | currency}}
                  </div>
                  <div class="col-6 col-sm-3 qq-card-info-hld">
                    <div class="qq-card-amount vertical-align">
                      <!-- <div class="mat-subheading-1">TERM 10 Years</div> -->
                      Whole Life <br>
                      <!-- {{displayInfoArr[0][quote.company].policyNameReg}} -->
                      <hr>
                      <div class="mat-title color-primary"><strong>{{quote.value | currency}}</strong></div>
                      <button mat-stroked-button color="primary"
                        (click)="applyRouter(displayInfoArr[0][quote.company].policyNameCode, displayInfoArr[0][quote.company].companyNameAng)">
                        Apply Now</button>
                    </div>
                  </div>
                  <div class="col-0 col-sm-3"></div>
                </div>
              </mat-card-content>
              <!-- *** -->
    
            </mat-card>
            <!-- New Term Top Quote ends -->
            <!-- *** -->
          </div>
        </div>
        <!-- *** -->
        <!--  -->

        <div class="spacer-md"></div>

        <!-- Open list button -->
        <div class="spacer-md"></div>
          <button 
          (click)="this.buttonListToggle()"
          color="primary" 
          mat-flat-button>
            {{buttonResultsName}}
          </button>
          <!--  -->
        <div class="spacer-lg" *ngIf="buttonList"></div>

        <!-- *** -->
        <!-- New Term List -->
        <div class="termTopHld" 
        *ngIf="quoteForm.get('type').value != 'burI' && quoteForm.get('type').value != 'creI'">
        <div class="list-hide" *ngIf="buttonList">
          <div class="mat-title">
            <span><b>More term life quotes</b></span>
          </div>
          <p>
            COVERAGE AMOUNT <br>
            {{quoteForm.get('coverage').value |currency}}
          </p>
          <div *ngFor="let quote of topQuotesTermArr; let i = index;">
            <div class="">
              <mat-card 
                (mouseover)="isActiveToggle(false)"
                (mouseout)="isActiveToggle(false)"
                [class.mat-elevation-z2]="!isActive"
                [class.mat-elevation-z8]="isActive"
                class="section-card card-list-term list-card-hover">
                  <div class="row">
                    <div class="col-12 col-sm-3 qq-list-img">
                      <img src="{{displayInfoArr[0][quote.term].img}}"  class="img-fluid">
                      <div class="h-spacer spacer-sm"></div>
                    </div>
                    <div class="col-12 col-sm-3 qq-list-term">
                      <div class="qq-list-term-info vertical-align">
                        Term Life <br>
                        {{displayInfoArr[0][quote.term].policyNameReg}}
                        <div class="h-spacer spacer-sm"></div>                      
                      </div>
                    </div>
                    <div class="col-12 col-sm-3 qq-list-amount">
                      <div class="qq-list-amount-info vertical-align">
                        <div class="mat-title color-primary">
                          <strong>{{quote.value | currency}}</strong>
                        </div>
                        <div class="h-spacer spacer-sm"></div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-3 qq-list-button-hld">
                      <div class="qq-list-button vertical-align">
                        <button mat-stroked-button color="primary"
                          (click)="applyRouter(displayInfoArr[0][quote.term].policyNameCode, displayInfoArr[0][quote.term].companyNameAng)">
                          Apply Now</button>
                      </div>
                      <div class="h-spacer spacer-sm"></div>
                    </div>
                  </div>
                </mat-card>
                <div class="spacer-xs"></div>
              </div>
            </div>
          </div>
        </div>
        <!-- New list ends -->
        <!-- *** -->

        <div class="spacer-lg" *ngIf="buttonList"></div>

        <!-- *** -->
        <!-- New Whole Life List -->
        <div class="list-hide" *ngIf="buttonList">
          <div class="mat-title">
            <span><b>More whole life quotes</b></span>
          </div>
          <p>
            COVERAGE AMOUNT <br>
            {{quoteForm.get('coverage').value |currency}}
          </p>
          <div *ngFor="let quote of topQuotesWLArr; let i = index;">
            <div class="">
              <mat-card 
              (mouseover)="isActiveToggle(false)"
              (mouseout)="isActiveToggle(false)"
              [class.mat-elevation-z2]="!isActive"
              [class.mat-elevation-z8]="isActive"
              class="section-card card-list-term list-card-hover">
                <div class="row">
                  <div class="col-12 col-sm-3 qq-list-img">
                    <img src="{{displayInfoArr[0][quote.company].img}}"  class="img-fluid">
                    <div class="h-spacer spacer-sm"></div>
                  </div>
                  <div class="col-12 col-sm-3 qq-list-term">
                    <div class="qq-list-term-info vertical-align">
                      Whole Life <br>
                    </div>
                    <div class="h-spacer spacer-sm"></div>
                  </div>
                  <div class="col-12 col-sm-3 qq-list-amount">
                    <div class="qq-list-amount-info vertical-align">
                      <div class="mat-title color-primary">
                        <strong>{{quote.value | currency}}</strong>
                      </div>
                      <div class="h-spacer spacer-sm"></div>
                    </div>
                  </div>
                  <div class="col-12 col-sm-3 qq-list-button-hld">
                    <div class="qq-list-button vertical-align">
                      <button mat-stroked-button color="primary"
                        (click)="applyRouter(displayInfoArr[0][quote.company].policyNameCode, displayInfoArr[0][quote.company].companyNameAng)">
                        Apply Now</button>
                    </div>
                    <div class="h-spacer spacer-sm"></div>
                  </div>
                </div>
              </mat-card>
              <div class="spacer-xs"></div>
            </div>
          </div>
        </div>
        <!-- New list ends -->
        <!-- *** -->

        <div class="spacer-lg"></div>
        
        <!--  -->
        <!-- *** Stepper nav buttons -->
        <div class="row stepper-header-btn">
          <div class="col-12 spacer-sm"></div>
          <div class="col-6 needs-button-prev">
            <button 
            mat-raised-button 
            matStepperPrevious
            >Back</button>
          </div>
          <div class="col-6 needs-button-next">
            <!-- <button
            [disabled]="!typeValidator"
            mat-raised-button 
            matStepperNext
            color="primary"
            >Next</button> -->
          </div>
        </div>
        <!--  -->
      </div>
    </mat-step>  
    <!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->
    <!-- %% -->
  </mat-horizontal-stepper>
  <!--  -->
  <!--  -->
</div>
<!--  -->