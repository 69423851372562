import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from "./main/main/main.component";
import { CppAppComponent } from './main/cpp-app/cpp-app.component';
import { AclAppComponent } from './main/acl-app/acl-app.component';

const routes: Routes = [
  // redirect
  {path: '', redirectTo: 'get-quote', pathMatch: 'full'},
  // pages...
  {path: 'get-quote', component: MainComponent },
  {path: 'CPP-App', component: CppAppComponent},
  {path: 'ACL-App', component:AclAppComponent},
  // catch all redirect
  {path: '**', redirectTo: 'get-quote', pathMatch: 'full'}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
