import { Component, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'ILM CPP APP';
  formArray;
  cppBasicForm:FormGroup;
  

  // 
  constructor(
    private http:HttpClient
  ){}

  // 
  ngOnInit(){
    this.formArray = {
      "plan": "Term Life",
      "coverage": 100000,
      "age": 45,
      "smoker": true,
      "gender": "male"
    }
    this.cppBasicForm = new FormGroup({
      "plan": new FormControl(null, Validators.required),
      "coverage": new FormControl(null, Validators.required),
      "age": new FormControl(null, Validators.required),
      "smoker": new FormControl(null, Validators.required),
      "gender": new FormControl(null, Validators.required)
    })
  }
  // 
  onSubmit(){
    this.sendForm(this.formArray)
    .subscribe(responseData =>{
      console.log('Success!');
      console.log(responseData.body['plan']);
    }, error =>{
      console.log('Error');
      console.log(error);
    })
  }
  // 
  sendForm(data){
    let sendData = data;
    return this.http
    .post(
      'https://us-central1-cpp-quotes.cloudfunctions.net/cppQuotes ',
      sendData,
      {
        observe: 'response'
      }
    )
  }
}
