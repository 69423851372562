import { Injectable } from '@angular/core';
import { AngularFirestore } from "@angular/fire/firestore";

@Injectable({
  providedIn: 'root'
})
export class QqFormService {

  constructor(
    private firestore: AngularFirestore
  ) { }
  // 

  createPerson(person){
    return this.firestore.collection('qqFormPerson/').add(person.value);
  }

  updatePerson(person, id){
    return this.firestore.doc('qqFormPerson/' + id).update(person.value);
  }
}
