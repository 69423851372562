import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class QqRouterFormService {
  //
  qqForm:FormGroup;
  constructor(
  ) { }
  // 
  qqFormSet(form){
    this.qqForm = form;
  }
}