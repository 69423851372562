<div class="container acl-app">
  <div class="row">
    <div class="col-12">
      <!-- Mat-sidenav-container -->
      <mat-sidenav-container class="sidenav-container">
        <!--  -->
        <!-- *** Sidebar Right -->
        <mat-sidenav 
        #drawer
        class="drawer"
        [mode]="(isHandset$ | async) ? 'over' : 'side'"
        [opened]="(isHandset$ | async) ? 'false' : 'false'" 
        position="end">
          <div class="col-12 acl-sidenav">
            <!-- Close button -->
            <div class="row drawer-close-hld">
              <div class="col-12 drawer-close-icon">
                <button
                  type="button"
                  aria-label="Toggle sidenav"
                  mat-flat-button
                  (click)="drawer.toggle()">                  
                  <mat-icon>close</mat-icon>
                </button>
              </div>
            </div>
            <!-- AGENT IMG -->
            <div class="row">
              <div class="col-12 agent-hld">
                <div class="agent-img">
                  <img src="../../../assets/img/chat-icon-sm.jpg" class="img-fluid">
                </div>
              </div>
            </div>
            <!-- Drawer Agent Name-->
            <div class="row">
              <div class="col-12 agent-name-hld">
                Byron Diggs
              </div>
            </div>
            <!-- Drawer Agent Title -->
            <div class="row">
              <div class="col-12 agent-title-hld">
                Your insurance advisor
              </div>
            </div>
            <!-- Agent icon button -->
            <div class="row">
              <div class="col-6 agent-icon-btn-hld">
                 <button
                 mat-icon-button>
                 <mat-icon>phone</mat-icon>
                </button>
              </div>
              <div class="col-6 agent-icon-btn-hld">
                <button
                mat-icon-button>
                <mat-icon>email</mat-icon>
               </button>
              </div>
            </div>
            <!-- Top Title -->
            <div class="row solid-output-hld">
              <div class="col-12 spacer-xs spacer-bottom-border"></div>
              <div class="col-12 solid-output-title-text">
              TOP PLANS
              </div>
            </div>
            <!-- Top Monthly -->
            <div class="row solid-output-hld">              
              <div class="col-7 solid-output-text">
                Preferred Elite
              </div>
              <div class="col-5 solid-output-output" >
                <div *ngIf="isMonthly">
                  {{apiReturnData['monthly'][0] | currency}}
                </div>
                <div *ngIf="!isMonthly">
                  <mat-progress-bar class="output-spinner" mode="indeterminate">
                  </mat-progress-bar>
                </div>
                
              </div>
            </div>
            <!--  -->
            
            <!-- Form Output -->
            <!-- Coverage -->
            <div class="row output-hld">
              <div class="col-7 output-text">
              Coverage Amount
              </div>
              <div class="col-5 output-output select-output">
                <form [formGroup]="planInfoForm">
                  <mat-form-field 
                  class="id-input-fld">
                    <input matInput 
                    formControlName="coverage"
                    value="0.00"
                    currencyMask
                    [options]="{prefix:'$', thousands: ',', decimal: '.'}" >
                  </mat-form-field>
                </form>
              </div>
            </div>
            <!-- Smoker -->
            <div class="row output-hld">
              <div class="col-7 output-text">
              Smoking Status
              </div>
              <div class="col-5 output-output select-output">
                <form [formGroup]="personalInfoForm.get('idInfoForm')">
                  <mat-form-field class="id-input-fld">
                    <mat-select
                      formControlName="smoker">
                        <mat-option value="default" disabled>Select...</mat-option>
                        <mat-option [value]="true">Smoker</mat-option>
                        <mat-option [value]="false">Non-Smoker</mat-option>
                    </mat-select>
                  </mat-form-field>
                </form>
              </div>
            </div>
            <!-- Gender -->
            <div class="row output-hld">
              <div class="col-7 output-text">
              Gender
              </div>
              <div class="col-5 output-output select-output">
                <form [formGroup]="personalInfoForm.get('idInfoForm')">
                  <mat-form-field class="id-input-fld">
                    <mat-select
                      formControlName="gender">
                        <mat-option value="default" disabled>Select...</mat-option>
                        <mat-option value="male">Male</mat-option>
                        <mat-option value="female">Female</mat-option>
                    </mat-select>
                  </mat-form-field>
                </form>
              </div>
            </div>
            <!-- Birth Date -->
            <div class="row output-hld">
              <div class="col-7 output-text">
              Birth Date
              </div>
              <div class="col-5 output-output select-output">
                <form [formGroup]="personalInfoForm.get('idInfoForm')">
                  <mat-form-field class="id-input-fld">
                    <input  
                      [matDatepicker]="ageDatepicker"
                      [min]="minDate"
                      [max]="maxDate"
                      startView="multi-years"
                      formControlName="dob"
                      matInput>
                    <mat-datepicker-toggle 
                      [for]="ageDatepicker"
                      matSuffix>
                    </mat-datepicker-toggle>
                    <mat-datepicker 
                      #ageDatepicker 
                      >
                    </mat-datepicker>
                  </mat-form-field>
                </form>
              </div>
            </div>
            <!-- TErm -->
            <div class="row output-hld">
              <div class="col-7 output-text">
              Select Term
              </div>
              <div class="col-5 output-output select-output">
                <form [formGroup]="planInfoForm">
                  <mat-form-field class="id-input-fld">
                    <mat-select
                      formControlName="term">
                        <mat-option 
                          *ngFor="let term of termArr"
                          [value]="term.value">
                          {{term.text}}
                        </mat-option>
                    </mat-select>
                  </mat-form-field>
                </form>
              </div>
            </div>
            <!-- Send changes to quote -->
            <div class="row output-hld" style="border-bottom:0px solid transparent;">
              <div class="col-7 ">
              </div>
              <div class="col-5 output-output output-btn">
                <button 
                [ngStyle]="{'opacity':!changeQuote ? '0.2' : '1' }"
                [disabled]="!changeQuote"
                (click)="sendChangeToQuote()"
                mat-flat-button>
                  Get Quote
                </button>
              </div>
            </div>
            <!--  -->

            <!--  -->
            <!-- ALTERNATE PLANS -->
            <!-- Alt Title -->
            <div class="row solid-output-hld">
              <div class="col-12 spacer-xs"></div>
              <div class="col-12 solid-output-title-text">
              ALTERNATE PLANS
              </div>
            </div>
            <!-- plan 2 going to loop -->
            <div class="row solid-output-hld">
              <div class="col-7 solid-output-alt-text">
              Preferred
              </div>
              <div class="col-5 solid-output-alt-output">
                <div *ngIf="isMonthly">
                  {{apiReturnData['monthly'][1] | currency}}
                </div>
                <div *ngIf="!isMonthly">
                  <mat-progress-bar class="output-spinner" mode="indeterminate">
                  </mat-progress-bar>
                </div>
              </div>
            </div>
            <!-- plan 3 -->
            <div class="row solid-output-hld">
              <div class="col-7 solid-output-alt-text">
              Simplified Elite 
              </div>
              <div class="col-5 solid-output-alt-output">
                <div *ngIf="isMonthly">
                  {{apiReturnData['monthly'][2] | currency}}
                </div>
                <div *ngIf="!isMonthly">
                  <mat-progress-bar class="output-spinner" mode="indeterminate">
                  </mat-progress-bar>
                </div>
              </div>
            </div>
            <!-- plan 4 -->
            <div class="row solid-output-hld">
              <div class="col-7 solid-output-alt-text">
              Deferred Elite
              </div>
              <div class="col-5 solid-output-alt-output">
                <div *ngIf="isMonthly">
                  {{apiReturnData['monthly'][3] | currency}}
                </div>
                <div *ngIf="!isMonthly">
                  <mat-progress-bar class="output-spinner" mode="indeterminate">
                  </mat-progress-bar>
                </div>
              </div>
            </div>
            <!-- ALTERNATE PLANS ENDS -->
            <div class="row spacer-md"></div>
            <!--  -->
          </div>
        </mat-sidenav>
        <!-- *** Sidebar Right Ends -->
        <!--  -->

        <!--  -->
        <!-- *** acl APP CONTENT -->
        <mat-sidenav-content>
          <div class="col-12">
            <div class="row">
              <!--  -->

              <!-- *** LEFT SIDEBAR -->
              <div class="col-0 col-sm-2 left-sidebar mobile-hide">
                <div class="row">
                  <div class="col-12 lg-logo-hld">
                    <img src="../../../assets/img/iae-logo.png"
                      class="img-fluid">
                  </div>
                </div>
              </div>
              <!-- * LEFT SIDEBAR ENDS -->

              <!-- *** MAIN CONTENT -->
              <div class="col-12 col-sm-10 main">

                <!-- Title -->
                <div class="row title">
                  <div class="col-8">
                    <div class="mat-display-2">
                      {{appTitle}}
                    </div>
                  </div>
                  <div class="col-4 drawer-target-toggle-btn mobile-hide"
                  (click)="drawer.toggle()">
                    <div class="row drawer-target-toggle-hld">
                      <div class="col-10">
                        <div class="drawer-target-toggle-text">Hide Details</div>
                      </div>
                      <div class="col-2 drawer-target-toggle-icon">
                        <mat-icon>double_arrow</mat-icon>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- *** QUESTIONS -->
                <div class="row questions">
                  <div class="col-12 questions-hld">
                    <mat-accordion class="questions-acc">

                      <!-- ??? PERSONAL INFORMATION -->
                      <mat-expansion-panel 
                        [expanded]="step === 0" 
                        (opened)="setStep(0)" 
                        hideToggle>
                        <!-- Header -->
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                            Personal Information
                          </mat-panel-title>
                          <mat-panel-description>
                            <span>&nbsp;</span>
                            <mat-icon>account_circle</mat-icon>
                          </mat-panel-description>
                        </mat-expansion-panel-header>
                        <!--  -->

                        <!-- PERSONAL INFO -->
                        <!-- IDENTIFICATION TITLE -->
                        <div class="col-12 question-sub-title">
                            <div class="col-12 question-title-text">Identification</div>
                        </div>
                        <!-- IDENTIFICATION FORM -->                        
                        <form [formGroup]="personalInfoForm.get('idInfoForm')"
                          style="width: 100%;">
                          <!-- Name -->
                          <div class="col-12 question-sub-forms">
                            <div class="col-12 col-sm-4 question-id-text">
                              <mat-form-field class="id-input-fld">
                                <mat-label>First Name</mat-label>
                                <input matInput 
                                formControlName="firstName">
                              </mat-form-field>
                            </div>
                            <div class="col-12 col-sm-4 question-id-text ">
                              <mat-form-field class="id-input-fld">
                                <mat-label>Middle Name</mat-label>
                                <input matInput 
                                formControlName="middleName">
                              </mat-form-field>
                            </div>
                            <div class="col-12 col-sm-4 question-id-text">
                              <mat-form-field class="id-input-fld">
                                <mat-label>Last Name</mat-label>
                                <input matInput 
                                formControlName="lastName">
                              </mat-form-field>
                            </div>
                          </div>
                          <!-- ** Name ends-->


                          <!-- Statuses  -->
                          <!-- <div class="col-12 question-sub-forms">
                            <div class="col-12 col-sm-4 question-id-text">
                              <mat-form-field class="id-input-fld">
                                <mat-label>Gender</mat-label>
                                <mat-select
                                  formControlName="smoker">
                                    <mat-option value="default" disabled>Select...</mat-option>
                                    <mat-option value="male">Male</mat-option>
                                    <mat-option value="female">Female</mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                            <div class="col-12 col-sm-4 question-id-text ">
                              <mat-form-field class="id-input-fld">
                                <mat-label>Smoking Status</mat-label>
                                <mat-select
                                  formControlName="smoker">
                                    <mat-option value="default" disabled>Select...</mat-option>
                                    <mat-option [value]="true">Smoker</mat-option>
                                    <mat-option [value]="false">Non-Smoker</mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                            <div class="col-12 col-sm-4 question-id-text">
                              <mat-form-field class="id-input-fld">
                                <mat-label>Date of birth</mat-label>
                                <input  
                                  [matDatepicker]="ageDatepicker"
                                  [min]="minDate"
                                  [max]="maxDate"
                                  startView="multi-years"
                                  formControlName="dob"
                                  matInput>
                                <mat-datepicker-toggle 
                                  [for]="ageDatepicker"
                                  matSuffix>
                                </mat-datepicker-toggle>
                                <mat-datepicker 
                                  #ageDatepicker 
                                  >
                                </mat-datepicker>
                              </mat-form-field>
                            </div>
                          </div> -->
                          <!-- ** Statuses ends -->

                          
                          <!-- ID/SIN -->
                          <div class="col-12 question-sub-forms">
                            <!-- citizen -->
                            <div class="col-12 col-sm-4 question-id-text">
                              <mat-form-field class="id-input-fld">
                                <mat-label>Citizenship</mat-label>
                                <input matInput 
                                formControlName="citizen">
                              </mat-form-field>
                            </div>
                            <!-- Country of Birth -->
                            <div class="col-12 col-sm-4 question-id-text">
                              <mat-form-field class="id-input-fld">
                                <mat-label>Country of birth</mat-label>
                                <mat-select
                                  formControlName="country">
                                    <mat-option value="Afghanistan" >Afghanistan</mat-option>
                                    <mat-option value="Albania" >Albania</mat-option>
                                    <mat-option value="Algeria" >Algeria</mat-option>
                                    <mat-option value="American Samoa" >American Samoa</mat-option>
                                    <mat-option value="Andorra" >Andorra</mat-option>
                                    <mat-option value="Angola" >Angola</mat-option>
                                    <mat-option value="Antigua and Barbuda" >Antigua and Barbuda</mat-option>
                                    <mat-option value="Argentina" >Argentina</mat-option>
                                    <mat-option value="Armenia" >Armenia</mat-option>
                                    <mat-option value="Aruba" >Aruba</mat-option>
                                    <mat-option value="Australia" >Australia</mat-option>
                                    <mat-option value="Austria" >Austria</mat-option>
                                    <mat-option value="Azerbaijan" >Azerbaijan</mat-option>
                                    <mat-option value="Bahamas" >Bahamas</mat-option>
                                    <mat-option value="Bahrain" >Bahrain</mat-option>
                                    <mat-option value="Bangladesh" >Bangladesh</mat-option>
                                    <mat-option value="Barbados" >Barbados</mat-option>
                                    <mat-option value="Belarus" >Belarus</mat-option>
                                    <mat-option value="Belgium" >Belgium</mat-option>
                                    <mat-option value="Belize" >Belize</mat-option>
                                    <mat-option value="Benin" >Benin</mat-option>
                                    <mat-option value="Bermuda" >Bermuda</mat-option>
                                    <mat-option value="Bhutan" >Bhutan</mat-option>
                                    <mat-option value="Bolivia" >Bolivia</mat-option>
                                    <mat-option value="Bonaire" >Bonaire</mat-option>
                                    <mat-option value="Bosnia and Herzegovina" >Bosnia and Herzegovina</mat-option>
                                    <mat-option value="Botswana" >Botswana</mat-option>
                                    <mat-option value="Brazil" >Brazil</mat-option>
                                    <mat-option value="British Virgin Islands" >British Virgin Islands</mat-option>
                                    <mat-option value="Brunei" >Brunei</mat-option>
                                    <mat-option value="Bulgaria" >Bulgaria</mat-option>
                                    <mat-option value="Burkina Faso" >Burkina Faso</mat-option>
                                    <mat-option value="Burundi" >Burundi</mat-option>
                                    <mat-option value="Cambodia" >Cambodia</mat-option>
                                    <mat-option value="Cameroon" >Cameroon</mat-option>
                                    <mat-option value="Cape Verde" >Cape Verde</mat-option>
                                    <mat-option value="Cayman Islands" >Cayman Islands</mat-option>
                                    <mat-option value="Central African Republic" >Central African Republic</mat-option>
                                    <mat-option value="Chad" >Chad</mat-option>
                                    <mat-option value="Chile" >Chile</mat-option>
                                    <mat-option value="China" >China</mat-option>
                                    <mat-option value="China (Hong Kong)" >China (Hong Kong)</mat-option>
                                    <mat-option value="Colombia" >Colombia</mat-option>
                                    <mat-option value="Comoros" >Comoros</mat-option>
                                    <mat-option value="Costa Rica" >Costa Rica</mat-option>
                                    <mat-option value="Croatia" >Croatia</mat-option>
                                    <mat-option value="Cuba" >Cuba</mat-option>
                                    <mat-option value="Curacao" >Curacao</mat-option>
                                    <mat-option value="Cyprus" >Cyprus</mat-option>
                                    <mat-option value="Czech Republic" >Czech Republic</mat-option>
                                    <mat-option value="Democratic Republic of the Congo" >Democratic Republic of the Congo</mat-option>
                                    <mat-option value="Denmark" >Denmark</mat-option>
                                    <mat-option value="Djibouti" >Djibouti</mat-option>
                                    <mat-option value="Dominica" >Dominica</mat-option>
                                    <mat-option value="Dominican Republic" >Dominican Republic</mat-option>
                                    <mat-option value="East Timor" >East Timor</mat-option>
                                    <mat-option value="Ecuador" >Ecuador</mat-option>
                                    <mat-option value="Egypt" >Egypt</mat-option>
                                    <mat-option value="El Salvador" >El Salvador</mat-option>
                                    <mat-option value="Equatorial Guinea" >Equatorial Guinea</mat-option>
                                    <mat-option value="Eritrea" >Eritrea</mat-option>
                                    <mat-option value="Estonia" >Estonia</mat-option>
                                    <mat-option value="Ethiopia" >Ethiopia</mat-option>
                                    <mat-option value="Falkland Islands" >Falkland Islands</mat-option>
                                    <mat-option value="Fiji" >Fiji</mat-option>
                                    <mat-option value="Finland" >Finland</mat-option>
                                    <mat-option value="France" >France</mat-option>
                                    <mat-option value="Gabon" >Gabon</mat-option>
                                    <mat-option value="Gambia" >Gambia</mat-option>
                                    <mat-option value="Georgia" >Georgia</mat-option>
                                    <mat-option value="Germany" >Germany</mat-option>
                                    <mat-option value="Ghana" >Ghana</mat-option>
                                    <mat-option value="Greece" >Greece</mat-option>
                                    <mat-option value="Greenland" >Greenland</mat-option>
                                    <mat-option value="Grenada" >Grenada</mat-option>
                                    <mat-option value="Guatemala" >Guatemala</mat-option>
                                    <mat-option value="Guernsey" >Guernsey</mat-option>
                                    <mat-option value="Guinea" >Guinea</mat-option>
                                    <mat-option value="Guinea-Bissau" >Guinea-Bissau</mat-option>
                                    <mat-option value="Guyana" >Guyana</mat-option>
                                    <mat-option value="Haiti" >Haiti</mat-option>
                                    <mat-option value="Honduras" >Honduras</mat-option>
                                    <mat-option value="Hungary" >Hungary</mat-option>
                                    <mat-option value="Iceland" >Iceland</mat-option>
                                    <mat-option value="India" >India</mat-option>
                                    <mat-option value="Indonesia" >Indonesia</mat-option>
                                    <mat-option value="Iran" >Iran</mat-option>
                                    <mat-option value="Iraq" >Iraq</mat-option>
                                    <mat-option value="Ireland" >Ireland</mat-option>
                                    <mat-option value="Israel" >Israel</mat-option>
                                    <mat-option value="Italy" >Italy</mat-option>
                                    <mat-option value="Ivory Coast" >Ivory Coast</mat-option>
                                    <mat-option value="Jamaica" >Jamaica</mat-option>
                                    <mat-option value="Japan" >Japan</mat-option>
                                    <mat-option value="Jordan" >Jordan</mat-option>
                                    <mat-option value="Kazakhstan" >Kazakhstan</mat-option>
                                    <mat-option value="Kenya" >Kenya</mat-option>
                                    <mat-option value="Kiribati" >Kiribati</mat-option>
                                    <mat-option value="Kosovo" >Kosovo</mat-option>
                                    <mat-option value="Kuwait" >Kuwait</mat-option>
                                    <mat-option value="Kyrgyzstan" >Kyrgyzstan</mat-option>
                                    <mat-option value="Laos" >Laos</mat-option>
                                    <mat-option value="Latvia" >Latvia</mat-option>
                                    <mat-option value="Lebanon" >Lebanon</mat-option>
                                    <mat-option value="Lesotho" >Lesotho</mat-option>
                                    <mat-option value="Liberia" >Liberia</mat-option>
                                    <mat-option value="Libya" >Libya</mat-option>
                                    <mat-option value="Liechtenstein" >Liechtenstein</mat-option>
                                    <mat-option value="Lithuania" >Lithuania</mat-option>
                                    <mat-option value="Luxembourg" >Luxembourg</mat-option>
                                    <mat-option value="Macao" >Macao</mat-option>
                                    <mat-option value="Macedonia" >Macedonia</mat-option>
                                    <mat-option value="Madagascar" >Madagascar</mat-option>
                                    <mat-option value="Malawi" >Malawi</mat-option>
                                    <mat-option value="Malaysia" >Malaysia</mat-option>
                                    <mat-option value="Maldives" >Maldives</mat-option>
                                    <mat-option value="Mali" >Mali</mat-option>
                                    <mat-option value="Malta" >Malta</mat-option>
                                    <mat-option value="Marshall Islands" >Marshall Islands</mat-option>
                                    <mat-option value="Mauritania" >Mauritania</mat-option>
                                    <mat-option value="Mauritius" >Mauritius</mat-option>
                                    <mat-option value="Mexico" >Mexico</mat-option>
                                    <mat-option value="Micronesia" >Micronesia</mat-option>
                                    <mat-option value="Moldova" >Moldova</mat-option>
                                    <mat-option value="Monaco" >Monaco</mat-option>
                                    <mat-option value="Mongolia" >Mongolia</mat-option>
                                    <mat-option value="Montenegro" >Montenegro</mat-option>
                                    <mat-option value="Montserrat" >Montserrat</mat-option>
                                    <mat-option value="Morocco" >Morocco</mat-option>
                                    <mat-option value="Mozambique" >Mozambique</mat-option>
                                    <mat-option value="Myanmar" >Myanmar</mat-option>
                                    <mat-option value="Namibia" >Namibia</mat-option>
                                    <mat-option value="Nauru" >Nauru</mat-option>
                                    <mat-option value="Nepal" >Nepal</mat-option>
                                    <mat-option value="Netherlands" >Netherlands</mat-option>
                                    <mat-option value="New Caledonia" >New Caledonia</mat-option>
                                    <mat-option value="New Zealand" >New Zealand</mat-option>
                                    <mat-option value="Nicaragua" >Nicaragua</mat-option>
                                    <mat-option value="Niger" >Niger</mat-option>
                                    <mat-option value="Nigeria" >Nigeria</mat-option>
                                    <mat-option value="North Korea" >North Korea</mat-option>
                                    <mat-option value="Northern Mariana Islands" >Northern Mariana Islands</mat-option>
                                    <mat-option value="Norway" >Norway</mat-option>
                                    <mat-option value="Oman" >Oman</mat-option>
                                    <mat-option value="Pakistan" >Pakistan</mat-option>
                                    <mat-option value="Palau" >Palau</mat-option>
                                    <mat-option value="Palestine" >Palestine</mat-option>
                                    <mat-option value="Panama" >Panama</mat-option>
                                    <mat-option value="Papua New Guinea" >Papua New Guinea</mat-option>
                                    <mat-option value="Paraguay" >Paraguay</mat-option>
                                    <mat-option value="Peru" >Peru</mat-option>
                                    <mat-option value="Philippines" >Philippines</mat-option>
                                    <mat-option value="Poland" >Poland</mat-option>
                                    <mat-option value="Portugal" >Portugal</mat-option>
                                    <mat-option value="Puerto Rico" >Puerto Rico</mat-option>
                                    <mat-option value="Qatar" >Qatar</mat-option>
                                    <mat-option value="Republic of the Congo" >Republic of the Congo</mat-option>
                                    <mat-option value="Romania" >Romania</mat-option>
                                    <mat-option value="Russia" >Russia</mat-option>
                                    <mat-option value="Rwanda" >Rwanda</mat-option>
                                    <mat-option value="Saint Kitts and Nevis" >Saint Kitts and Nevis</mat-option>
                                    <mat-option value="Saint Lucia" >Saint Lucia</mat-option>
                                    <mat-option value="Saint Vincent and the Grenadines" >Saint Vincent and the Grenadines</mat-option>
                                    <mat-option value="Samoa" >Samoa</mat-option>
                                    <mat-option value="San Marino" >San Marino</mat-option>
                                    <mat-option value="Sao Tome and Principe" >Sao Tome and Principe</mat-option>
                                    <mat-option value="Saudi Arabia" >Saudi Arabia</mat-option>
                                    <mat-option value="Senegal" >Senegal</mat-option>
                                    <mat-option value="Serbia" >Serbia</mat-option>
                                    <mat-option value="Seychelles" >Seychelles</mat-option>
                                    <mat-option value="Sierra Leone" >Sierra Leone</mat-option>
                                    <mat-option value="Singapore" >Singapore</mat-option>
                                    <mat-option value="Slovakia" >Slovakia</mat-option>
                                    <mat-option value="Slovenia" >Slovenia</mat-option>
                                    <mat-option value="Solomon Islands" >Solomon Islands</mat-option>
                                    <mat-option value="Somalia" >Somalia</mat-option>
                                    <mat-option value="South Africa" >South Africa</mat-option>
                                    <mat-option value="South Korea" >South Korea</mat-option>
                                    <mat-option value="South Sudan" >South Sudan</mat-option>
                                    <mat-option value="Spain" >Spain</mat-option>
                                    <mat-option value="Sri Lanka" >Sri Lanka</mat-option>
                                    <mat-option value="Sudan" >Sudan</mat-option>
                                    <mat-option value="Suriname" >Suriname</mat-option>
                                    <mat-option value="Swaziland" >Swaziland</mat-option>
                                    <mat-option value="Sweden" >Sweden</mat-option>
                                    <mat-option value="Switzerland" >Switzerland</mat-option>
                                    <mat-option value="Syria" >Syria</mat-option>
                                    <mat-option value="Taiwan" >Taiwan</mat-option>
                                    <mat-option value="Tajikistan" >Tajikistan</mat-option>
                                    <mat-option value="Tanzania" >Tanzania</mat-option>
                                    <mat-option value="Thailand" >Thailand</mat-option>
                                    <mat-option value="Togo" >Togo</mat-option>
                                    <mat-option value="Tonga" >Tonga</mat-option>
                                    <mat-option value="Trinidad and Tobago" >Trinidad and Tobago</mat-option>
                                    <mat-option value="Tunisia" >Tunisia</mat-option>
                                    <mat-option value="Turkey" >Turkey</mat-option>
                                    <mat-option value="Turkmenistan" >Turkmenistan</mat-option>
                                    <mat-option value="Turks and Caicos Islands" >Turks and Caicos Islands</mat-option>
                                    <mat-option value="Tuvalu" >Tuvalu</mat-option>
                                    <mat-option value="Uganda" >Uganda</mat-option>
                                    <mat-option value="Ukraine" >Ukraine</mat-option>
                                    <mat-option value="United Arab Emirates" >United Arab Emirates</mat-option>
                                    <mat-option value="United Kingdom" >United Kingdom</mat-option>
                                    <mat-option value="United States" >United States</mat-option>
                                    <mat-option value="Uruguay" >Uruguay</mat-option>
                                    <mat-option value="Uzbekistan" >Uzbekistan</mat-option>
                                    <mat-option value="Vanuatu" >Vanuatu</mat-option>
                                    <mat-option value="Vatican City" >Vatican City</mat-option>
                                    <mat-option value="Venezuela" >Venezuela</mat-option>
                                    <mat-option value="Vietnam" >Vietnam</mat-option>
                                    <mat-option value="Yemen" >Yemen</mat-option>
                                    <mat-option value="Zambia" >Zambia</mat-option>
                                    <mat-option value="Zimbabwe" >Zimbabwe</mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                            <!-- SIN# -->
                            <div class="col-12 col-sm-4 question-id-text">
                              <mat-form-field class="id-input-fld">
                                <mat-label>SIN Number</mat-label>
                                <input matInput 
                                formControlName="sin">
                              </mat-form-field>
                            </div>

                          </div>
                          <!-- ** ID/SIN  ends -->
                          <!-- ID Info -->
                          <div class="col-12 question-sub-forms">
                            <!-- citizen -->
                            <div class="col-12 col-sm-4 question-id-text">
                              <mat-form-field class="id-input-fld">
                                <mat-label>Insured's ID type</mat-label>
                                <input matInput 
                                formControlName="idType">
                              </mat-form-field>
                            </div>
                            <!-- Country of Birth -->
                            <div class="col-12 col-sm-4 question-id-text">
                              <mat-form-field class="id-input-fld">
                                <mat-label>ID Number</mat-label>
                                <input matInput 
                                formControlName="idNum">
                              </mat-form-field>
                            </div>
                            <!-- SIN# -->
                            <div class="col-12 col-sm-4 question-id-text">
                              <mat-form-field class="id-input-fld">
                                <mat-label>Province/Territory of issue</mat-label>
                                <input matInput 
                                formControlName="idProvince">
                              </mat-form-field>
                            </div>

                          </div>
                          <!-- ** ID Info ends -->
                          <!-- Expiry -->
                          <div class="col-12 question-sub-forms">
                            <!-- Expiry -->
                            <div class="col-12 col-sm-4 question-id-text ">
                              <mat-form-field class="id-input-fld">
                                <mat-label>Expiry date</mat-label>
                                <input matInput 
                                formControlName="idExpiry">
                              </mat-form-field>
                            </div>
                          </div>
                          <!-- Expiry ends-->

                        </form>
                        <!-- IDENTIFICATION FORM ENDS --> 

                        <!-- ADDRESS TITLE -->
                        <div class="col-12 question-sub-title">
                            <div class="col-12 question-title-text" *ngIf="true">Address</div>
                        </div>
                        <!-- ADDRESS FORM -->
                        <form [formGroup]="personalInfoForm.get('addressForm')"
                        *ngIf="true">
                          <!-- Address type -->
                          <div class="col-12 question-sub-forms">
                            <div class="col-12 col-sm-4 question-id-text">
                              <mat-form-field class="id-input-fld">
                                <mat-label>Address Type</mat-label>
                                <mat-select
                                  formControlName="addressType">
                                    <mat-option value="default" disabled>Select...</mat-option>
                                    <mat-option value="house">House</mat-option>
                                    <mat-option value="town house">Town House</mat-option>
                                    <mat-option value="condo">Condo</mat-option>
                                    <mat-option value="apt">Apartment</mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                          </div>
                          <!-- ** Address type ends -->
                          <!-- Address  -->
                          <div class="col-12 question-sub-forms">
                            <div class="col-12 question-id-text">
                              <mat-form-field class="id-input-fld">
                                <mat-label>Address</mat-label>
                                <input matInput 
                                formControlName="address"
                                ngx-google-places-autocomplete
                                (onAddressChange)="handleAddressChange($event)"
                                autocomplete="new-password">
                              </mat-form-field>
                            </div>
                          </div>
                          <!-- ** Address ends -->
                          <!-- Postals  -->
                          <div class="col-12 question-sub-forms">
                            <div class="col-12 col-sm-4 question-id-text">
                              <mat-form-field class="id-input-fld">
                                <mat-label>Unit</mat-label>
                                <input matInput 
                                formControlName="unit">
                              </mat-form-field>
                            </div>
                            <div class="col-12 col-sm-4 question-id-text">
                              <mat-form-field class="id-input-fld">
                                <mat-label>City</mat-label>
                                <input matInput 
                                formControlName="city">
                              </mat-form-field>
                            </div>
                            <div class="col-12 col-sm-4 question-id-text">
                              <mat-form-field class="id-input-fld">
                                <mat-label>Postal</mat-label>
                                <input matInput 
                                formControlName="postal">
                              </mat-form-field>
                            </div>
                          </div>
                          <!-- ** Address ends -->
                        </form>
                        <!-- ADDRESS FORM ENDS-->

                        <!-- CONTACT TITLE -->
                        <div class="col-12 question-sub-title">
                          <div class="col-12 question-title-text" *ngIf="true">Contact</div>
                        </div>
                        <!-- CONTACT FORM -->
                        <form [formGroup]="personalInfoForm.get('contact')"
                        *ngIf="true">
                          <!-- phone  -->
                          <div class="col-12 question-sub-forms">
                            <div class="col-12 col-sm-6 question-id-text">
                              <mat-form-field class="id-input-fld">
                                <mat-label>Phone</mat-label>
                                <input matInput 
                                formControlName="phone">
                              </mat-form-field>
                            </div>
                            <div class="col-12 col-sm-6 question-id-text">
                              <mat-form-field class="id-input-fld">
                                <mat-label>Alt. phone</mat-label>
                                <input matInput 
                                formControlName="altNum">
                              </mat-form-field>
                            </div>
                          </div>
                          <!-- ** phone ends -->
                          <!-- phone  -->
                          <div class="col-12 question-sub-forms">
                            <div class="col-12 question-id-text">
                              <mat-form-field class="id-input-fld">
                                <mat-label>Email</mat-label>
                                <input matInput 
                                formControlName="email">
                              </mat-form-field>
                            </div>
                          </div>
                          <!-- ** phone ends -->
                        </form>
                        <!-- CONTACT FORM ENDS-->


                        <!--  -->
                      </mat-expansion-panel>
                      <!-- ? PERSONAL INFORMATION ENDS -->

                      <!-- ??? A) QUESTION SECTION -->
                      <mat-expansion-panel 
                      [expanded]="step === 1" 
                      (opened)="setStep(1)" 
                      hideToggle>
                        <mat-expansion-panel-header style="height: 100%;">
                          <mat-panel-title>
                            A: Guaranteed Acceptance Life
                          </mat-panel-title>
                          <mat-panel-description>
                            &nbsp;
                            <mat-icon>help</mat-icon>
                          </mat-panel-description>
                        </mat-expansion-panel-header>
                        
                        <!-- A Q1 -->
                        <div class="col-12 question">
                          <form  style="width: 100%;">
                            <div class="row">
                              <!-- Title -->
                              <div class="col-12 col-sm-9 question-text">
                                Are you currently incapable of independently carrying out two or more of the basic activities of daily living such as getting up, walking, washing, toileting, dressing or feeding?
                              </div>
                              <!--  -->

                              <!-- Buttons -->
                              <div class="col-12 col-sm-3 question-btn-hld">
                                <!-- <mat-radio-group> -->
                                  <div class="col-6 question-btn qYes">
                                    <mat-radio-button name="aq1" value="yes" labelPosition="before">
                                      Yes
                                    </mat-radio-button>
                                  </div>
                                  <div class="col-6 question-btn qNo">
                                    <mat-radio-button name="aq1" value="no" labelPosition="before">
                                      No
                                    </mat-radio-button>
                                  </div>
                                <!-- </mat-radio-group> -->
                              </div>
                              <!--  -->

                            </div>
                          </form>
                        </div>
                        <!-- A Q1 ENDS -->

                      </mat-expansion-panel>
                      <!-- ? A) QUESTION SECTION -->

                      <!-- ??? B) QUESTION SECTION -->
                      <mat-expansion-panel 
                      [expanded]="step === 2" 
                      (opened)="setStep(2)" 
                      hideToggle>
                        <mat-expansion-panel-header style="height: 100%;">
                          <mat-panel-title>
                            B: Deferred Life
                          </mat-panel-title>
                          <mat-panel-description>
                            &nbsp;
                            <mat-icon>help</mat-icon>
                          </mat-panel-description>
                        </mat-expansion-panel-header>
                        <!-- B Q1 -->
                        <div class="col-12 question">
                          <form  style="width: 100%;">
                            <div class="row">
                              <div class="col-12 col-sm-9 question-text">
                                Within the past 12 months, have you had, been told you have, or been treated for:
                              </div>
                              <div class="col-12 col-sm-3 question-btn-hld">
                                <!-- <mat-radio-group> -->
                                  <div class="col-6 question-btn qYes">
                                    <mat-radio-button 
                                      name="bq1" value="yes"
                                      labelPosition="before">
                                        Yes
                                    </mat-radio-button>
                                  </div>
                                  <div class="col-6 question-btn qNo">
                                    <mat-radio-button 
                                      name="bq1" value="no"
                                      labelPosition="before">
                                        No
                                    </mat-radio-button>
                                  </div>
                                <!-- </mat-radio-group> -->
                              </div>
                            </div>
                          </form>
                        </div>
                        <!--  -->

                      </mat-expansion-panel>
                      <!-- ? B) QUESTION SECTION -->

                    </mat-accordion>
                  </div>
                </div>
                <!-- *** QUESTIONS ENDS -->
                <button
                type="button"
                aria-label="Toggle sidenav"
                mat-raised-button
                (click)="drawer.toggle()">
                View Info
              </button>

              </div>
              <!-- * MAIN CONTENT ENDS -->


              <!--  -->
            </div>
          </div>
        </mat-sidenav-content>
        <!-- *** acl APP CONTENT ENDS -->
        <!--  -->

      </mat-sidenav-container>
      <!-- Mat-sidenav-container -->
    </div>
  </div>
</div>

