import { Component } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss']
})
export class MainNavComponent {
  footerLoad:boolean = false;
  footerColor;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(private breakpointObserver: BreakpointObserver) {}

  activeCheck:boolean = false;
  // FOOTER COLOR CHANGE
  onActivate(componentReference) {
    this.footerLoad = false;
    console.log(componentReference)
    const footerColor = componentReference.footerColor();
    console.log(`This is the footer color:  ${footerColor}`);
    this.footerColor = footerColor;
    this.footerLoad = true;
  }

}
